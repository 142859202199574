import $ from 'jquery'
import {formatterEnum,pickRow,addTool,updateTool,idsTool,pageLoad} from './cloudTool'

export default {
    first:function (them) {
        var userName = localStorage.getItem("userName");
		var token = sessionStorage.getItem("token");
        $.ajax({
            type: "get",
            // contentType: "application/json;charset=UTF-8",
            url: "/wave-api/user/selectUserByUserName",
            data: {"userName":userName},
            success: function(res) {
                console.log(res);
                them.userInfo=res.data;
            }
        });
        $.ajax({
            type: "get",
            // contentType: "application/json;charset=UTF-8",
            url: "/wave-api/device/selectProductAndDevice",
            data: {"userName":userName},
            success: function(res) {
                console.log(res);
                them.count=res.data;
            }
        });
    },
    updatePassword:function (them) {
        them.title=them.passwordTitle;
        them.showInfo=them.password;
        them.updateInfo={};
        $("#updatePassword").attr("data-target", "#exampleModal");
    },
    toUpdate:function (them) {
        console.log(them.updateInfo);
        if(them.updateInfo.confirmInfo!=them.updateInfo.newInfo && them.title=="密码修改"){
            alert("请确保两次输入的密码一致");
        }else{
            if(them.title="密码修改"){
                var userName = localStorage.getItem("userName");
                them.updateInfo.userName=userName;
                $.ajax({
                    type: "post",
                    contentType: "application/json;charset=UTF-8",
                    url: "/wave-api/user/updateUserPassword",
                    data: JSON.stringify(them.updateInfo),
                    success: function(res) {
                        alert(res.message);
                        if (res.code == 200) {
                            // window.location.href = "/wave-admin/to/toUser";
                            $('.close').trigger("click");
                        }
                    }
                });
            }
        }
    }
}
/*
var them = new Vue({
    el: "#app",
    data: {
        userInfo:{},
        updateInfo:{
            userName:"",
            oldInfo:"",
            newInfo:"",
            confirmInfo:""
        },
        password:{
            old:"原密码",
            new:"新密码",
            confirm:"再次确认",
        },
        showInfo:{
            old:"",
            new:"",
            confirm:"",
        },
        title:"",
        passwordTitle:"密码修改",
        emailTitle:"邮箱修改",
        count:{
        }
    },
    methods:{
        updatePassword:function () {
            them.title=them.passwordTitle;
            them.showInfo=them.password;
            them.updateInfo={};
            $("#updatePassword").attr("data-target", "#exampleModal");
        },
        toUpdate:function () {
            console.log(them.updateInfo);
            if(them.updateInfo.confirmInfo!=them.updateInfo.newInfo && them.title=="密码修改"){
                alert("请确保两次输入的密码一致");
            }else{
                if(them.title="密码修改"){
                    var userName = localStorage.getItem("userName");
                    them.updateInfo.userName=userName;
                    $.ajax({
                        type: "post",
                        contentType: "application/json;charset=UTF-8",
                        url: "/wave-admin/user/updateUserPassword",
                        data: JSON.stringify(them.updateInfo),
                        success: function(res) {
                            alert(res.message);
                            if (res.code == 200) {
                                // window.location.href = "/wave-admin/to/toUser";
                                $('.close').trigger("click");
                            }
                        }
                    });
                }
            }
        }
    },
    created: function() {
        var userName = localStorage.getItem("userName");
        $.ajax({
            type: "get",
            // contentType: "application/json;charset=UTF-8",
            url: "/wave-admin/user/selectUserByUserName",
            data: {"userName":userName},
            success: function(res) {
                console.log(res);
                them.userInfo=res.data;
            }
        });
        $.ajax({
            type: "get",
            // contentType: "application/json;charset=UTF-8",
            url: "/wave-admin/device/selectProductAndDevice",
            data: {"userName":userName},
            success: function(res) {
                console.log(res);
                them.count=res.data;
            }
        });
    },
})*/
