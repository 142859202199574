<template>
	<el-container>
		<el-header>
			<el-card class="box-card"><span class="badge badge-primary">设备日志</span>
			</el-card>
		</el-header>
		<el-main>
			<el-card class="box-card">
				<el-row type="flex" justify="start" :gutter="20" class="collapse search" id="collapseExample">
					<!-- 搜索框 start -->
					<el-col :span="6">
						<el-input v-model="searchDeviceVo.searchInfo" placeholder="请输入内容"></el-input>
					</el-col>
					<el-col :span="5">
						<el-date-picker v-model="searchDeviceVo.time" type="datetimerange" :picker-options="pickerOptions"
						 range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right">
						</el-date-picker>
					</el-col>
					<el-col :span="6">
						<el-button type="primary" plain icon="el-icon-search" @click="search">查询</el-button>
						<el-button type="warning" plain icon="el-icon-refresh-left" @click="reset">重置</el-button>
					</el-col>
					<!-- 搜索框 end -->
				</el-row>


				<el-row type="flex" :gutter="20" justify="space-between">
					<!-- 左侧功能按钮 start -->
					<el-col :span="5" class="left-botton">
					</el-col>
					<!-- 左侧功能按钮 end -->
					<!-- <el-col :span="4" class="left-botton">
				</el-col> -->
					<!-- 右侧功能按钮 start-->
					<el-col :span="1.5" class="btn-group right-botton btn-group-sm" role="group" aria-label="...">
						<el-button-group>
							<el-button round icon="el-icon-search" autofocus data-toggle="collapse" data-target="#collapseExample"
							 aria-expanded="false" aria-controls="collapseExample"></el-button>
							<el-button round icon="el-icon-refresh" autofocus @click="search"></el-button>
						</el-button-group>
					</el-col>
					<!-- 右侧功能按钮 end-->
				</el-row>
				<el-divider content-position="left">日志</el-divider>
				<!-- 设备信息表格-->
				<table id="table"></table>
			</el-card>


		</el-main>
		<el-footer></el-footer>
	</el-container>
</template>

<script>
	import deviceLog from '../js/deviceLog'
	export default {
		name: "DeviceLog",
		data() {
			return {
				title: "",
				searchDeviceVo: {

				},
				pickerOptions: {
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
			}
		},
		created() {
			var token = sessionStorage.getItem("token");
			deviceLog.loadTable(this, token);
		},
		methods: {
			clear: function() {
				deviceLog.clear(this)
			},
			search: function() {
				deviceLog.search(this)
			},
			reset: function() {
				deviceLog.reset(this)
			}
		}
	}
</script>

<style scoped src="../css/deviceLog.css">

</style>
