<template>
	<div id="amazon-root">
		<div class="shortcut">
			<div class="logo fl">
				<h1><a href="###" style="font-size:0">云世<img src="../assets/yunshi.png"></a></h1>
			</div>
			<div class="nav fr">
				<ul>
					<li><a href="#">首页</a></li>
					<li><a>帮助中心</a></li>
				</ul>
			</div>
		</div>
		<div id="app">
			<!-- 用户注册Modal start-->
			<!--<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
               <div class="modal-dialog modal-lg">
                   <div class="modal-content">
                       <div class="modal-header">
                           <h5 class="modal-title" id="exampleModalLabel">用户注册</h5>
                           <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                               <span aria-hidden="true">&times;</span>
                           </button>
                       </div>
                       &lt;!&ndash; 提交的表单 &ndash;&gt;
                       <form id="insertUser">
                           <div class="form-group row">
                               <label for="insertUserName" class="col-sm-2 col-form-label">姓名</label>
                               <div class="col-sm-10">
                                   <input type="text" class="form-control" id="insertUserName">
                               </div>
                           </div>
                           <div class="form-group row">
                               <label for="insertUserPassword" class="col-sm-2 col-form-label">密码</label>
                               <div class="col-sm-10">
                                   <input type="text" class="form-control" id="insertUserPassword">
                               </div>
                           </div>
                           <div class="form-group row">
                               <label for="insertUserPhone" class="col-sm-2 col-form-label">电话</label>
                               <div class="col-sm-10">
                                   <input type="text" class="form-control" id="insertUserPhone">
                               </div>
                           </div>
                           <div class="form-group row">
                               <label for="insertUserEmail" class="col-sm-2 col-form-label">邮箱</label>
                               <div class="col-sm-10">
                                   <input type="email" class="form-control" id="insertUserEmail">
                               </div>
                           </div>
                           <div class="form-group row">
                               <label for="insertUserGender" class="col-sm-2 col-form-label">性别</label>
                               <div class="col-sm-10">
                                   &lt;!&ndash; <input type="" class="form-control" id="inputRole"> &ndash;&gt;
                                   <select id="insertUserGender" class="form-control">
                                       <option value="MAN">男</option>
                                       <option value="WOMAN">女</option>
                                   </select>
                               </div>
                           </div>
                       </form>
                       <div class="modal-footer">
                           <button type="button" class="btn btn-secondary" data-dismiss="modal">取消</button>
                           <button type="button" class="btn btn-primary" @click="submit">提交</button>
                       </div>
                   </div>
               </div>
           </div>-->
			<!-- 用户注册Modal end-->

			<div class="main">
				<div class="loginForm">
					<div class="loginTop"><span>账号登录</span></div>
					<div class="error"></div>
					<!-- <p><input type="text" spellcheck="false" class="input-text" validator="true" placeholder="账号名/邮箱" id="username"><br></p>
                   <p><input type="password" autocomplete="off" class="input-text" validator="true" placeholder="密码" id="password"><br></p> -->
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm formMain">
						<el-form-item label="企业编码" prop="enterprise_number">
							<el-input placeholder="请输入企业编码" v-model="ruleForm.enterprise_number" @keyup.enter.native="login" clearable
							 prefix-icon="el-icon-office-building"></el-input>
						</el-form-item>
						<el-form-item label="用户名" prop="username">
							<el-input placeholder="请输入用户名" v-model="ruleForm.username" @keyup.enter.native="login" clearable prefix-icon="el-icon-user-solid"></el-input>
						</el-form-item>
						<el-form-item label="密码" prop="password">
							<el-input placeholder="请输入密码" v-model="ruleForm.password" show-password @keyup.enter.native="login" clearable
							 prefix-icon="el-icon-lock"></el-input>
						</el-form-item>
					</el-form>
					<el-checkbox v-model="ruleForm.checked" class="remenberMe">记住我</el-checkbox>
					<div class="loginBotton"><button @click="login()">登录</button></div>
					<div class="loginFoot">
						<a class="forgetPassword">忘记密码</a>
					</div>
					<div>
						<!-- <el-button type="warning" plain>警告按钮</el-button> -->
						<!-- <a>Amazon登录</a> -->
						<a href="###" @click="loginAmazon()" id="LoginWithAmazon">
							<img border="0" alt="Login with Amazon" src="https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gold_156x32.png"
							 width="156" height="32" />
						</a>
						<a href="###" @click="logoutAmazon()">注销Amazon</a>
					</div>
				</div>
			</div>
		</div>
		<div class="footer">
			<div class="footMain">
				<div class="footLeft">
					<span>版权所有 © 2021 <a href="http://cloudagetech.com/">深圳云世科技有限公司</a><a href="https://beian.miit.gov.cn/"> ICP证: 粤ICP备20002471号</a></span>
				</div>
				<div class="footRight fr">
					<a href="#">法律声明</a>&nbsp;&nbsp;
					<a href="#">隐私政策声明</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import login from '../js/login'
	export default {
		name: "Login",
		data() {
			var validateUserName = (rule, value, callback) => {
				if (!/^\S+$/.test(value)) {
					callback(new Error('账号不能有空格'));
				}
			}
			return {
				input1: '',
				input2: '',
				ruleForm: {
					username: '',
					password: '',
					checked: false
				},
				rules: {
					enterprise_number: [{
						required: true,
						message: '请输入企业编码',
						trigger: 'blur'
					}, {
						validator: validateUserName,
						trigger: 'blur'
					}],
					username: [{
							required: true,
							message: '请输入用户名称',
							trigger: 'blur'
						}, {
							validator: validateUserName,
							trigger: 'blur'
						},
						{
							min: 4,
							max: 20,
							message: '长度在 4 到 20 个字符',
							trigger: 'blur'
						}
					],
					password: [{
							required: true,
							message: '请输入用户密码',
							trigger: 'blur'
						},
						{
							min: 6,
							max: 20,
							message: '长度在 6 到 20 个字符',
							trigger: 'blur'
						}
					]
				},
				distinguish: false
			}
		},
		methods: {
			login: function() {
				login.login(this);
				// this.$router.push("/toIndex");
			},
			loginAmazon: function() {
				var options = {};
				// options.scope = 'profile';
				options.response_type='code';
				options.scope = 'profile';
				// options.scope_data = {
				// 	'profile': {
				// 		'essential': false
				// 	}
				// };
				// amazon.Login.authorize(options,
				// 	'https://cloud.szxinken.com/index');
				// return false;
				// options.pkce = true;
				// amazon.Login.authorize(options, function(response) {
				// 	if (response.error) {
				// 		alert('oauth error ' + response.error);
				// 		return;
				// 	}
				// 	console.info(response);
				// 	alert(response.code);
					
				// 	// amazon.Login.retrieveToken(response.code, function(response) {
				// 	// 	if (response.error) {
				// 	// 		alert('oauth error ' + response.error);
				// 	// 		return;
				// 	// 	}
				// 	// 	console.info(response);
				// 	// 	alert(response.access_token);
				// 	// 	amazon.Login.retrieveProfile(response.access_token, function(response) {
				// 	// 		alert('Hello, ' + response.profile.Name);
				// 	// 		alert('Your e-mail address is ' + response.profile.PrimaryEmail);
				// 	// 		alert('Your unique ID is ' + response.profile.CustomerId);
				// 	// 		if (window.console && window.console.log)
				// 	// 			window.console.log(response);
				// 	// 	});
				// 	// });
				// });
				amazon.Login.authorize(options, function(response) {
					if ( response.error ) {
						alert('oauth error ' + response.error);
						return;
					}
					console.info(response);
					alert(response.code);
				});
			},
			logoutAmazon: function(){
				amazon.Login.logout();
			}
		},
		created() {

		},
		mounted() {
			window.onAmazonLoginReady = function() {
				amazon.Login.setClientId('amzn1.application-oa2-client.71b641b7f29e4ada8fc532fddf42ac3b');
			};
			(function(d) {
				var a = d.createElement('script');
				a.type = 'text/javascript';
				a.async = true;
				a.id = 'amazon-login-sdk';
				a.src = 'https://assets.loginwithamazon.com/sdk/na/login1.js';
				d.getElementById('amazon-root').appendChild(a);
			})(document);
		}
	};
</script>
<style scoped src="../css/login.css">
</style>
