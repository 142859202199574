<template>
	<div>
		<div class="shortcut">
			<div class="logo fl">
				<h1><a href="###" style="font-size:0">云世<img src="../assets/yunshi.png"></a></h1>
			</div>
			<div class="nav fr">
				<ul>
					<li><a href="#">首页</a></li>
					<li><a>帮助中心</a></li>
				</ul>
			</div>
		</div>
		<div class="registerForm">
			<el-form :model="registerForm" :rules="registers" ref="ruleForm" class="demo-ruleForm">
				<div id="title">用户注册</div>
				<el-form-item label="用户名" prop="username">
					<el-input v-model="registerForm.username" @keyup.enter.native="register" clearable></el-input>
				</el-form-item>
				<el-form-item label="密码" prop="password">
					<el-input type="password" v-model="registerForm.password" show-password @keyup.enter.native="register"
					 autocomplete="off" clearable></el-input>
				</el-form-item>
				<el-form-item label="确认密码" prop="checkPass">
					<el-input type="password" v-model="registerForm.checkPass" show-password @keyup.enter.native="register"
					 autocomplete="off" clearable></el-input>
				</el-form-item>
				<el-form-item label="手机号码" prop="phone">
					<el-input oninput ="value=value.replace(/[^0-9.]/g,'')" v-model="registerForm.phone" @keyup.enter.native="register" clearable></el-input>
				</el-form-item>
				<el-form-item label="邮箱" prop="email">
					<el-input v-model="registerForm.email" @keyup.enter.native="register" clearable></el-input>
				</el-form-item>
				<el-form-item label="性别">
					<el-radio-group v-model="registerForm.gender" size="medium" class="genderGroup">
						<el-radio border  label="男" class="genderRadio"></el-radio>
						<el-radio border  label="女" class="genderRadio"></el-radio>
					</el-radio-group>
				</el-form-item>
				<div>
					<button type="button" id="submit" @click="register">注册</button>
				</div>
			</el-form>
		</div>
		<div class="footer">
			<div class="footMain">
				<div class="footLeft">
					<span>版权所有 © 深圳云世科技有限公司 2023 保留一切权利</span>
				</div>
				<div class="footRight fr">
					<a href="#">法律声明</a>&nbsp;&nbsp;
					<a href="#">隐私政策声明</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import register from '../js/register'
	export default {
		name: "Register",
		data() {
			var validateUserName = (rule, value, callback) => {
				if (!/^[a-zA-Z0-9_-]{4,20}$/.test(value)) {//4到20位（字母，数字，下划线，减号）
					callback(new Error('用户名应为4到20位（字母，数字，下划线，减号）构成'));
				} else {
					callback();
				}
			};
			var validatePass = (rule, value, callback) => {
				if (!/^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9._~!?@#$^&*+-]{6,20}$/.test(value)) {
					callback(new Error('密码只能输入6-20个字母、数字、下划线等特殊字符'));
				} else if (!/^\S+$/.test(value)){
					callback(new Error('密码不能有空格'));
				} else if (!/^[^\u4e00-\u9fa5]+$/.test(value)) {
					callback(new Error('密码不能为中文'));
				} else {
					if (this.registerForm.checkPass !== '') {
						this.$refs.registerForm.validateField('checkPass');
					}
					callback();
				}
			};
			var validatecheckPass = (rule, value, callback) => {
				if (value !== this.registerForm.password) {
					callback(new Error('两次输入密码不一致!'));
				} else if (!/^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9._~!?@#$^&*+-]{6,20}$/.test(value)) {
					callback(new Error('密码只能输入6-20个字母、数字、下划线等特殊字符'));
				} else if (!/^\S+$/.test(value)){
					callback(new Error('密码不能有空格'));
				} else if (!/^[^\u4e00-\u9fa5]+$/.test(value)) {
					callback(new Error('密码不能为中文'));
				} else {
					callback();
				}
			};
			var validatorEmail = (rule, value, callback) => {
				if (!/^[^\u4e00-\u9fa5]+$/.test(value)) {
					callback(new Error('邮箱不能为中文'));
				} else if (/^\w{1,64}@[a-z0-9\-]{1,256}(\.[a-z]{2,6}){1,2}$/i.test(value) == false) {
					callback(new Error("邮箱格式错误"));
				} else {
					callback();
				}
			};

			var validatorPhone = (rule, value, callback) => {
				if (/^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[8-9])[0-9]{8}$/.test(value) == false) {
					callback(new Error("手机号格式错误"));
				} else {
					callback();
				}
			};
			return {
				registerForm: {
					username: '',
					password: '',
					checkPass: '',
					phone: '',
					email: '',
					gender: ''
				},
				registers: {
					username: [{
							required: true,
							message: '请输入用户名称',
							trigger: 'blur'
						},
						{	
							validator:validateUserName,
							trigger: 'blur'
						},
						{
							min: 4,
							max: 20,
							message: '用户名称长度为 4 到 20 个字符',
							trigger: 'blur'
						}
						
					],
					password: [{
							required: true,
							message: '请输入用户密码',
							trigger: 'blur'
						},
						{
							validator: validatePass,
							trigger: 'blur'
						}
					],
					checkPass: [{
							required: true,
							message: '请确认用户密码',
							trigger: 'blur'
						},
						{
							validator: validatecheckPass,
							trigger: 'blur'
						}
					],
					phone: [{
							required: true,
							message: '请输入手机号码',
							trigger: 'blur'
						},
						{
							min: 11,
							max: 11,
							message: '+86手机号码应为11位',
							trigger: "blur"
						}, {
							validator: validatorPhone,
							trigger: "blur"
						}
					],
					email: [{
						required: true,
						message: '请输入用户邮箱',
						trigger: 'blur'
					}, {
						validator: validatorEmail,
						trigger: 'blur'
					}]
				}
			}
		},
		methods: {
			register: function() {
				register.register(this);
			}
		}
	}
</script>

<style scoped src="../css/register.css">

</style>
