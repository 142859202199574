import {
	formatterEnum,
	pickRow,
	addTool,
	updateTool,
	idsTool,
	pageLoad
} from './cloudTool'

import $ from './jquery'
import '@ztree/ztree_v3/css/metroStyle/metroStyle.css'
import '@ztree/ztree_v3/js/jquery.ztree.all.min'
var token = sessionStorage.getItem("token");
export default {
	second: function(_this, token) {
		var token = sessionStorage.getItem("token");
		$(function() {
			$("#table").bootstrapTable({
				height: $(window).height()*0.7,//自定义高度
				url: "/wave-user/role/selectRoleAll", //请求地址
				striped: true, //是否显示行间隔色
				pageNumber: 1, //初始化加载第一页
				pagination: true, //是否分页
				sidePagination: 'client', //server:服务器端分页|client：前端分页
				pageSize: 5, //单页记录数
				pageList: [5, 10, 20, 50], //可选择单页记录数
				showRefresh: false, //刷新按钮
				clickToSelect: true, //是否启用点击选中行
				singleSelect: false, //开启单选,想要获取被选中的行数据必须要有该参数
				ajaxOptions: {
					headers: {
						"Authorization": "Bearer " + token
					}
				},
				columns: [{
					checkbox: true //第一列显示复选框
				}, {
					title: '角色id',
					field: 'roleId',
					sortable: true,
					visible: false
				}, {
					title: '角色名称',
					field: 'roleName',
					sortable: true
				}, {
					title: '角色描述',
					field: 'roleDescribe',
					sortable: true
				}, {
					title: '角色创建时间',
					field: 'roleCreateTime',
					sortable: true
				}, {
					title: '角色修改时间',
					field: 'roleUpdateTime',
					sortable: true
				}],
				onLoadSuccess: function(res) {
					$("#table").bootstrapTable("load", res.data)
				},
				onCheck: function(row, $element) {
					var rows = $("#table").bootstrapTable('getSelections');
					if (rows.length > 1) {
						treeLoad(_this, token);
						return;
					}
					treeCheckLoad(row, _this, token);
				},
				onUncheck: function(row, $element) {
					treeLoad(_this, token);
					var rows = $("#table").bootstrapTable('getSelections');
					if (rows.length == 1) {
						treeCheckLoad(rows[0], _this, token);
					}
				},
				onCheckAll: function(rowsAfter, rowsBefore) {
					treeLoad(_this, token);
				},
				onUncheckAll: function(rowsAfter, rowsBefore) {
					treeLoad(_this, token);
				}
			});
		});

		function treeLoad(_this, token) {
			$.ajax({
				type: "get",
				url: "/wave-user/menu/selectMenuAll",
				async: true,
				headers: {
					"Authorization": "Bearer " + token
				},
				success: function(res) {
					let zTreeObj;
					zTreeObj = $.fn.zTree.init($("#tree"), _this.settings, res.data); //初始化树
					zTreeObj.expandAll(false); //true 节点全部展开、false节点收缩
					_this.treeObj = $.fn.zTree.getZTreeObj("tree"); //获取树对象
				},
				error: function(xhr, errorText, errorType) {
					if (xhr.status == 403) {
						//do something
						// _this.$message.error("抱歉，您没有权限");
						return;
					}
					// _this.$message.error(xhr.responseJSON.error_description);
					// _this.$message.error(xhr.responseText);
				}
			});
		};

		function treeCheckLoad(row, _this, token) {
			var treeObj = $.fn.zTree.getZTreeObj("tree"); //获取树对象

			$.ajax({
				type: "GET",
				url: "/wave-user/permissions/getPermissionsByRoleId/" + row.roleId,
				contentType: "application/json;charset=UTF-8",
				dataType: "json",
				headers: {
					"Authorization": "Bearer " + token
				},
				success: function(res) {
					if (!res.data) {
						// _this.$message.error(res.message);
						return;
					}
					var nodes = treeObj.transformToArray(treeObj.getNodes());
					for (let i = 0; i < nodes.length; i++){
						for (let j = 0; j < res.data.length; j++){
							if (nodes[i].menuId !== res.data[j].menuId){
								continue;
							}
							treeObj.checkNode(nodes[i], true, true); //选中树节点
						}
					}
					// for (let i = 0; i < treeObj.getNodes().length; i++) {
					// 	var menu = treeObj.getNodes()[i].children; //找到菜单
					// 	for (let j = 0; j < menu.length; j++) { //循环菜单
					// 		for (let n = 0; n < res.data.length; n++) { //循环权限
					// 			//权限至少设置二级菜单
					// 			if (menu[j].menuParentId == res.data[n].permissionMenuId) { //对应权限菜单二级菜单
					// 				if (menu[j].menuName.indexOf("新增") != -1) {
					// 					treeObj.checkNode(menu[j], res.data[n].permissionInsert, res.data[n].permissionInsert); //选中树节点
					// 				}
					// 				if (menu[j].menuName.indexOf("修改") != -1) {
					// 					treeObj.checkNode(menu[j], res.data[n].permissionUpdate, res.data[n].permissionUpdate); //选中树节点
					// 				}
					// 				if (menu[j].menuName.indexOf("删除") != -1) {
					// 					treeObj.checkNode(menu[j], res.data[n].permissionDelete, res.data[n].permissionDelete); //选中树节点
					// 				}
					// 				if (menu[j].menuName.indexOf("查看") != -1) {
					// 					treeObj.checkNode(menu[j], res.data[n].permissionSelect, res.data[n].permissionSelect); //选中树节点
					// 				}
					// 			}
					// 			if (menu[j].menuId == res.data[n].permissionMenuId) { //对应权限菜单三级菜单
					// 				var option = menu[j].children;
					// 				for (let m = 0; m < option.length; m++) {
					// 					if (option[m].menuName.indexOf("新增") != -1) {
					// 						treeObj.checkNode(option[m], res.data[n].permissionInsert, res.data[n].permissionInsert); //选中树节点
					// 					}
					// 					if (option[m].menuName.indexOf("修改") != -1) {
					// 						treeObj.checkNode(option[m], res.data[n].permissionUpdate, res.data[n].permissionUpdate); //选中树节点
					// 					}
					// 					if (option[m].menuName.indexOf("删除") != -1) {
					// 						treeObj.checkNode(option[m], res.data[n].permissionDelete, res.data[n].permissionDelete); //选中树节点
					// 					}
					// 					if (option[m].menuName.indexOf("查看") != -1) {
					// 						treeObj.checkNode(option[m], res.data[n].permissionSelect, res.data[n].permissionSelect); //选中树节点
					// 					}
					// 				}
					// 			}
					// 		}
					// 	}
					// }

				}
			});
		}
	},
	first: function(_this, token) {
		pageLoad("role", _this);
		this.treeLoad(_this);
	},
	add: function(_this) {
		addTool(_this, "新增角色", "role");
		this.treeLoad(_this);
	},
	update: function(_this) {
		var row = pickRow(_this);
		updateTool(_this, row, "修改角色", "role");
		this.treeLoad(_this);
	},
	saveOrUpdate: function(_this) {
		var token = sessionStorage.getItem("token");
		if (!_this.role.roleName) {
			_this.$message.warning('请输入角色名称');
			return;
		}
		if (!_this.role.roleDescribe) {
			_this.$message.warning('请输入角色描述');
			return;
		}
		if (!/^\S+$/.test(_this.role.roleName) || !/^\S+$/.test(_this.role.roleDescribe)) {
			_this.$message.warning('输入不能有空格');
			return;
		}

		var url = _this.role.roleId == null ? "/wave-user/role/insertRole" : "/wave-user/role/updateRole";
		$.ajax({
			type: "POST",
			url: url,
			contentType: "application/json;charset=UTF-8",
			dataType: "json",
			headers: {
				"Authorization": "Bearer " + token
			},
			data: JSON.stringify(_this.role),
			success: function(result) {
				if (result.code == 20000) {
					_this.$message.success(result.message);
					$('#table').bootstrapTable('refresh');
					_this.dialogFormVisible = false;
				} else {
					_this.$message.warning(result.message);
				}
			},
			error: function(xhr, errorText, errorType) {
				if (xhr.status == 403) {
					//do something
					_this.$message.error("抱歉，您没有权限");
					return;
				}
				// _this.$message.error(xhr.responseJSON.error_description);
				// _this.$message.error(xhr.responseText);
			}
		});

	},
	remove: function(_this) {
		var token = sessionStorage.getItem("token");
		//判断删除是否选中行
		var row = pickRow(_this);
		if (!row) {
			return;
		}
		var ids = idsTool(row, "user");
		_this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'warning'
		}).then(() => {
			var ids = idsTool(row, "role");
			$.ajax({
				type: "POST",
				contentType: "application/json;charset=UTF-8",
				url: "/wave-user/role/deleteRole",
				dataType: "json",
				headers: {
					"Authorization": "Bearer " + token
				},
				data: JSON.stringify(ids),
				success: function(result) {
					if (result.code == 20000) {
						_this.$message.success(result.message);
					} else {
						_this.$message.error(result.message);
					}
					// window.location.href = "/wave-user/to/toUser";
					$('#table').bootstrapTable('refresh');

				},
				error: function(xhr, errorText, errorType) {
					if (xhr.status == 403) {
						//do something
						_this.$message.error("抱歉，您没有权限");
						return;
					}
					// _this.$message.error(xhr.responseJSON.error_description);
					// _this.$message.error(xhr.responseText);
				}
			});
		}).catch(() => {
			_this.$message({
				type: 'info',
				message: '已取消删除'
			});
		});

		this.treeLoad(_this);
	},
	clear: function(_this) {
		_this.searchRoleVo.searchInfo = "";
	},
	search: function(_this) {
		var token = sessionStorage.getItem("token");
		$.ajax({
			type: "POST",
			contentType: "application/json;charset=UTF-8",
			url: "/wave-user/role/searchRoleInfo",
			dataType: "json",
			headers: {
				"Authorization": "Bearer " + token
			},
			data: JSON.stringify(_this.searchRoleVo),
			success: function(res) {
				// window.location.href = "/wave-user/to/toUser";
				$('#table').bootstrapTable('load', res.data);
			},
			error: function(xhr, errorText, errorType) {
				if (xhr.status == 403) {
					//do something
					_this.$message.error("抱歉，您没有权限");
					return;
				}
				// _this.$message.error(xhr.responseJSON.error_description);
				// _this.$message.error(xhr.responseText);
			}
		});
		this.treeLoad(_this);
	},
	reset: function(_this) {
		_this.searchRoleVo.searchInfo = "";
		_this.searchRoleVo.start = "";
		_this.searchRoleVo.end = "";
		$('#table').bootstrapTable('refresh');

	},
	// 权限保存
	permission: function(_this) {
		var token = sessionStorage.getItem("token");
		var row = pickRow(_this);
		if (!row || row.length > 1) {
			if (row.length > 1) {
				_this.$message.warning('请选择单行进行权限分配！');
			}
			return;
		} else {
			var roleId = row[0].roleId;
			var nodes = _this.treeObj.getCheckedNodes(true); //获取树节点
			var permissionInfo = [];

			for (let i = 0; i < nodes.length; i++) { //循环找到菜单，并赋予菜单下（增删改查作为子菜单）操作权限
				var permissionData = {};
				// if (nodes[i].menuName.indexOf("新增") != -1 || nodes[i].menuName.indexOf("修改") != -1 || nodes[i].menuName.indexOf(
				// 		"删除") != -1 || nodes[i].menuName.indexOf("查看") != -1) { //找到最底层菜单（增删改查权限）
				// 	for (let j = 0; j < nodes.length; j++) {
				// 		if (nodes[j].menuId === nodes[i].menuParentId || nodes[j].menuParentId === nodes[i].menuParentId) {
				// 			permissionData.roleId = roleId;
				// 			permissionData.menuId = nodes[i].menuParentId; //取父级菜单id，接下来判断该父级下面的操作权限
				// 			if (nodes[j].menuName.indexOf("新增") != -1) {
				// 				permissionData.menuInsert = 1;
				// 			} else if (nodes[j].menuName.indexOf("修改") != -1) {
				// 				permissionData.menuUpdate = 1;
				// 			} else if (nodes[j].menuName.indexOf("删除") != -1) {
				// 				permissionData.menuDelete = 1;
				// 			} else if (nodes[j].menuName.indexOf("查看") != -1) {
				// 				permissionData.menuSelect = 1;
				// 			}
				// 		}
				// 	}
				// }
				// permissionInfo[i] = permissionData; //至此会出现多条空数据，并且有重复数据
				permissionData.rmRoleId = roleId;
				permissionData.rmMenuId = nodes[i].menuId;
				permissionInfo[i] = permissionData;
			}
			// for (let i = 0; i < permissionInfo.length; i++) {
			// 	if (permissionInfo[i].menuId) {
			// 		if (permissionInfo[i + 1]) {
			// 			if (permissionInfo[i + 1].menuId == permissionInfo[i].menuId) { //去除重复数据
			// 				delete permissionInfo[i];
			// 			}
			// 		}
			// 	} else { //去除无效数据
			// 		delete permissionInfo[i];
			// 	}
			// } //此循环结束会去重并出现数组下标不连续

			// permissionInfo = permissionInfo.filter(item => item); //去除空数据并且保证下标顺序正确（此方法去空并不能去除数组内空对象，所以上一步必须delete去除无效数据，故此处作用为重置数组顺序）
			
			if(permissionInfo.length == 0){
				_this.$confirm('是否确认分配, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					var permissionData = {};
					permissionData.rmRoleId = roleId
					permissionInfo[0] = permissionData;
					$.ajax({
						type: "POST",
						url: "/wave-user/permissions/savePermission",
						contentType: "application/json;charset=UTF-8",
						dataType: "json",
						headers: {
							"Authorization": "Bearer " + token
						},
						data: JSON.stringify(permissionInfo),
						success: function(result) {
							if (result.code == 20000) {
								$('#table').bootstrapTable('refresh');
								_this.$message.success(result.message);
							} else {
								_this.$message.warning(result.message);
							}
						},
						error: function(xhr, errorText, errorType) {
							if (xhr.status == 403) {
								//do something
								_this.$message.error("抱歉，您没有权限");
								return;
							}
							// _this.$message.error(xhr.responseJSON.error_description);
							// _this.$message.error(xhr.responseText);
						}
					});
				}).catch(() => {
					_this.$message({
						type: 'info',
						message: '已取消权限分配'
					});
					return;
				});
			} else {
				$.ajax({
					type: "POST",
					url: "/wave-user/permissions/savePermission",
					contentType: "application/json;charset=UTF-8",
					dataType: "json",
					headers: {
						"Authorization": "Bearer " + token
					},
					data: JSON.stringify(permissionInfo),
					success: function(result) {
						if (result.code == 20000) {
							$('#table').bootstrapTable('refresh');
							_this.$message.success(result.message);
						} else {
							_this.$message.warning(result.message);
						}
					},
					error: function(xhr, errorText, errorType) {
						if (xhr.status == 403) {
							//do something
							_this.$message.error("抱歉，您没有权限");
							return;
						}
						// _this.$message.error(xhr.responseJSON.error_description);
						// _this.$message.error(xhr.responseText);
					}
				});
			}
		}
		this.treeLoad(_this);
	},
	treeLoad: function(_this) {
		var token = sessionStorage.getItem("token");
		$.ajax({
			type: "get",
			url: "/wave-user/menu/selectMenuAll",
			async: true,
			headers: {
				"Authorization": "Bearer " + token
			},
			success: function(res) {
				let zTreeObj;
				zTreeObj = $.fn.zTree.init($("#tree"), _this.settings, res.data); //初始化树
				zTreeObj.expandAll(false); //true 节点全部展开、false节点收缩
				_this.treeObj = $.fn.zTree.getZTreeObj("tree"); //获取树对象
			},
			error: function(xhr, errorText, errorType) {
				if (xhr.status == 403) {
					//do something
					// _this.$message.error("抱歉，您没有权限");
					return;
				}
				// _this.$message.error(xhr.responseJSON.error_description);
				// _this.$message.error(xhr.responseText);
			}

		});
	},
	treeCheckLoad: function(row) {
		var token = sessionStorage.getItem("token");
		var treeObj = $.fn.zTree.getZTreeObj("tree"); //获取树对象
		$.ajax({
			type: "get",
			url: "/wave-user/permissions/selectPermissions/" + row.roleId,
			success: function(res) {
				for (let i = 0; i < treeObj.getNodes().length; i++) {
					var menu = treeObj.getNodes()[i].children; //找到菜单
					for (let j = 0; j < menu.length; j++) { //循环菜单
						for (let n = 0; n < res.data.length; n++) { //循环权限
							if (menu[j].menuId == res.data[n].permissionMenuId) { //对应权限菜单
								var option = menu[j].children;
								for (let m = 0; m < option.length; m++) {
									if (option[m].menuName.indexOf("新增") != -1) {
										treeObj.checkNode(option[m], res.data[n].permissionInsert, res.data[n].permissionInsert); //选中树节点
									}
									if (option[m].menuName.indexOf("修改") != -1) {
										treeObj.checkNode(option[m], res.data[n].permissionUpdate, res.data[n].permissionUpdate); //选中树节点
									}
									if (option[m].menuName.indexOf("删除") != -1) {
										treeObj.checkNode(option[m], res.data[n].permissionDelete, res.data[n].permissionDelete); //选中树节点
									}
									if (option[m].menuName.indexOf("查看") != -1) {
										treeObj.checkNode(option[m], res.data[n].permissionSelect, res.data[n].permissionSelect); //选中树节点
									}
								}
							}
						}
					}
				}

			}
		});
	}
}
