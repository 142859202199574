<template>
	<div id="app" class="container-fluid">
		<div class="header row">
			<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 top"><span class="badge badge-primary">设备管理</span></div>
			<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 top"></div>
			<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 top"></div>
			<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 top"></div>
		</div>
		<div class="main row">
			<el-dialog :title="title" :visible.sync="dialogFormVisible" @close="closeDialog"
				:close-on-click-modal='false' :append-to-body='true'>
				<div id="deviceForm">
					<el-form :model="device" :rules="devices" ref="deviceForm" class="demo-ruleForm">
						<el-form-item label="设备名称" prop="devName">
							<el-input v-model="device.devName" @keyup.enter.native="saveOrUpdate" clearable></el-input>
						</el-form-item>
						<el-form-item label="所属用户" prop="user" v-show="userDisabled">
							<el-select v-model="device.devUserId" filterable placeholder="请选择" class="userVal">
								<el-option v-for="user in userList" :value="user.userId" :key="user.userId"
									:label="user.userName">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="厂商名称" prop="devEnterpriseName">
							<el-select v-model="device.devEnterpriseId" filterable placeholder="请选择"
								class="enterpriseVal">
								<el-option v-for="enterprise in enterpriseList" :value="enterprise.enterpriseId"
									:key="enterprise.enterpriseId" :label="enterprise.enterpriseName">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="MAC地址" prop="devMac">
							<el-input v-model="device.devMac" @keyup.enter.native="saveOrUpdate" clearable></el-input>
						</el-form-item>
						<el-form-item label="SN号" prop="devSerialNumber">
							<el-input v-model="device.devSerialNumber" @keyup.enter.native="saveOrUpdate" clearable>
							</el-input>
						</el-form-item>
						<el-form-item label="设备协议" prop="devProtocol">
							<el-input v-model="device.devProtocol" @keyup.enter.native="saveOrUpdate" clearable>
							</el-input>
						</el-form-item>
						<el-form-item label="安装地址" prop="devAddress">
							<el-input v-model="device.devAddress" @keyup.enter.native="saveOrUpdate" clearable>
							</el-input>
						</el-form-item>
						<el-form-item label="所属产品" prop="product">
							<el-select v-model="device.devPdId" filterable placeholder="请选择" class="productVal"
								clearable>
								<el-option v-for="product in productList" :value="product.pdId" :key="product.pdId"
									:label="product.pdName">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="固件版本" prop="devFirmwareVersion">
							<el-input v-model="device.devFirmwareVersion" @keyup.enter.native="saveOrUpdate" clearable>
							</el-input>
						</el-form-item>
						<el-form-item label="硬件版本" prop="devHardwareVersion">
							<el-input v-model="device.devHardwareVersion" @keyup.enter.native="saveOrUpdate" clearable>
							</el-input>
						</el-form-item>
						<el-form-item label="SDK版本" prop="devSdkVersion">
							<el-input v-model="device.devSdkVersion" @keyup.enter.native="saveOrUpdate" clearable>
							</el-input>
						</el-form-item>
						<el-form-item label="设备升级状态(OTA)">
							<el-select v-model="device.devUpgradeStatus" placeholder="请选择" class="otaStatusVal">
								<el-option v-for="item in statusList" :key="item.id" :value="item.value"
									:label="item.label">
								</el-option>
							</el-select>
						</el-form-item>
					</el-form>
				</div>
				<div slot="footer" class="dialog-footer">
					<el-button @click="closeDialog">取 消</el-button>
					<el-button type="primary" @click="saveOrUpdate">确 定</el-button>
				</div>
			</el-dialog>



			<!-- 搜索框 start -->
			<div class="col-md-12 collapse search" id="collapseExample">

				<div class="col-md-6 input-group input-group-sm ">
					<input type="text" class="col-md-6 form-control" placeholder="请输入名称或者描述搜索"
						v-model="searchDeviceVo.searchInfo">
					<input type="date" class="col-md-3 form-control" aria-label="Sizing example input"
						aria-describedby="inputGroup-sizing-sm" v-model="searchDeviceVo.startCreateTime">
					<div class="input-group-append">
						<span class="input-group-text">至</span>
					</div>
					<input type="date" class="col-md-3 form-control" aria-label="Sizing example input"
						aria-describedby="inputGroup-sizing-sm" v-model="searchDeviceVo.endCreateTime">
				</div>

				<div class="col-md-4">
					<button type="button" class="btn btn-info btn-sm" @click="search">
						<svg class="bi bi-search" focusable="false" aria-hidden="true" width="1em" height="1em"
							viewBox="0 0 18 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd"
								d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
								clip-rule="evenodd" />
							<path fill-rule="evenodd"
								d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
								clip-rule="evenodd" />
						</svg>
						搜&nbsp;索
					</button>

					<button type="button" class="btn btn-warning btn-sm" @click="reset">
						<svg width="1em" height="1em" viewBox="0 0 18 20" class="bi bi-arrow-counterclockwise"
							fill="currentColor" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd"
								d="M12.83 6.706a5 5 0 0 0-7.103-3.16.5.5 0 1 1-.454-.892A6 6 0 1 1 2.545 5.5a.5.5 0 1 1 .91.417 5 5 0 1 0 9.375.789z" />
							<path fill-rule="evenodd"
								d="M7.854.146a.5.5 0 0 0-.708 0l-2.5 2.5a.5.5 0 0 0 0 .708l2.5 2.5a.5.5 0 1 0 .708-.708L5.707 3 7.854.854a.5.5 0 0 0 0-.708z" />
						</svg>
						重&nbsp;置
					</button>
				</div>
			</div>
			<!-- 搜索框 end -->

			<!-- 左侧功能按钮 start -->
			<div class="col-md-3 left-botton">
				<button type="button" class="btn btn-sm btn-outline-success" data-toggle="modal" data-target=""
					@click="add" id="add" v-show="addIsShow">
					<svg class="bi bi-plus" focusable="false" aria-hidden="true" width="1.2em" height="1.2em"
						viewBox="0 0 15 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd"
							d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z"
							clip-rule="evenodd" />
						<path fill-rule="evenodd" d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z"
							clip-rule="evenodd" />
					</svg>&nbsp;新增
				</button>
				<button type="button" class="btn btn-sm btn-outline-warning" data-toggle="modal" data-target=""
					@click="update" id="update" v-show="updateIsShow">
					<svg class="bi bi-pen" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor"
						xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd"
							d="M5.707 13.707a1 1 0 0 1-.39.242l-3 1a1 1 0 0 1-1.266-1.265l1-3a1 1 0 0 1 .242-.391L10.086 2.5a2 2 0 0 1 2.828 0l.586.586a2 2 0 0 1 0 2.828l-7.793 7.793zM3 11l7.793-7.793a1 1 0 0 1 1.414 0l.586.586a1 1 0 0 1 0 1.414L5 13l-3 1 1-3z"
							clip-rule="evenodd" />
						<path fill-rule="evenodd"
							d="M9.854 2.56a.5.5 0 0 0-.708 0L5.854 5.855a.5.5 0 0 1-.708-.708L8.44 1.854a1.5 1.5 0 0 1 2.122 0l.293.292a.5.5 0 0 1-.707.708l-.293-.293z"
							clip-rule="evenodd" />
						<path
							d="M13.293 1.207a1 1 0 0 1 1.414 0l.03.03a1 1 0 0 1 .03 1.383L13.5 4 12 2.5l1.293-1.293z" />
					</svg>&nbsp;修改
				</button>
				<button type="button" class="btn btn-sm btn-outline-danger" @click="remove" id="remove"
					v-show="removeIsShow">
					<svg class="bi bi-trash" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
						<path fill-rule="evenodd"
							d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
							clip-rule="evenodd" />
					</svg>&nbsp;删除
				</button>
			</div>
			<!-- 左侧功能按钮 end -->

			<div class="col-md-8"></div>

			<!-- 右侧功能按钮 start-->
			<div class="col-md-1 btn-group right-botton btn-group-sm" role="group" aria-label="...">
				<button type="button" class="btn btn-outline-secondary" data-toggle="collapse"
					data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" @click="clear">
					<svg class="bi bi-search" focusable="false" aria-hidden="true" width="1em" height="1em"
						viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd"
							d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
							clip-rule="evenodd" />
						<path fill-rule="evenodd"
							d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
							clip-rule="evenodd" />
					</svg>
				</button>
				<button type="button" class="btn btn-outline-secondary" @click="search">
					<svg class="bi bi-arrow-repeat" focusable="false" aria-hidden="true" width="1em" height="1em"
						viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd"
							d="M2.854 7.146a.5.5 0 0 0-.708 0l-2 2a.5.5 0 1 0 .708.708L2.5 8.207l1.646 1.647a.5.5 0 0 0 .708-.708l-2-2zm13-1a.5.5 0 0 0-.708 0L13.5 7.793l-1.646-1.647a.5.5 0 0 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0 0-.708z"
							clip-rule="evenodd" />
						<path fill-rule="evenodd"
							d="M8 3a4.995 4.995 0 0 0-4.192 2.273.5.5 0 0 1-.837-.546A6 6 0 0 1 14 8a.5.5 0 0 1-1.001 0 5 5 0 0 0-5-5zM2.5 7.5A.5.5 0 0 1 3 8a5 5 0 0 0 9.192 2.727.5.5 0 1 1 .837.546A6 6 0 0 1 2 8a.5.5 0 0 1 .501-.5z"
							clip-rule="evenodd" />
					</svg>
				</button>
			</div>
			<!-- 右侧功能按钮 end-->



			<!-- 设备信息表格-->
			<div class="table table-sm col-md-12">
				<table id="table"></table>
			</div>

		</div>

		<div class="footer row">
			<div class="col-md-4"></div>
			<div class="col-md-4"></div>
			<div class="col-md-4"></div>
		</div>
	</div>
</template>

<script>
	import device from '../js/device'
	export default {
		name: "Device",
		data() {
			var validate = (rule, value, callback) => {
				if (!/^\S+$/.test(value)) {
					callback(new Error('输入不能有空格'));
				}
			};
			return {
				statusList: [{
						id: 0,
						label: '启用',
						value: 'ENABLE'
					},
					{
						id: 1,
						label: '禁用',
						value: 'DISABLE'
					},
				],
				productList: [],
				userList: [],
				enterpriseList: [],
				title: "",
				addIsShow: false,
				removeIsShow: false,
				updateIsShow: false,
				dialogFormVisible: false,
				userDisabled: false,
				device: {},
				devices: {
					devName: [{
							required: true,
							message: '请输入设备名称',
							trigger: 'blur'
						},
						{
							validator: validate,
							trigger: 'blur'
						}
					],
					devEnterpriseName: [
						// {
						// 	required: true,
						// 	message: '请输入厂商名称',
						// 	trigger: 'blur'
						// },
						// {
						// 	validator: validate,
						// 	trigger: 'blur'
						// }
					],
					devMac: [{
							required: true,
							message: '请输入设备MAC地址',
							trigger: 'blur'
						},
						{
							validator: validate,
							trigger: 'blur'
						}
					],
					devSerialNumber: [
						// {
						// 	required: true,
						// 	message: '请输入设备序列号',
						// 	trigger: 'blur'
						// },
						// {
						// 	validator: validate,
						// 	trigger: 'blur'
						// }
					],
					devSn: [
						// {
						// 	required: true,
						// 	message: '请输入设备SN号',
						// 	trigger: 'blur'
						// },
						// {
						// 	validator: validate,
						// 	trigger: 'blur'
						// },
						// {
						// 	min: 4,
						// 	max: 20,
						// 	message: '设备名称长度为 4 到 20 个字符',
						// 	trigger: 'blur'
						// }
					],
					devProtocol: [
						// {
						// 	required: true,
						// 	message: '请输入设备协议',
						// 	trigger: 'blur'
						// },
						// {
						// 	validator: validate,
						// 	trigger: 'blur'
						// }
					],
					devAddress: [
						// {
						// 	required: true,
						// 	message: '请输入设备安装地址',
						// 	trigger: 'blur'
						// },
						// {
						// 	validator: validate,
						// 	trigger: 'blur'
						// }
					],
					user: [
						// {
						// 	required: true,
						// 	message: '请选择',
						// 	trigger: 'blur'
						// }
					],
					product: [{
						// required: true,
						// message: '请选择产品',
						// trigger: 'change'
					}],
					devFirmwareVersion: [{}],
					devHardwareVersion: [{}],
					devSdkVersion: [{}]
				},

				searchDeviceVo: {
					searchInfo: "",
					startCreateTime: "",
					endCreateTime: "",
				},
			}
		},
		created() {
			var token = sessionStorage.getItem("token");
			device.first(this, token);
			device.second(this, token);
		},
		methods: {
			add: function() {
				device.add(this)
			},
			update: function() {
				device.update(this)
			},
			saveOrUpdate: function() {
				device.saveOrUpdate(this)
			},
			remove: function() {
				device.remove(this)
			},
			clear: function() {
				device.clear(this)
			},
			search: function() {
				device.search(this)
			},
			reset: function() {
				device.reset(this)
			},
			closeDialog: function() {
				this.dialogFormVisible = false;
				this.$refs.deviceForm.resetFields();
			}
		}
	}
</script>

<style scoped src="../css/device.css">

</style>
