<template>
	<div class="row">
		<div class="col-md-10 mx-auto" style="display: flex; justify-content: center;align-items: center; margin-top: 8%;">
			<h5 style="color : #006aef;  font-family : 楷体,sans-serif;font-size : 20pt;  letter-spacing : 2pt; font-weight:bold; ">欢迎<span
				>{{username}}</span> 登录
				<img style="-webkit-user-drag: none;" oncontextmenu="return false;" src="../assets/yunshi.png" height="500"
				 width="500"> 后台管理系统
			</h5>
		</div>
	</div>
</template>

<script>
	import $ from 'jquery'
	export default {
		name: "Welcome",
		data() {
			return {
				username: "",
			}
		},
		created() {
			var userName = sessionStorage.getItem("username");
			this.username = userName;
		}
	}
</script>

<style scoped>

</style>
