<template>
	<div id="app">
		<div class="shortcut">
			<div class="fl shortcutl">
				<!-- <a href="#" id="menuName" class="menuName"><i class="fa fa-bars" aria-hidden="true"></i><span>菜单 &nbsp;</span></a> -->
				<h1 class="logo"><a href="/index" style="font-size:0">云世<img src="../assets/yunshi.png"></a></h1>
			</div>
			<div class="nav fr">

				<div class="btn-group">
					<button id="userName" class="btn btn-sm dropdown-toggle  btn-outline-secondary" type="button" data-toggle="dropdown"
					 aria-haspopup="true" aria-expanded="false">
						{{username}}
					</button>

					<div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
						<a class="dropdown-item" href="/toPersonal" target="rightContent">个人中心</a>
						<a class="dropdown-item" @click="logout()">注销</a>
					</div>
				</div>
			</div>
		</div>
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>标定工具</span>
			</div>
			<el-form ref="distinguishForm" :model="distinguish" label-width="80px">
				<el-row :gutter="20">
					<el-col :span="6">
						<el-form-item label="产线名称:">
							<el-input placeholder="请输入产线名称" v-model="lineName" :disabled="disabled" style="width: 100%;"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="超时设置:">
							<el-input placeholder="请输入超时时间单位:秒" v-model="timeout" :disabled="disabled" oninput="value=value.replace(/[^0-9.]/g,'')"
							 style="width: 100%;"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="产品:">
							<el-select v-model="productId" filterable placeholder="请选择产品" class="productVal" style="width: 100%;" :disabled="disabled">
								<el-option v-for="product in productList" :value="product.pdId" :key="product.pdId" :label="product.pdName">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-button type="warning" style="width: 100%;" round plain @click="lock_unlock">锁定/解锁</el-button>
					</el-col>

					<el-dialog title="锁定/解锁" :visible.sync="dialogFormVisible" width="30%">
						<div class="error"></div>
						<el-form :model="loginForm" :rules="logins" ref="ruleForm" class="demo-ruleForm formMain">
							<el-form-item label="企业编码" prop="enterprise_number">
								<el-input placeholder="请输入企业编码" v-model="loginForm.enterprise_number" @keyup.enter.native="login" clearable prefix-icon="el-icon-office-building"></el-input>
							</el-form-item>
							<el-form-item label="用户名" prop="username">
								<el-input placeholder="请输入用户名" v-model="loginForm.username" @keyup.enter.native="login" clearable prefix-icon="el-icon-user-solid"></el-input>
							</el-form-item>
							<el-form-item label="密码" prop="password">
								<el-input placeholder="请输入密码" v-model="loginForm.password" show-password @keyup.enter.native="login" clearable
								 prefix-icon="el-icon-lock"></el-input>
							</el-form-item>
						</el-form>
						<div slot="footer" class="dialog-footer">
							<el-button @click="dialogFormVisible = false">取 消</el-button>
							<el-button type="primary" @click="login">确 定</el-button>
						</div>
					</el-dialog>
				</el-row>
				<el-card class="box-card-interior" shadow="never">
					<el-row :gutter="20">
						<el-col :span="16">
							<el-form-item label="设备信息:">
								<el-card class="box-card-upper_left" shadow="never">
									<el-input v-model="mac" :disabled="disabled_mac" placeholder="请扫码" autofocus @keyup.enter.native="check_mac"
									 v-loading.fullscreen.lock="fullscreenLoading"></el-input>
									<div class="passed" v-if="passed === 'PASS'" style="background-color: rgba(103,194,58,0.77);">
										{{passed}}
									</div>
									<div class="passed" v-else-if="passed === 'NG'" style="background-color: rgba(255, 0, 0, 0.8);">
										{{passed}}
									</div>
									<div class="passed" v-else-if="passed">
										{{passed}}
									</div>
								</el-card>
							</el-form-item>
						</el-col>
						<el-col :span="8">
							<el-form-item label="成功率:">
								<el-card class="box-card-upper_right" shadow="never">
									<!-- <div class="success_rate">{{success_rate}}</div> -->
									<el-progress type="dashboard" :percentage="percentage" :color="colors"></el-progress>
								</el-card>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row :gutter="20">
						<el-col :span="24">
							<el-steps :active="process" finish-status="success" :process-status="process_status">
								<el-step title="MAC扫描"></el-step>
								<el-step title="MAC校验"></el-step>
								<el-step title="推送固件"></el-step>
								<el-step title="固件校验"></el-step>
								<el-step title="录入结果"></el-step>
							</el-steps>
						</el-col>
					</el-row>
					<el-row :gutter="20">
						<el-col :span="24">
							<el-form-item label="历史信息:">
								<el-card class="box-card-bottom_left" shadow="never">
									<el-table :data="tableData" stripe style="width: 100%" height="260">
										<el-table-column prop="dlogCreateDate" label="日期" width="180">
										</el-table-column>
										<el-table-column prop="dlogLineName" label="产线">
										</el-table-column>
										<el-table-column prop="dlogTimeout" label="超时设置(单位:秒)">
										</el-table-column>
										<el-table-column prop="dlogMac" label="设备mac">
										</el-table-column>
										<el-table-column prop="dlogStatus" label="状态">
											<template scope='scope'>
												<span v-if="scope.row.dlogStatus === 'PASS'" style="color: #42B983;">{{scope.row.dlogStatus}}</span>
												<span v-else-if="scope.row.dlogStatus === 'NG'" style="color: red;">{{scope.row.dlogStatus}}</span>
											</template>
										</el-table-column>
										<el-table-column prop="dlogAssignableCauses" label="异常原因">
											<template scope='scope'>
												<span v-if="scope.row.dlogAssignableCauses != '' || scope.row.dlogAssignableCauses != null" style="color: red;">{{scope.row.dlogAssignableCauses}}</span>
											</template>
										</el-table-column>
									</el-table>
								</el-card>
							</el-form-item>
						</el-col>
					</el-row>
				</el-card>

			</el-form>
		</el-card>
	</div>
</template>
<script>
	import distinguish from '../js/distinguish'
	import mqtt from 'mqtt'
	export default {
		name: "Distinguish",
		data() {
			var validateUserName = (rule, value, callback) => {
				if (!/^\S+$/.test(value)) {
					callback(new Error('账号不能有空格'));
				}
			}
			return {
				process_status: "",
				process: 0,
				loginForm: {
					username: '',
					password: '',
					checked: false
				},
				logins: {
					enterprise_number:[{
						required: true,
							message: '请输入企业编码',
							trigger: 'blur'
						}, {
							validator: validateUserName,
							trigger: 'blur'
					}],
					username: [{
							required: true,
							message: '请输入用户名称',
							trigger: 'blur'
						}, {
							validator: validateUserName,
							trigger: 'blur'
						},
						{
							min: 4,
							max: 20,
							message: '长度在 4 到 20 个字符',
							trigger: 'blur'
						}
					],
					password: [{
							required: true,
							message: '请输入用户密码',
							trigger: 'blur'
						},
						{
							min: 6,
							max: 20,
							message: '长度在 6 到 20 个字符',
							trigger: 'blur'
						}
					]
				},
				dialogFormVisible: false,
				lineName: "",
				timeout: "",
				productId: "",
				username: "用户名",
				percentage: 95,
				colors: [{
						color: '#ff0000',
						percentage: 90
					},
					{
						color: '#e6a23c',
						percentage: 95
					},
					{
						color: '#5cb87a',
						percentage: 100
					}
				],
				fullscreenLoading: false,
				mac: "",
				distinguish: {},
				passed: "message",
				success_rate: "100%",
				tableData: [],
				productList: [],
				value: '',
				disabled: true,
				disabled_mac: false,
				mtopic: "cloud_admin_test11",
				msg: "test",
			}
		},
		created() {
			distinguish.pageLoad(this);
		},
		mounted() {
			this.client = mqtt.connect("ws://cloud.cloudagetech.com:8083/mqtt", {
			// this.client = mqtt.connect("ws://cloud.cloudagetech.com:8083/mqtt", {
				clientId: 'distinguish_' + Math.random().toString(16).substr(2, 8),
				username: "distinguish",
				password: "distinguish",
				clean: true
			});
			// this.client.on("connect", e => {
			// 	console.log("连接成功");
			// 	this.client.subscribe(this.mtopic, (err) => {
			// 		if (!err) {
			// 			console.log("订阅成功:" + this.mtopic+"/#");
			// 		}
			// 	});
			// });
			// this.client.on("message", (topic, message) => {
			// 	this.msg = message
			// 	console.log("message:" + this.msg);
			// });
		},
		methods: {
			push_fireware: function() {
				distinguish.push_fireware(this);
			},
			lock_unlock: function() {
				distinguish.lock_unlock(this);
			},
			check_mac: function() {
				// const loading = this.$loading({
				// 	lock: true,
				// 	text: 'Loading',
				// 	spinner: 'el-icon-loading',
				// 	background: 'rgba(0, 0, 0, 0.7)'
				// });
				// alert(this.mac)
				// loading.close();

				// this.fullscreenLoading = true;
				// setTimeout(() => {
				// 	this.fullscreenLoading = false;
				// }, 2000);

				distinguish.check_mac(this);
			},
			login: function() {
				distinguish.login(this);
			},
			logout: function() {
				distinguish.logout(this);
			}
		}
	}
</script>

<style scoped src="../css/distinguish.css">
</style>
