import $ from 'jquery'
import {
	formatterEnum,
	pickRow,
	addTool,
	updateTool,
	idsTool,
	pageLoad
} from './cloudTool'
var token = sessionStorage.getItem("token");
export default {
	second: function(_this,token) {
		var token = sessionStorage.getItem("token");
		var userType = sessionStorage.getItem("userType");
		$(function() {
			$("#table").bootstrapTable({
				height: $(window).height()*0.7,//自定义高度
				url: "/wave-device/device/getDeviceAll_admin", //请求地址
				striped: true, //是否显示行间隔色
				pageNumber: 1, //初始化加载第一页
				ajaxOptions: {
					headers: {
						"Authorization": "Bearer " + token
					}
				},
				pagination: true, //是否分页
				sidePagination: 'client', //server:服务器端分页|client：前端分页
				pageSize: 5, //单页记录数
				pageList: [5, 10, 20, 50], //可选择单页记录数
				showRefresh: false, //刷新按钮
				clickToSelect: true, //是否启用点击选中行
				singleSelect: false, //开启单选,想要获取被选中的行数据必须要有该参数
				columns: [{
					checkbox: true //第一列显示复选框
				}, {
					title: '设备id',
					field: 'devId',
					sortable: true,
					visible: false
				}, {
					title: '设备名称',
					field: 'devName',
					sortable: true
				}, {
					title: '用户id',
					field: 'devUserId',
					sortable: true,
					visible: false
				}, {
					title: '用户名称',
					field: 'devUserName',
					sortable: true,
					visible: userType === "ADMIN" ? true : false
				}, {
					title: '厂商名称',
					field: 'devEnterpriseName',
					sortable: true
				}, {
					title: 'MAC地址',
					field: 'devMac',
					sortable: true
				}, {
					title: 'SN号',
					field: 'devSerialNumber',
					sortable: true
				}, {
					title: '设备协议',
					field: 'devProtocol',
					sortable: true
				}, {
					title: '安装地址',
					field: 'devAddress',
					sortable: true
				}, {
					title: '设备状态',
					field: 'devStatus',
					sortable: true,
					formatter: formatterEnum
				}, {
					title: '设备升级状态（OTA）',
					field: 'devUpgradeStatus',
					sortable: true,
					formatter: formatterEnum
				}, {
					title: '固件版本',
					field: 'devFirmwareVersion',
					sortable: true
				}, {
					title: '硬件版本',
					field: 'devHardwareVersion',
					sortable: true
				}, {
					title: 'SDK版本',
					field: 'devSdkVersion',
					sortable: true
				}, {
					title: '关联产品id',
					field: 'devPdId',
					sortable: true,
					visible: false
				}, {
					title: '关联产品',
					field: 'devPdName',
					sortable: true
				}, {
					title: '设备创建时间',
					field: 'devCreateTime',
					sortable: true
				}, {
					title: '设备修改时间',
					field: 'devUpdateTime',
					sortable: true
				}],
				onLoadSuccess: function(res) {
					$("#table").bootstrapTable("load", res.data)
				}
			});
		})
	},
	first: function(_this,token) {
		var token = sessionStorage.getItem("token");
		pageLoad("device", _this);
		// var token = localStorage.getItem("token");
		$.ajax({
			type: "get",
			url: "/wave-device/product/getProductInfo_Enterprise",
			contentType: "application/json;charset=UTF-8",
			headers: {
				"Authorization": "Bearer " + token
			},
			dataType: "json",
			success: function(result) {
				_this.productList = result.data;
			}
		});

		$.ajax({
			type: "get",
			url: "/wave-user/user/getUserAll_person",
			contentType: "application/json;charset=UTF-8",
			headers: {
				"Authorization": "Bearer " + token
			},
			dataType: "json",
			success: function(result) {
				_this.userList = result.data;
			}
		});
		
		$.ajax({
			type: "get",
			url: "/wave-enterprise/enterprise/selectEnterpriseAll",
			contentType: "application/json;charset=UTF-8",
			headers: {
				"Authorization": "Bearer " + token
			},
			dataType: "json",
			success: function(result) {
				_this.enterpriseList = result.data;
			}
		});
		
	},
	add: function(_this) {
		addTool(_this, "新增设备", "device");
		if(sessionStorage.getItem("userType") === "ADMIN"){
			_this.enterpriseDisabled = true;
			_this.userDisabled = true;
		}
	},
	update: function(_this) {
		var row = pickRow(_this);
		var isPass = updateTool(_this, row, "修改设备", "device");
		if (isPass){
			if(sessionStorage.getItem("userType") === "ADMIN"){
				_this.enterpriseDisabled = true;
				_this.userDisabled = true;
			}
			if (_this.device.devActivatedStatus) {
				_this.device.devActivatedStatus = _this.device.devActivatedStatus.value;
			}
			if (_this.device.devStatus) {
				_this.device.devStatus = _this.device.devStatus.value;
			}
			if (_this.device.devUpgradeStatus) {
				_this.device.devUpgradeStatus = _this.device.devUpgradeStatus.value;
			}
		}
	},
	requestSaveOrUpdate: function(_this,token,url){
		$.ajax({
			type: "POST",
			url: url,
			contentType: "application/json;charset=UTF-8",
			dataType: "json",
			headers: {
				"Authorization": "Bearer " + token
			},
			data: JSON.stringify(_this.device),
			success: function(result) {
				if (result.code == 20000) {
					_this.$message.success(result.message);
					$('#table').bootstrapTable('refresh');
					_this.dialogFormVisible = false;
					return;
				} else {
					_this.$message.warning(result.message);
					return;
				}
			},
			error: function(xhr, errorText, errorType) {
				if (xhr.status == 403) {
					//do something
					_this.$message.error("抱歉，您没有权限");
					return;
				}
				// _this.$message.error(xhr.responseJSON.error_description);
				// _this.$message.error(xhr.responseText);
			}
		});
	},
	saveOrUpdate: function(_this) {
		var token = sessionStorage.getItem("token");
		if (!_this.device.devName) {
			_this.$message.warning("请输入设备名称!");
			return;
		}

		// if (!_this.device.devUserId) {
		// 	_this.$message.warning("请选择设备所属用户!");
		// 	return;
		// }

		if (!_this.device.devMac) {
			_this.$message.warning("请输入设备MAC地址!");
			return;
		}

		// if (!_this.device.devPdId) {
		// 	_this.$message.warning("请选择设备所属产品!");
		// 	return;
		// }

		if (!/^\S+$/.test(_this.device.devName) || !/^\S+$/.test(_this.device.devMac)) {
			_this.$message.warning('输入不能有空格');
			return;
		}
		// for (var i = 0; i < _this.productList.length; i++) {
		//     if (_this.productVal == _this.productList[i].pdName) {
		//         _this.device.devPdId = _this.productList[i].pdId;
		//         _this.device.devPdName = _this.productList[i].pdName;
		//     }
		// }

		// for (var i = 0; i < _this.userList.length; i++) {
		//     if (_this.userVal == _this.userList[i].userName) {
		//         _this.device.devUserId = _this.userList[i].userId;
		//         _this.device.devUserName = _this.userList[i].userName;
		//     }
		// }
		
		//调用后台校验当前设备是否和所属产品是同一家企业
		// 如果不是，请用户确认是否修改
		
		var deviceVerify_enterprise;
		$.ajax({
			type: "POST",
			url: "/wave-device/device/deviceVerify_enterprise",
			contentType: "application/json;charset=UTF-8",
			dataType: "json",
			async: false,
			headers: {
				"Authorization": "Bearer " + token
			},
			data: JSON.stringify(_this.device),
			success: function(result) {
				deviceVerify_enterprise = result;
			},
			error: function(xhr, errorText, errorType) {
				if (xhr.status == 403) {
					//do something
					_this.$message.error("抱歉，您没有权限");
					return;
				}
				// _this.$message.error(xhr.responseJSON.error_description);
				// _this.$message.error(xhr.responseText);
			}
		});
		
		let url = _this.device.devId == null ? "/wave-device/device/insertDevice" :
			"/wave-device/device/updateDevice";
		if (deviceVerify_enterprise.data){
			this.requestSaveOrUpdate(_this,token,url);
		} else {
			_this.$confirm(deviceVerify_enterprise.message, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.requestSaveOrUpdate(_this,token,url);
			}).catch(() => {
				_this.$message({
					type: 'info',
					message: '已取消'
				});
			});
		}
		
		
		
		
		// let url = _this.device.devId == null ? "/wave-device/device/insertDevice" :
		// 	"/wave-device/device/updateDevice";
		// $.ajax({
		// 	type: "POST",
		// 	url: url,
		// 	contentType: "application/json;charset=UTF-8",
		// 	dataType: "json",
		// 	headers: {
		// 		"Authorization": "Bearer " + token
		// 	},
		// 	data: JSON.stringify(_this.device),
		// 	success: function(result) {
		// 		if (result.code == 20000) {
		// 			_this.$message.success(result.message);
		// 			$('#table').bootstrapTable('refresh');
		// 			_this.dialogFormVisible = false;
		// 			return;
		// 		} else {
		// 			_this.$message.warning(result.message);
		// 			return;
		// 		}
		// 	},
		// 	error: function(xhr, errorText, errorType) {
		// 		if (xhr.status == 403) {
		// 			//do something
		// 			_this.$message.error("抱歉，您没有权限");
		// 			return;
		// 		}
		// 		// _this.$message.error(xhr.responseJSON.error_description);
		// 		// _this.$message.error(xhr.responseText);
		// 	}
		// });
	},
	remove: function(_this) {
		var token = sessionStorage.getItem("token");
		//判断删除是否选中行
		var row = pickRow(_this);
		if (!row) {
			return;
		}

		_this.$confirm('此操作将永久删除该设备, 是否继续?', '提示', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'warning'
		}).then(() => {
			var ids = idsTool(row, "device");
			$.ajax({
				type: "POST",
				contentType: "application/json;charset=UTF-8",
				url: "/wave-device/device/deleteDevice",
				dataType: "json",
				data: JSON.stringify(ids),
				headers: {
					"Authorization": "Bearer " + token
				},
				success: function(result) {
					if (result.code == 20000) {
						_this.$message.success(result.message);
					} else {
						_this.$message.error(result.message);
					}
					$('#table').bootstrapTable('refresh');

				},
				error: function(xhr, errorText, errorType) {
					if (xhr.status == 403) {
						//do something
						_this.$message.error("抱歉，您没有权限");
						return;
					}
				}
			});
		}).catch(() => {
			_this.$message({
				type: 'info',
				message: '已取消删除'
			});
		});
	},
	clear: function(_this) {
		_this.searchDeviceVo.searchInfo = "";
	},
	search: function(_this) {
		// var token = localStorage.getItem("token");
		var token = sessionStorage.getItem("token");
		$.ajax({
			type: "POST",
			contentType: "application/json;charset=UTF-8",
			url: "/wave-device/device/selectDeviceLike",
			headers: {
				"Authorization": "Bearer " + token
			},
			dataType: "json",
			data: JSON.stringify(_this.searchDeviceVo),
			success: function(res) {
				if (res.code == 20000) {
					_this.$message.success(res.message);
					$('#table').bootstrapTable('load', res.data);
				} else {
					_this.$message.error(res.message);
				}
			},
			error: function(xhr, errorText, errorType) {
				if (xhr.status == 403) {
					//do something
					_this.$message.error("抱歉，您没有权限");
					return;
				}
			}
		});
	},
	reset: function(_this) {
		_this.searchDeviceVo.searchInfo = "";
		_this.searchDeviceVo.startCreateTime = "";
		_this.searchDeviceVo.endCreateTime = "";
		$('#table').bootstrapTable('refresh');
	}
}


/*
var _this = new Vue({
    el: "#app",
    data: {
        device: {},
        title: "",
        productVal: "请选择",
        productList: [],
        userVal: "请选择",
        userList: [],
        searchDeviceVo: {
            searchInfo: "",
            start: "",
            end: "",
        },
    },
    methods: {
        add: function() {
            _this.productVal = "请选择";
            _this.userVal = "请选择";
            addTool("新增设备", "device");
        },
        update: function() {
            var row = pickRow();
            var isPass = updateTool(row, "修改设备", "device");
            if (isPass) {
                _this.productVal = _this.device.devPdName == null ? "请选择" : _this.device.devPdName;
                _this.userVal = _this.device.devUserName == null ? "请选择" : _this.device.devUserName;
            }
        },
        saveOrUpdate: function() {

            if (!_this.userVal || _this.userVal === "请选择") {
                alert("请选择用户关联至您的设备!");
                return;
            }

            for (var i = 0; i < _this.productList.length; i++) {
                if (_this.productVal == _this.productList[i].pdName) {
                    _this.device.devPdId = _this.productList[i].pdId;
                    _this.device.devPdName = _this.productList[i].pdName;
                }
            }

            for (var i = 0; i < _this.userList.length; i++) {
                if (_this.userVal == _this.userList[i].userName) {
                    _this.device.devUserId = _this.userList[i].userId;
                    _this.device.devUserName = _this.userList[i].userName;
                }
            }
            var url = _this.device.devId == null ? "/wave-admin/device/insertDevice" : "/wave-admin/device/updateDevice";
            $.ajax({
                type: "POST",
                url: url,
                contentType: "application/json;charset=UTF-8",
                dataType: "json",
                data: JSON.stringify(_this.device),
                success: function(result) {
                    alert(result.message);
                    if (result.code == 200) {
                        // window.location.href = "/wave-admin/to/toUser";
                        $('#table').bootstrapTable('refresh');
                        $('.close').trigger("click");
                    }
                }
            });

        },
        remove: function() {
            //判断删除是否选中行
            var row = pickRow();
            if (!row) {
                return;
            } else {
                var ids = idsTool(row, "device");
                if (confirm("确认删除？")) {
                    console.log(JSON.stringify(row));
                    $.ajax({
                        type: "POST",
                        contentType: "application/json;charset=UTF-8",
                        url: "/wave-admin/device/deleteDevice",
                        dataType: "json",
                        data: JSON.stringify(ids),
                        success: function(result) {
                            // window.location.href = "/wave-admin/to/toUser";
                            $('#table').bootstrapTable('refresh');
                        }
                    });
                }
            }

        },
        clear: function() {
            _this.searchDeviceVo.searchInfo = "";
        },
        search: function() {
            $.ajax({
                type: "POST",
                contentType: "application/json;charset=UTF-8",
                url: "/wave-admin/device/searchDeviceInfo",
                dataType: "json",
                data: JSON.stringify(_this.searchDeviceVo),
                success: function(res) {
                    // window.location.href = "/wave-admin/to/toUser";
                    console.log(res);
                    $('#table').bootstrapTable('load', res.data);
                }
            });
        },
        reset: function() {
            _this.searchDeviceVo.searchInfo = "";
            _this.searchDeviceVo.start="";
            _this.searchDeviceVo.end="";
            $('#table').bootstrapTable('refresh');
        }
    },
    created: function() {

        pageLoad("设备管理");
        var that = this;
        $.ajax({
            type: "get",
            url: "/wave-admin/product/selectProductAll",
            contentType: "application/json;charset=UTF-8",
            dataType: "json",
            success: function(result) {
                console.log(result.data);
                that.productList = result.data;
            }
        });

        $.ajax({
            type: "get",
            url: "/wave-admin/user/selectUserAll",
            contentType: "application/json;charset=UTF-8",
            dataType: "json",
            success: function(result) {
                console.log(result.data);
                that.userList = result.data;
            }
        });
    },
    mounted: function() {}
})*/
