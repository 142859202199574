<template>
	<div><img src="../assets/404.jpg" style="width: 100%; height: 100%;"></div>
</template>

<script>
	export default{
		name:'NotFound'
	}
</script>

<style>
</style>
