import $ from 'jquery'

//表格超出宽度鼠标悬停显示td内容
function paramsMatter(value, row, index) {
	var span = document.createElement("span");
	span.setAttribute("title", value);
	span.innerHTML = value;
	return span.outerHTML;
}
//td宽度以及内容超过宽度隐藏
function formatTableUnit(value, row, index) {
	return {
		css: {
			"white-space": "nowrap",
			"text-overflow": "ellipsis",
			"overflow": "hidden",
			"max-width": "60px"
		}
	}
}

function formatterEnum(value, row, index) {
	if (value && value.alias) {
		return value.alias;
	} else {
		return "";
	}
}
//判断是否选中行
function pickRow(_this) {
	var row = $("#table").bootstrapTable('getSelections');
	if (!row || row.length == 0) {
		_this.$message.warning('请选择一条记录！');
		return false;
	} else {
		return row;
	}
}

function addTool(_this, title, data) {
	let form = "";
	if (data == "user") {
		_this.user = {};
		form = "userForm";
	} else if (data == "role") {
		_this.role = {};
		form = "roleForm";
	} else if (data == "device") {
		_this.device = {};
		form = "deviceForm";
	} else if (data == "product") {
		_this.product = {};
		form = "productForm";
	} else if (data == "productFunction") {
		_this.productFunction = {};
		form = "productFunctionForm";
	} else if (data == "enterprise") {
		_this.enterprise = {};
		form = "enterpriseForm";
	} else if (data == "ota") {
		_this.ota = {};
		form = "otaForm";
	}
	_this.title = title;
	_this.dialogFormVisible = true;
	// _this.$nextTick(function() {
	// 	//方法
	// 	_this.$refs[form].resetFields();
	// });
}

function updateTool(_this, row, title, data) {
	if (!row || row.length > 1) {
		if (row.length > 1) {
			_this.$message.warning('请选择单行进行编辑！');
		}
		// $("#update").attr("data-target", ""); //此处为点击修改不选择行或选择多行时使页面不出现修改页面
		return false;
	} else {
		if (data == "user") {
			// _this.user = row[0];
			//创建一个新的对象，指向新的内存地址
			_this.user = JSON.parse(JSON.stringify(row[0]));
		} else if (data == "role") {
			_this.role = JSON.parse(JSON.stringify(row[0]));
		} else if (data == "device") {
			_this.device = JSON.parse(JSON.stringify(row[0]));
		} else if (data == "product") {
			_this.product = JSON.parse(JSON.stringify(row[0]));
		} else if (data == "productFunction") {
			_this.productFunction = JSON.parse(JSON.stringify(row[0]));
		} else if (data == "enterprise") {
			_this.enterprise = JSON.parse(JSON.stringify(row[0]));
		} else if (data == "ota") {
			_this.ota = JSON.parse(JSON.stringify(row[0]));
		}

		_this.title = title;
		_this.dialogFormVisible = true;
		return true;
	}
}

function idsTool(row, menu) {
	var ids = [];
	for (var i = 0; i < row.length; i++) {
		if (menu == "user") {
			ids[i] = row[i].userId;
		} else if (menu == "role") {
			ids[i] = row[i].roleId;
		} else if (menu == "device") {
			ids[i] = row[i].devId;
		} else if (menu == "product") {
			ids[i] = row[i].pdId;
		} else if (menu == "productFunction") {
			ids[i] = row[i].functionId;
		} else if (menu == "enterprise") {
			ids[i] = row[i].enterpriseId;
		} else if (menu == "ota") {
			ids[i] = row[i].otaId;
		}
	}
	return ids;
}

function pageLoad(page, _this) {
	var token = sessionStorage.getItem("token");
	$.ajax({
		type: "get",
		url: "/wave-user/permissions/pageLoad/",
		headers: {
			"Authorization": "Bearer " + token
		},
		success: function(res) {
			var permissions = res.data;
			if (res.code === 20000){
				for (var i = 0; i < permissions.length; i++) {
					var permission = permissions[i].split(":");
					if (page === permission[0]){//只判定当前页面的权限
						if (_this.addIsShow !== true){
							_this.addIsShow = permission[1] === "add" ? true : false;
						}
						if (_this.updateIsShow !== true){
							_this.updateIsShow = permission[1] === "update" ? true : false;
							if (permission[0] === "role" && _this.updateIsShow === true) {//拥有修改角色权限就有分配权限的权限
								_this.permissionSaveIsShow = true;
							}
						}
						if (_this.removeIsShow !== true){
							_this.removeIsShow = permission[1] === "delete" ? true : false;
						}
						if (_this.otaIsPush !== true){
							_this.otaIsPush = permission[1] === "push" ? true : false;
						}
					}
				}
				
			}
			// if (res.data.length > 0) {
			// 	for (let i = 0; i < res.data.length; i++) {
			// 		var permissions = res.data[i];
			// 		for (let j = 0; j < permissions.length; j++) {
			// 			if (permissions[j].menuName === page) {
			// 				if (permissions[j].permissionInsert) {
			// 					_this.addIsShow = true;
			// 				}
			// 				if (permissions[j].permissionDelete) {
			// 					_this.removeIsShow = true;
			// 				}
			// 				if (permissions[j].permissionUpdate) {
			// 					_this.updateIsShow = true;
			// 					if (page === "角色管理") {
			// 						_this.permissionSaveIsShow = true;
			// 					}
			// 				}
			// 				if (!permissions[j].permissionSelect) {
			// 					window.location.href = "/toWelcome";
			// 				}
			// 			}
			// 		}
			// 	}
			// }
			
		}
	});
}
export {
	paramsMatter,
	formatTableUnit,
	formatterEnum,
	pickRow,
	addTool,
	updateTool,
	idsTool,
	pageLoad
}
