import $ from 'jquery'
import {
	formatterEnum,
	pickRow,
	addTool,
	updateTool,
	idsTool,
	pageLoad
} from './cloudTool'
export default {
	second: function(_this,token) {
		var token = sessionStorage.getItem("token");
		var userType = sessionStorage.getItem("userType");
		$(function() {
			$("#table").bootstrapTable({
				height: $(window).height()*0.7,//自定义高度
				url: "/wave-device/function/selectFunctionAll", //请求地址
				striped: true, //是否显示行间隔色
				pageNumber: 1, //初始化加载第一页
				pagination: true, //是否分页
				ajaxOptions: {
					headers: {
						"Authorization": "Bearer " + token
					}
				},
				sidePagination: 'client', //server:服务器端分页|client：前端分页
				pageSize: 5, //单页记录数
				pageList: [5, 10, 20, 50], //可选择单页记录数
				showRefresh: false, //刷新按钮
				clickToSelect: true, //是否启用点击选中行
				singleSelect: false, //开启单选,想要获取被选中的行数据必须要有该参数
				columns: [{
					checkbox: true //第一列显示复选框
				}, {
					title: '功能配置id',
					field: 'functionId',
					sortable: true,
					visible: false
				}, {
					title: '功能配置名称',
					field: 'functionName',
					sortable: true
				}, {
					title: '功能描述',
					field: 'functionDescribe',
					sortable: true
				}, {
					title: '功能类型',
					field: 'functionType',
					formatter: formatterEnum,
					sortable: true
				}, {
					title: '功能属性',
					field: 'functionProperty',
					formatter: formatterEnum,
					sortable: true
				}, {
					title: '命令',
					field: 'functionCmd',
					sortable: true
				}, {
					title: '长度',
					field: 'functionLength',
					sortable: true
				}, {
					title: '数据传输类型',
					field: 'functionDataTransferType',
					formatter: formatterEnum,
					sortable: true
				},{
					title: '数据类型',
					field: 'functionDataType',
					formatter: formatterEnum,
					sortable: true
				}, {
					title: '数据值',
					field: 'functionPortValue',
					sortable: true
				}, {
					title: '范围',
					field: 'functionRange',
					sortable: true,
					formatter: function(value , row , index){
						return "<pre style='white-space: pre-wrap;'>"+JSON.stringify(value, null, 2)+"</pre>";
					}
				}, {
					title: '范围类型',
					field: 'functionRangeType',
					formatter: formatterEnum,
					sortable: true
				}, {
					title: '功能所属企业',
					field: 'functionEnterprise',
					sortable: true,
					visible: userType === "ADMIN" ? true : false
				}, {
					title: '创建人',
					field: 'functionCreateUser',
					sortable: true
				}, {
					title: '创建人所属企业',
					field: 'functionCreateUserEnterprise',
					sortable: true,
					visible: userType === "ADMIN" ? true : false
				}, {
					title: '创建时间',
					field: 'functionCreateTime',
					sortable: true
				}, {
					title: '修改时间',
					field: 'functionUpdateTime',
					sortable: true
				}],
				onLoadSuccess: function(res) {
					$("#table").bootstrapTable("load", res.data)
				}
			});
		})
	},
	first: function(_this) {
		pageLoad("product_function", _this);
		var token = sessionStorage.getItem("token");
		$.ajax({
			type: "get",
			url: "/wave-enterprise/enterprise/selectEnterpriseAll",
			headers: {
				"Authorization": "Bearer " + token
			},
			dataType: "json",
			success: function(result) {
				_this.enterpriseList = result.data;
			},
			error: function(xhr, errorText, errorType) {
				if (xhr.status == 403) {
					//do something
					// _this.$message.error("抱歉，您没有权限");
					return;
				}
				// _this.$message.error(xhr.responseJSON.error_description);
				// _this.$message.error(xhr.responseText);
			}
		});
	},
	isAdmin: function(_this){
		var userType = sessionStorage.getItem("userType");
		if(userType !== "ADMIN"){
			_this.disabled_enterprise = false;
		} else {
			_this.disabled_enterprise = true;
		}
	},
	add: function(_this) {
		addTool(_this, "新增产品配置", "productFunction");
		_this.dynamicValidateForm.functionRanges = [{
			frName: "",
			frValue: ""
		}];
		this.isAdmin(_this);
	},
	update: function(_this) {
		this.isAdmin(_this);
		var row = pickRow(_this);
		var isPass = updateTool(_this, row, "修改产品配置", "productFunction");
		// if (isPass) {
		// 	_this.productVal = _this.productFunction.configPdName == null ? "请选择" : _this.productFunction.configPdName;
		// }
		if (_this.productFunction.functionType) {
			_this.productFunction.functionType = _this.productFunction.functionType.value;
		}
		if (_this.productFunction.functionDataType) {
			_this.productFunction.functionDataType = _this.productFunction.functionDataType.value;
		}
		if (_this.productFunction.functionDataTransferType) {
			_this.productFunction.functionDataTransferType = _this.productFunction.functionDataTransferType.value;
		}
		if (_this.productFunction.functionProperty) {
			_this.productFunction.functionProperty = _this.productFunction.functionProperty.value;
		}
		if (_this.productFunction.functionRangeType) {
			_this.productFunction.functionRangeType = _this.productFunction.functionRangeType.value;
		}
		_this.dynamicValidateForm.functionRanges = _this.productFunction.functionRange.length == 0 ? [{
			frName: "",
			frValue: ""
		}] : _this.productFunction.functionRange ;
	},

	saveOrUpdate: function(_this) {
		var token = sessionStorage.getItem("token");
		if (!_this.productFunction.functionName) {
			_this.$message.warning("请输入功能名称!");
			return;
		}

		if (!_this.productFunction.functionDescribe) {
			_this.$message.warning("请输入功能描述!");
			return;
		}

		// if (!_this.productFunction.functionCmd) {
		// 	_this.$message.warning("请输入命令!");
		// 	return;
		// }

		// if (!_this.productFunction.functionLen) {
		// 	_this.$message.warning("请输入长度!");
		// 	return;
		// }

		if (!_this.productFunction.functionPortValue) {
			_this.$message.warning("请输入数据!");
			return;
		}

		if (!/^\S+$/.test(_this.productFunction.functionName) || !/^\S+$/.test(_this.productFunction.functionPortValue)) {
			_this.$message.warning('输入不能有空格');
			return;
		}
		
		// if (/[^0-9a-fA-F]/.test(_this.productFunction.functionCmd)){
		// 	_this.$message.warning('请输入十六进制数');
		// 	return;
		// }
		// if(_this.productFunction.functionCmd.length > 10){
		// 	_this.$message.warning('cmd值在10位以内');
		// 	return;
		// }
		// if(_this.productFunction.functionCmd > 2147483647){
		// 	_this.$message.warning('cmd值超出，请重新输入');
		// 	return;
		// }
		// if (_this.productFunction.functionType === "BYTE" || _this.productFunction.functionType === "WORD" || _this.productFunction.functionType === "DWORD" || _this.productFunction.functionType === "FLOAT" || _this.productFunction.functionType === "DOUBLE"){
		// 	if ((!_this.productFunction.functionRangeMin && _this.productFunction.functionRangeMin !=0) || (!_this.productFunction.functionRangeMax && _this.productFunction.functionRangeMax !=0)) {
		// 		_this.$message.warning("请输入范围!");
		// 		return;
		// 	} else if (_this.productFunction.functionRangeMin > _this.productFunction.functionRangeMax) {
		// 		_this.$message.warning("范围起始值应小于或等于终值!");
		// 		return;
		// 	}
		// 	if (_this.productFunction.functionType === "BYTE" || _this.productFunction.functionType === "WORD" || _this.productFunction.functionType === "DWORD"){
		// 		if (String(_this.productFunction.functionRangeMin).indexOf(".")!=-1 || String(_this.productFunction.functionRangeMax).indexOf(".")!=-1) {
		// 			_this.$message.warning("数据类型为整形，范围请输入整数!");
		// 			return;
		// 		}	
		// 	}
		// } else {
		// 	_this.productFunction.functionRangeMin = "";
		// 	_this.productFunction.functionRangeMax = "";
		// }
			
		
		// if(_this.productFunction.functionLen.length > 10){
		// 	_this.$message.warning('长度值在10位以内');
		// 	return;
		// }
		// if(_this.productFunction.functionLen > 2147483647){
		// 	_this.$message.warning('长度值超出，请重新输入');
		// 	return;
		// }
		if(_this.dynamicValidateForm.functionRanges.length < 2){
			_this.$message.warning('范围值不符合定义，请添加至少两个');
			return;
		}
		_this.productFunction.functionCmd = null;
		
		_this.productFunction.functionRange = _this.dynamicValidateForm.functionRanges.length < 2 ? _this.productFunction.functionRange : _this.dynamicValidateForm.functionRanges;

		_this.productFunction.functionPortId = _this.productFunction.functionPortId == "" ? null : _this.productFunction.functionPortId;

		_this.productFunction.functionType = _this.productFunction.functionType == "" ? null : _this.productFunction.functionType;
		
		_this.productFunction.functionDataType = _this.productFunction.functionDataType == "" ? null : _this.productFunction.functionDataType;
		
		_this.productFunction.functionProperty = _this.productFunction.functionProperty == "" ? null : _this.productFunction.functionProperty;
		
		_this.productFunction.functionDataTransferType = _this.productFunction.functionDataTransferType == "" ? null : _this.productFunction.functionDataTransferType;
		
		_this.productFunction.functionRangeType = _this.productFunction.functionRangeType == "" ? null : _this.productFunction.functionRangeType;
		var url = _this.productFunction.functionId == null ?
			"/wave-device/function/insertFunction" :
			"/wave-device/function/updateFunction";
		$.ajax({
			type: "POST",
			url: url,
			contentType: "application/json;charset=UTF-8",
			dataType: "json",
			headers: {
				"Authorization": "Bearer " + token
			},
			data: JSON.stringify(_this.productFunction),
			success: function(result) {
				if (result.code == 20000) {
					_this.$message.success(result.message);
					$('#table').bootstrapTable('refresh');
					_this.dialogFormVisible = false;
				} else {
					_this.$message.warning(result.message);
				}
			},
			error: function(xhr, errorText, errorType) {
				if (xhr.status == 403) {
					//do something
					_this.$message.error("抱歉，您没有权限");
					return;
				}
				// _this.$message.error(xhr.responseJSON.error_description);
				// _this.$message.error(xhr.responseText);
			}
		});
	},
	remove: function(_this) {
		var token = sessionStorage.getItem("token");
		//判断删除是否选中行
		var row = pickRow(_this);
		if (!row) {
			return;
		}
				
		_this.$confirm('此操作将永久删除该设备, 是否继续?', '提示', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'warning'
		}).then(() => {
			var ids = idsTool(row, "productFunction");
			$.ajax({
				type: "POST",
				contentType: "application/json;charset=UTF-8",
				url: "/wave-device/function/deleteFunction",
				dataType: "json",
				data: JSON.stringify(ids),
				headers: {
					"Authorization": "Bearer " + token
				},
				success: function(result) {
					if (result.code == 20000) {
						_this.$message.success(result.message);
					} else {
						_this.$message.error(result.message);
					}
					$('#table').bootstrapTable('refresh');
		
				},
				error: function(xhr, errorText, errorType) {
					if (xhr.status == 403) {
						//do something
						_this.$message.error("抱歉，您没有权限");
						return;
					}
				}
			});
		}).catch(() => {
			_this.$message({
				type: 'info',
				message: '已取消删除'
			});
		});
	},
	clear: function(_this) {
		_this.searchProductFunctionVo.searchInfo = "";
	},
	search: function(_this) {
		// var token = localStorage.getItem("token");
		var token = sessionStorage.getItem("token");
		// $.ajax({
		// 	type: "POST",
		// 	contentType: "application/json;charset=UTF-8",
		// 	url: "/wave-device/function/selectproductFunctionLike",
		// 	dataType: "json",
		// 	headers: {
		// 		"Authorization": "Bearer " + token
		// 	},
		// 	data: JSON.stringify(_this.searchProductFunctionVo),
		// 	success: function(res) {
		// 		// window.location.href = "/wave-admin/to/toUser";
		// 		$('#table').bootstrapTable('load', res.data);
		// 	}
		// });
		$.ajax({
			type: "POST",
			contentType: "application/json;charset=UTF-8",
			url: "/wave-device/function/getFunctionByFilter",
			dataType: "json",
			headers: {
				"Authorization": "Bearer " + token
			},
			data: JSON.stringify(_this.searchProductFunctionVo),
			success: function(res) {
				$('#table').bootstrapTable('load', res.data);
			}
		});
	},
	reset: function(_this) {
		_this.searchProductFunctionVo.searchInfo = "";
		_this.searchProductFunctionVo.startCreateTime = "";
		_this.searchProductFunctionVo.endCreateTime = "";
		$('#table').bootstrapTable('refresh');
	}
}
