import Vue from 'vue'
import VueRouter from 'vue-router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './plugins/axios'
import App from './App'
import router from './router'
import store from './store'
import Vuex from 'vuex'

//使用
Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(ElementUI)


import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-table'
import 'bootstrap-table/dist/bootstrap-table.min.css'
import 'popper.js'
import 'font-awesome/css/font-awesome.min.css'


Vue.config.productionTip = false

// 路由跳转之前
router.beforeEach((to, from, next) => {
	if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
		if (sessionStorage.getItem('token')) { // 判断本地是否存在access_token
			next()
		} else {
			// 未登录,跳转到登陆页面，并且带上 将要去的地址，方便登陆后跳转。
			next({
				path: '/'
			})
		}
		// 注销
	} else if (to.path === '/logout') {
		// 清空
		sessionStorage.clear();
		// 跳转至登录页面
		next({
			path: '/'
		});
	} else {
		next()
	}

})

// const vm = new Vue({
// 	router,
// 	store,
// 	render: function(h) {
// 		return h(App)
// 	}
// }).$mount('#app')
new Vue({
	el: '#app',
	router,
	store,
	render: h => h(App)
})
