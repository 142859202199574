import $ from 'jquery'
import {
	formatterEnum,
	pickRow,
	addTool,
	updateTool,
	idsTool,
	pageLoad
} from './cloudTool'
var token = sessionStorage.getItem("token");
export default {
	second: function(_this, token) {
		var token = sessionStorage.getItem("token");
		$(function() {
			$("#table").bootstrapTable({
				height: $(window).height()*0.6,//自定义高度
				url: "/wave-device/ota/getOtaInfo", //请求地址
				striped: true, //是否显示行间隔色
				pageNumber: 1, //初始化加载第一页
				ajaxOptions: {
					headers: {
						"Authorization": "Bearer " + token
					}
				},
				pagination: true, //是否分页
				sidePagination: 'client', //server:服务器端分页|client：前端分页
				pageSize: 5, //单页记录数
				pageList: [5, 10, 20, 50], //可选择单页记录数
				showRefresh: false, //刷新按钮
				clickToSelect: true, //是否启用点击选中行
				singleSelect: false, //开启单选,想要获取被选中的行数据必须要有该参数
				columns: [{
					checkbox: true //第一列显示复选框
				}, {
					title: 'otaid',
					field: 'otaId',
					sortable: true,
					visible: false
				}, {
					title: 'otaProductId',
					field: 'otaProductId',
					sortable: true,
					visible: false
				}, {
					title: '产品名称',
					field: 'otaProductName',
					sortable: true,
					align: 'center',
					formatter: function(value, row, index) {
						//row数据里面判断当前行固件类型不为配置固件添加链接
						if(row.otaFirmwareType != "CONFIG" && value != null){
							return '<a href="#" class="productClick">' + value + '</a>';
						}
					},
					events: {
						"click .productClick": productClick
					}
				}, {
					title: 'OTA类型',
					field: 'otaType',
					sortable: true,
					formatter: formatterEnum
				}, {
					title: '固件类型',
					field: 'otaFirmwareType',
					sortable: true,
					formatter: formatterEnum
				}, {
					title: '版本变更记录',
					field: 'otaVersionChange',
					sortable: true
				}, {
					title: '版本名称',
					field: 'otaVersionName',
					sortable: true
				}, {
					title: '链接',
					field: 'otaUrl',
					sortable: true,
				}, {
					title: 'CRC值',
					field: 'otaCrc',
					sortable: true,
				}, {
					title: '硬件版本',
					field: 'otaHardwareVersion',
					sortable: true
				}, {
					title: '当前使用固件版本',
					field: 'otaCurrentFirmwareVersion',
					sortable: true
				}, {
					title: 'ota状态',
					field: 'otaStatus',
					sortable: true,
					formatter: formatterEnum
				}, {
					title: '固件上传时间',
					field: 'otaCreateTime',
					sortable: true
				}],
				onLoadSuccess: function(res) {
					$("#table").bootstrapTable("load", res.data)
				}
			});
		});

		function productClick(event, value, row, index) {
			//调用父组件的方法，fatherEvent--是在父组件的的动态组件component上面的方法
			_this.$emit("fatherEvent", row)
		}
	},
	first: function(_this, token) {
		var token = sessionStorage.getItem("token");
		pageLoad("ota", _this);
		// var token = localStorage.getItem("token");
		$.ajax({
			type: "get",
			url: "/wave-device/product/getProductInfo_Enterprise",
			contentType: "application/json;charset=UTF-8",
			headers: {
				"Authorization": "Bearer " + token
			},
			dataType: "json",
			success: function(result) {
				_this.productList = result.data;
			}
		});
	},
	add: function(_this) {
		addTool(_this, "新增固件", "ota");
	},
	update: function(_this) {
		var row = pickRow(_this);
		var isPass = updateTool(_this, row, "修改OTA", "ota");
		if (_this.ota.otaType) {
			_this.ota.otaType = _this.ota.otaType.value;
		}
		if (_this.ota.otaStatus) {
			_this.ota.otaStatus = _this.ota.otaStatus.value;
		}
		if (_this.ota.otaFirmwareType) {
			_this.ota.otaFirmwareType = _this.ota.otaFirmwareType.value;
		}
	},
	saveOrUpdate: function(_this) {
		if (!_this.ota.otaCrc) {
			_this.$message.warning("请输入CRC校验值!");
			return;
		}
		if (!_this.ota.otaProductId) {
			_this.$message.warning("请选择产品!");
			return;
		}
		if (!_this.ota.otaType) {
			_this.$message.warning("请选择OTA类型!");
			return;
		}
		if (!_this.ota.otaStatus) {
			_this.$message.warning("请选择状态!");
			return;
		}
		if (!_this.ota.otaFirmwareType) {
			_this.$message.warning("请选择固件类型!");
			return;
		}
		var url = _this.ota.otaId == null ? "/wave-device/ota/addOtaInfo" :
			"/wave-device/ota/updateOtaInfo";
		var token = sessionStorage.getItem("token");
		$.ajax({
			type: "POST",
			url: url,
			contentType: "application/json;charset=UTF-8",
			dataType: "json",
			headers: {
				"Authorization": "Bearer " + token
			},
			data: JSON.stringify(_this.ota),
			success: function(result) {
				if (result.code == 20000) {
					_this.$confirm('是否需要上传文件?', '提示', {
						confirmButtonText: '是',
						cancelButtonText: '否',
						type: 'warning'
					}).then(() => {
						_this.ota = result.data;
						_this.dialogFormVisible = false;
						_this.dialogFormVisibleUpload = true;
					}).catch(() => {
						_this.dialogFormVisible = false;
						_this.$message({
							type: 'info',
							message: '取消上传'
						});
						$('#table').bootstrapTable('refresh');
					});
					// _this.$message.success(result.message);
				} else {
					_this.$message.warning(result.message);
					result;
				}
			},
			error: function(xhr, errorText, errorType) {
				if (xhr.status == 403) {
					//do something
					_this.$message.error("抱歉，您没有权限");
					return;
				}
				// _this.$message.error(xhr.responseJSON.error_description);
				// _this.$message.error(xhr.responseText);
			}
		});
	},
	remove: function(_this) {
		var token = sessionStorage.getItem("token");
		//判断删除是否选中行
		var row = pickRow(_this);
		if (!row) {
			return;
		}

		_this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'warning'
		}).then(() => {
			var ids = idsTool(row, "ota");
			$.ajax({
				type: "DELETE",
				contentType: "application/json;charset=UTF-8",
				url: "/wave-device/ota/deleteOtaInfo",
				dataType: "json",
				data: JSON.stringify(ids),
				headers: {
					"Authorization": "Bearer " + token
				},
				success: function(result) {
					if (result.code == 20000) {
						_this.$message.success(result.message);
					} else {
						_this.$message.error(result.message);
					}
					$('#table').bootstrapTable('refresh');

				},
				error: function(xhr, errorText, errorType) {
					if (xhr.status == 403) {
						//do something
						_this.$message.error("抱歉，您没有权限");
						return;
					}
				}
			});
		}).catch(() => {
			_this.$message({
				type: 'info',
				message: '已取消删除'
			});
		});
	},
	uploadSuccess: function(_this, response, file, fileList) {
		var token = sessionStorage.getItem("token");
		if (response.code == 20001){
			_this.fileList = [];
			_this.$message.error(response.message);
			return;
		}
		_this.ota.otaUrl = response.data;
		$.ajax({
			type: "POST",
			url: "/wave-device/ota/updateOtaInfo",
			contentType: "application/json;charset=UTF-8",
			dataType: "json",
			headers: {
				"Authorization": "Bearer " + token
			},
			data: JSON.stringify(_this.ota),
			success: function(result) {
				if (result.code == 20000) {
					$('#table').bootstrapTable('refresh');
					// _this.$message.success(result.message);
					_this.dialogFormVisibleUpload = false;
					_this.$message.success("上传成功！");
					return;
				} else {
					_this.fileList = [];
					_this.$message.error("上传失败");
					result;
				}
			},
			error: function(xhr, errorText, errorType) {
				if (xhr.status == 403) {
					//do something
					_this.$message.error("抱歉，您没有权限");
					return;
				}
				// _this.$message.error(xhr.responseJSON.error_description);
				// _this.$message.error(xhr.responseText);
			},
		});
	},
	clear: function(_this) {
		_this.searchOtaVo.searchInfo = "";
	},
	search: function(_this) {
		// var token = localStorage.getItem("token");
		// $.ajax({
		// 	type: "POST",
		// 	contentType: "application/json;charset=UTF-8",
		// 	url: "/wave-device/ota/",
		// 	headers: {
		// 		"Authorization": "Bearer " + token
		// 	},
		// 	dataType: "json",
		// 	data: JSON.stringify(_this.searchOtaVo),
		// 	success: function(res) {
		// 		if (res.code == 20000) {
		// 			_this.$message.success(res.message);
		// 			$('#table').bootstrapTable('load', res.data);
		// 		} else {
		// 			_this.$message.error(res.message);
		// 		}
		// 	},
		// 	error: function(xhr, errorText, errorType) {
		// 		if (xhr.status == 403) {
		// 			//do something
		// 			_this.$message.error("抱歉，您没有权限");
		// 			return;
		// 		}
		// 	}
		// });
	},
	reset: function(_this) {
		_this.searchOtaVo.searchInfo = "";
		_this.searchOtaVo.startCreateTime = "";
		_this.searchOtaVo.endCreateTime = "";
		$('#table').bootstrapTable('refresh');
	}
}
