import $ from 'jquery'

export default {
	register: function(that) {

		if (!that.registerForm.username) {
			that.$message.error("请输入用户名");
			return;
		}
		if (!/^[a-zA-Z0-9_-]{4,20}$/.test(that.registerForm.username)) { //4到20位（字母，数字，下划线，减号）
			that.$message.error("用户名应为4到20位（字母，数字，下划线，减号）构成");
			return;
		}
		if (!that.registerForm.password) {
			that.$message.error("请输入密码");
			return;
		}
		if (!that.registerForm.checkPass) {
			that.$message.error("请确认密码");
			return;
		}

		if (!/^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9._~!?@#$^&*+-]{6,20}$/.test(that.registerForm.password) || !/^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9._~!?@#$^&*+-]{6,20}$/.test(
				that.registerForm.checkPass)) {
			that.$message.error("密码只能输入6-20个字母、数字、下划线等特殊符号");
			return;
		} else if (!/^[^\u4e00-\u9fa5]+$/.test(that.registerForm.password)) {
			that.$message.error("密码不能为中文");
			return;
		} else if (!/^\S+$/.test(that.registerForm.password)) {
			that.$message.error('密码不能有空格');
			return;
		}
		if (that.registerForm.password != that.registerForm.checkPass) {
			that.$message.error("两次输入密码不相等，请重新输入");
			return;
		}

		if (!that.registerForm.phone) {
			that.$message.error("请输入手机号码");
			return;
		}

		if (/^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[8-9])[0-9]{8}$/.test(that.registerForm.phone) == false) {
			that.$message.error("手机号格式错误");
			return;
		}

		if (!that.registerForm.email) {
			that.$message.error("请输入邮箱");
			return;
		}

		if (/^\w{1,64}@[a-z0-9\-]{1,256}(\.[a-z]{2,6}){1,2}$/i.test(that.registerForm.email) == false) {
			that.$message.error("邮箱格式错误");
			return;
		}

		var insertUser = {};
		insertUser.userName = that.registerForm.username;
		insertUser.userPassword = that.registerForm.password;
		insertUser.userPhone = that.registerForm.phone;
		insertUser.userEmail = that.registerForm.email;
		insertUser.userGender = that.registerForm.gender == "男" ? "MAN" : that.registerForm.gender == "女" ? "WOMAN" : null;
		// console.log(insertUser);
		$.ajax({
			type: "POST",
			url: "/wave-user/user/register",
			contentType: "application/json;charset=UTF-8",
			dataType: "json",
			data: JSON.stringify(insertUser),
			success: function(result, status, xhr) {
				if (result.code == 20000) {
					that.$message.success(result.message);
					// var localstorage = window.localStorage;
					// localStorage.setItem('userName', insertUser.userName);
					that.$router.push("/");
					// window.location.href = "/toIndex";
				} else {
					that.$message.error(result.message);
				}
			}
		});

	}
}
/*var vm = new Vue({
    el: "#app",
    data: {
        types: ""
    },
    methods: {
        submit: function() {
            console.log($("#insertUserPhone").val());
            console.log($("#insertUserPhone").val().length);
            if ($("#insertUserPhone").val().length != 11) {
                alert("请输入正确的手机号码");
            } else {
                var insertUser = {};
                insertUser.userName = $("#insertUserName").val();
                insertUser.userPassword = $("#insertUserPassword").val();
                insertUser.userPhone = $("#insertUserPhone").val();
                // insertUser.userGender = $("#insertUserGender").val();
                insertUser.userEmail = $("#insertUserEmail").val();
                insertUser.userGender = $("input[name='gender']:checked").val();
                console.log(insertUser);
                $.ajax({
                    type: "POST",
                    url: "/wave-admin/user/register",
                    contentType: "application/json;charset=UTF-8",
                    dataType: "json",
                    data: JSON.stringify(insertUser),
                    success: function(result) {
                        alert(result.message);
                        if (result.code == 200) {
                            if (window.localStorage) {
                                // var localstorage = window.localStorage;
                                localStorage.setItem('userName', insertUser.userName);
                            }
                            window.location.href = "/wave-admin/toLogin";
                        }
                    }
                });
            }
        }
    },
    created: function() {},
    mounted: function() {}
})*/
