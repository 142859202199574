import $ from 'jquery'
import {
	formatterEnum,
	pickRow,
	addTool,
	updateTool,
	idsTool,
	pageLoad
} from './cloudTool'
var token = sessionStorage.getItem("token");
var productInfo = {};
export default {
	pageLoad: function(_this, token) {
		var token = sessionStorage.getItem("token");
		_this.isloading = true;
		$.ajax({
			type: "get",
			url: "/wave-device/device/getDeviceByproductId?productId=" + _this.otaRowInfo.otaProductId, //请求地址
			contentType: "application/json;charset=UTF-8",
			headers: {
				"Authorization": "Bearer " + token
			},
			dataType: "json",
			success: function(result) {
				// _this.tableData = result.data;
				let data = result.data;
				let merge = ['pdName', 'pdType', 'pdFunctionName', 'pdCreateTime', 'pdUpdateTime'];

				if (!merge || merge.length === 0) {
					return data
				}
				merge.forEach((m) => {
					const mList = {}
					data = data.map((v, index) => {
						const rowVal = v[m]
						if (mList[rowVal] && mList[rowVal].newIndex === index) {
							mList[rowVal]['num']++
							mList[rowVal]['newIndex']++
							data[mList[rowVal]['index']][m + '-span'].rowspan++
							v[m + '-span'] = {
								rowspan: 0,
								colspan: 0
							}
						} else {
							mList[rowVal] = {
								num: 1,
								index: index,
								newIndex: index + 1
							}
							v[m + '-span'] = {
								rowspan: 1,
								colspan: 1
							}
						}
						return v;
					})
				})
				_this.tableData = data;
				_this.isloading = false;
			}
		});
		
		_this.isPushLogloading = true;
		$.ajax({
			type: "get",
			url: "/wave-device/ota/getOtaPushLogInfo?productId=" + _this.otaRowInfo.otaProductId, //请求地址
			contentType: "application/json;charset=UTF-8",
			headers: {
				"Authorization": "Bearer " + token
			},
			dataType: "json",
			success: function(result) {
				// _this.tableData = result.data;
				let data = result.data;
				let merge = ['opDeviceMac', 'opCreateTime'];
		
				if (!merge || merge.length === 0) {
					return data
				}
				merge.forEach((m) => {
					const mList = {}
					data = data.map((v, index) => {
						const rowVal = v[m]
						if (mList[rowVal] && mList[rowVal].newIndex === index) {
							mList[rowVal]['num']++
							mList[rowVal]['newIndex']++
							data[mList[rowVal]['index']][m + '-span'].rowspan++
							v[m + '-span'] = {
								rowspan: 0,
								colspan: 0
							}
						} else {
							mList[rowVal] = {
								num: 1,
								index: index,
								newIndex: index + 1
							}
							v[m + '-span'] = {
								rowspan: 1,
								colspan: 1
							}
						}
						return v;
					})
				})
				_this.tablePushLogData = data;
				_this.isPushLogloading = false;
			}
		});
		pageLoad("ota", _this);
	},
	otaPush: function(_this) {
		let currentSelect = _this.$refs.tableDom.selection;
		if (currentSelect.length === 0) {
			_this.$message.warning('请选择需要推送的设备！');
			return;
		}

		var deviceInfo = new Array();
		currentSelect.forEach(function(value, key, iterable) {
			let data = {};
			data.deviceId = value.devId;
			data.deviceMac = value.devMac;
			data.deviceUpgradeStatus = value.devUpgradeStatus.value;
			deviceInfo.push(data);
		});

		let data = {};
		// _this.otaRowInfo.otaType = _this.otaRowInfo.otaType != null ? _this.otaRowInfo.otaType.value : _this.otaRowInfo.otaType;
		// _this.otaRowInfo.otaStatus = _this.otaRowInfo.otaStatus != null ? _this.otaRowInfo.otaStatus.value : _this.otaRowInfo.otaStatus;
		data.otaInfo = _this.otaRowInfo;
		data.otaPDInfo = deviceInfo;
		

		var token = sessionStorage.getItem("token");
		$.ajax({
			type: "POST",
			contentType: "application/json;charset=UTF-8",
			url: "/wave-device/ota/pushOtaInfo",
			dataType: "json",
			headers: {
				"Authorization": "Bearer " + token
			},
			data: JSON.stringify(data),
			success: function(res) {
				if (res.code == 20000) {
					_this.$message.success('推送成功！');
					_this.isPushLogloading = true;
					$.ajax({
						type: "get",
						url: "/wave-device/ota/getOtaPushLogInfo?productId=" + _this.otaRowInfo.otaProductId, //请求地址
						contentType: "application/json;charset=UTF-8",
						headers: {
							"Authorization": "Bearer " + token
						},
						dataType: "json",
						success: function(result) {
							// _this.tableData = result.data;
							let data = result.data;
							let merge = ['opDeviceMac', 'opCreateTime'];
					
							if (!merge || merge.length === 0) {
								return data
							}
							merge.forEach((m) => {
								const mList = {}
								data = data.map((v, index) => {
									const rowVal = v[m]
									if (mList[rowVal] && mList[rowVal].newIndex === index) {
										mList[rowVal]['num']++
										mList[rowVal]['newIndex']++
										data[mList[rowVal]['index']][m + '-span'].rowspan++
										v[m + '-span'] = {
											rowspan: 0,
											colspan: 0
										}
									} else {
										mList[rowVal] = {
											num: 1,
											index: index,
											newIndex: index + 1
										}
										v[m + '-span'] = {
											rowspan: 1,
											colspan: 1
										}
									}
									return v;
								})
							})
							_this.tablePushLogData = data;
							_this.isPushLogloading = false;
						}
					});
					return;
				} else {
					_this.$message.error(res.message);
					return;
				}
			},
			error: function(xhr, errorText, errorType) {
				if (xhr.status == 403) {
					_this.$message.error("抱歉，您没有权限");
					return;
				}
			}
		});
	},

	search: function(_this) {
		console.log(_this.searchOtaProduct.time);
		this.second(_this, token);
		// var token = localStorage.getItem("token");
		// $.ajax({
		// 	type: "POST",
		// 	contentType: "application/json;charset=UTF-8",
		// 	url: "/wave-device/product/selectProductLike",
		// 	dataType: "json",
		// 	headers: {
		// 		"Authorization": "Bearer " + token
		// 	},
		// 	data: JSON.stringify(_this.searchProductVo),
		// 	success: function(res) {
		// 		if (res.code == 20000) {
		// 		} else {
		// 			_this.$message.error(res.message);
		// 			return;
		// 		}
		// 	},
		// 	error: function(xhr, errorText, errorType) {
		// 		if (xhr.status == 403) {
		// 			//do something
		// 			_this.$message.error("抱歉，您没有权限");
		// 			return;
		// 		}
		// 		// _this.$message.error(xhr.responseJSON.error_description);
		// 		// _this.$message.error(xhr.responseText);
		// 	}
		// });


	},

	reset: function(_this) {
		_this.searchOtaProduct.searchInfo = "";
		_this.searchOtaProduct.time = "";
	}
}
