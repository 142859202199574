<template>
	<div id="app" class="container-fluid">
		<div class="header row">
			<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 top"><span class="badge badge-primary">OTA管理</span></div>
			<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 top"></div>
			<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 top"></div>
			<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 top"></div>
		</div>
		<div class="main">
			<el-tabs v-model="editableTabsValue" type="card" @tab-remove="removeTab">
				<!-- <el-tab-pane label="OTA信息" name="1"> -->
				<el-tab-pane :key="item.name" v-for="(item, index) in editableTabs" :label="item.title" :name="item.name" :closable="item.closable">
					<component :is='item.content' :otaRowInfo='otaRowInfo' @fatherEvent="btnclick"></component>
				</el-tab-pane>
			</el-tabs>
		</div>

		<div class="footer row">
			<div class="col-md-4"></div>
			<div class="col-md-4"></div>
			<div class="col-md-4"></div>
		</div>
	</div>
</template>

<script>
	// import ota from '../js/ota'
	import OTAMain from '../views/OTAMain'
	import OTAProductInfo from '../views/OTAProductInfo'
	export default {
		name: "ota",
		data() {
			return {
				editableTabsValue: '1',
				editableTabs: [{
					title: 'OTA信息',
					name: '1',
					closable: false,
					content: OTAMain
				}],
				tabIndex: 1,
				//动态子组件
				otaRowInfo:{},

				productList: [],
				title: "",
				dialogFormVisible: false,
			}
		},
		// created() {
		// 	var token = sessionStorage.getItem("token");
		// 	ota.first(this, token);
		// 	ota.second(this, token);
		// },
		methods: {
			addTab(targetName, row) { //----------------这是添加tabs页的方法，三个值对应着上面点击方法传过来的，但是也不是三个都要，第一个值是必须要传的
				let newTabName = ++this.tabIndex + '';
				this.otaRowInfo = row;
				this.otaRowInfo.otaType = this.otaRowInfo.otaType != null ? this.otaRowInfo.otaType.value : this.otaRowInfo.otaType;
				this.otaRowInfo.otaStatus = row.otaStatus != null ? this.otaRowInfo.otaStatus.value : this.otaRowInfo.otaStatus;
				this.otaRowInfo.otaFirmwareType = row.otaFirmwareType != null ? this.otaRowInfo.otaFirmwareType.value : this.otaRowInfo.otaFirmwareType;
				this.editableTabs.push({
					title: row.otaProductName,
					name: newTabName,
					closable: true,
					content: OTAProductInfo
				});
				this.editableTabsValue = newTabName;
			},
			btnclick(row) { //这个子组件点击控制父组件的方法，不同的子组件对应不同的调用
				let value = this.editableTabsValue;
				this.addTab(value, row);
			},
			// delTabs(targetName) { //---------------这是子组件想要关闭当前tab页的，控制父组件的操作
			// 	let tabs = this.editableTabs;
			// 	let activeName;
			// 	tabs.forEach((tab, index) => {
			// 		if (tab.title === targetName) { //因为判断不一样
			// 			let nextTab = tabs[index + 1] || tabs[index - 1];
			// 			if (nextTab) {
			// 				activeName = nextTab.name;
			// 			}
			// 		}
			// 	});
			// 	this.editableTabsValue = activeName;
			// 	this.editableTabs = tabs.filter(tab => tab.title !== targetName);
			// 	this.manageNode();
			// },
			removeTab(targetName) {
				let tabs = this.editableTabs;
				let activeName = this.editableTabsValue;
				if (activeName === targetName) {
					tabs.forEach((tab, index) => {
						if (tab.name === targetName) {
							let nextTab = tabs[index + 1] || tabs[index - 1];
							if (nextTab) {
								activeName = nextTab.name;
							}
						}
					});
				}
				this.editableTabsValue = activeName;
				this.editableTabs = tabs.filter(tab => tab.name !== targetName);
			},
		}
	}
</script>

<style scoped src="../css/ota.css">

</style>
