<template>
	<div>
		<!-- 快捷导航模块 start -->
		<div class="shortcut">
			<div class="fl shortcutl">
				<a href="#" id="menuName" class="menuName"><i class="fa fa-bars" aria-hidden="true"></i><span>菜单 &nbsp;</span></a>
				<h1 class="logo"><a href="/index" style="font-size:0">云世<img src="../assets/yunshi.png"></a></h1>
			</div>
			<div class="nav fr">
				<div class="search">
					<div class=" input-group input-group-sm mb-3">
						<!-- <input type="text" class="form-control" placeholder="请输入查询菜单" aria-label="请输入查询菜单" aria-describedby="button-addon2"
						 v-model="menu">
						<div class="input-group-append">
							<button class="btn btn-outline-secondary" type="button" id="button-addon2" @click="searchInfo"><i class="fa fa-search"
								 aria-hidden="true"></i></button>
						</div> -->
					</div>
				</div>

				<div class="btn-group">
					<button id="userName" class="btn btn-sm dropdown-toggle  btn-outline-secondary" type="button" data-toggle="dropdown"
					 aria-haspopup="true" aria-expanded="false">
						用户名
					</button>

					<div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
						<a class="dropdown-item" href="/toPersonal" target="rightContent">个人中心</a>
						<a class="dropdown-item" @click="logout()">注销</a>
					</div>
				</div>
			</div>
		</div>
		<!-- 快捷导航模块 end -->

		<!-- 主体 -->
		<div class="main">
			<!-- 菜单 -->
			<div id="menu" class="menu">
				<div class="menuList">
					<div class="menuParent">
						<a href="#"><i class="fa fa-user" aria-hidden="true"></i>用户服务
							<svg id="system" width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-down rotate" fill="currentColor"
							 xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
							</svg>
						</a>
					</div>
					<ul class="system" style="font-size: 18px;">
						<div id="user" v-show="userIsShow">
							<!-- <a href="/toUser" target="rightContent">
								<li>用户管理</li>
							</a> -->
							<router-link :to="{name: 'user'}">
								<li>用户管理</li>
							</router-link>
						</div>

						<div id="role" v-show="roleIsShow">
							<!-- <a href="/toRole" target="rightContent">
								<li>角色管理</li>
							</a> -->
							<router-link :to="{name: 'role'}">
								<li>角色管理</li>
							</router-link>
						</div>
					</ul>
					<div class="menuParent">
						<a href="#"><i class="fa fa-television" aria-hidden="true"></i>设备服务
							<svg id="device" width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-down rotate" fill="currentColor"
							 xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
							</svg>
						</a>
					</div>
					<ul class="equipment">
						<div id="product" v-show="productIsShow">
							<!-- <a href="/toProduct" target="rightContent">
								<li>
									产品管理
								</li>
							</a> -->
							<router-link :to="{name: 'product'}">
								<li>产品管理</li>
							</router-link>
						</div>
						<div id="productFunction" v-show="productFunctionIsShow">
							<!-- <a href="/toProductConfig" target="rightContent">
								<li>
									产品配置
								</li>
							</a> -->
							<router-link :to="{name: 'productFunction'}">
								<li>产品功能配置</li>
							</router-link>
						</div>
						<div id="deviceManagement" v-show="deviceManagementIsShow">
							<!-- <a href="/toDevice" target="rightContent">
								<li>
									设备管理
								</li>
							</a> -->
							<router-link :to="{name: 'device'}">
								<li>设备管理</li>
							</router-link>
						</div>
						<div id="deviceLog" v-show="deviceLogIsShow">
							<router-link :to="{name: 'deviceLog'}">
								<li>设备日志</li>
							</router-link>
						</div>
						<div id="ota" v-show="otaIsShow">
							<router-link :to="{name: 'ota'}">
								<li>OTA</li>
							</router-link>
						</div>
						<!--<div>
                            <a href="#">
                                <li>群组</li>
                            </a>
                        </div>
                        <div>
                            <a href="#">
                                <li>软固件升级</li>
                            </a>
                        </div>
                        <div>
                            <a href="#">
                                <li>设备CA证书</li>
                            </a>
                        </div>-->
					</ul>
					<div class="menuParent">
						<a href="#"><i class="fa fa-university" aria-hidden="true"></i>企业服务
							<svg id="enterprise" width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-down rotate" fill="currentColor"
							 xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
							</svg>
						</a>
					</div>
					<ul class="enterprise">
						<div id="enterpriseManagement" v-show="enterpriseManagementIsShow">
							<router-link :to="{name: 'enterprise'}">
								<li>企业管理</li>
							</router-link>
						</div>
					</ul>

					<div class="menuParent" v-show="apiIsShow">
						<a href="#"><i class="fa fa-exchange" aria-hidden="true"></i>API管理
							<svg id="api" width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-down rotate" fill="currentColor"
							 xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
							</svg>

						</a>
					</div>
					<ul class="api" v-show="apiIsShow">
						<div id="api">
							<a href="http://confidenceint.synology.me:10180/confidence-api/swagger-ui.html" target="rightContent">
								<li>
									API
								</li>
							</a>
						</div>
					</ul>
				</div>
			</div>
			<!-- 嵌套页面 -->
			<div id="interlayer" class="interlayer" style="background-color: black;"></div>
			<div id="content" class="content">
				<!-- <iframe :src="url" width='100%' height='100%' frameborder="0" name="rightContent"></iframe> -->
				<router-view width='100%' height='100%'></router-view>
			</div>
		</div>
	</div>
</template>

<script>
	import index from '../js/index'
	export default {
		name: "Index",
		data() {
			return {
				menu: "",
				menuList: [],
				userIsShow: false,
				roleIsShow: false,
				productIsShow: false,
				productFunctionIsShow: false,
				deviceManagementIsShow: false,
				enterpriseManagementIsShow: false,
				deviceLogIsShow:false,
				otaIsShow:false,
				apiIsShow: false
			}
		},
		created() {
			index.first(this);
			index.pageLoad(this);
		},
		methods: {
			// searchInfo: function() {
			// 	index.searchInfo(this);
			// },
			logout: function() {
				index.logout(this);
			}
		}
	};
</script>

<style scoped src="../css/index.css">

</style>
