import $ from 'jquery'
import {
	formatterEnum,
	pickRow,
	addTool,
	updateTool,
	idsTool,
	pageLoad
} from './cloudTool'
var token = sessionStorage.getItem("token");
export default {
	second: function(_this,token) {
		var token = sessionStorage.getItem("token");
		var enterpriseId = sessionStorage.getItem("enterpriseId");
		var userType = sessionStorage.getItem("userType");
		var url = userType === "ADMIN" ? "/wave-enterprise/enterprise/selectEnterpriseAll" : "/wave-enterprise/enterprise/getEnterprise_subsetById?enterpriseId="+enterpriseId;
		$(function() {
			$("#table").bootstrapTable({
				height: $(window).height()*0.7,//自定义高度
				url: url, //请求地址
				striped: true, //是否显示行间隔色
				pageNumber: 1, //初始化加载第一页
				ajaxOptions: {
					headers: {
						"Authorization": "Bearer " + token
					}
				},
				pagination: true, //是否分页
				sidePagination: 'client', //server:服务器端分页|client：前端分页
				pageSize: 5, //单页记录数
				pageList: [5, 10, 20, 50], //可选择单页记录数
				showRefresh: false, //刷新按钮
				clickToSelect: true, //是否启用点击选中行
				singleSelect: false, //开启单选,想要获取被选中的行数据必须要有该参数
				columns: [{
					checkbox: true //第一列显示复选框
				}, {
					title: '企业id',
					field: 'enterpriseId',
					sortable: true,
					visible: false
				}, {
					title: '企业名称',
					field: 'enterpriseName',
					sortable: true
				}, {
					title: '企业编码',
					field: 'enterpriseNumber',
					sortable: true
				}, {
					title: '统一社会信用代码',
					field: 'enterpriseCreditCode',
					sortable: true
				}, {
					title: '企业英文名称',
					field: 'enterpriseEnglishName',
					sortable: true
				}, {
					title: '企业地址',
					field: 'enterpriseSite',
					sortable: true
				}, {
					title: '企业法人',
					field: 'enterpriseLegalPerson',
					sortable: true
				}, {
					title: '企业联系人',
					field: 'enterpriseContacts',
					sortable: true
				}, {
					title: '企业联系人电话',
					field: 'enterpriseContactPhone',
					sortable: true
				}, {
					title: '企业联系人邮箱',
					field: 'enterpriseContactEmail',
					sortable: true
				}, {
					title: '上级企业Id',
					field: 'enterpriseParentId',
					sortable: true,
					visible: false
				}, {
					title: '上级企业名称',
					field: 'enterpriseParentName',
					sortable: true
				}, {
					title: '企业类型',
					field: 'enterpriseType',
					sortable: true
				}],
				onLoadSuccess: function(res) {
					$("#table").bootstrapTable("load", res.data)
				}
			});
		})
	},
	first: function(_this,token) {
		pageLoad("enterprise", _this);
	},
	add: function(_this) {
		addTool(_this, "新增企业", "enterprise");
	},
	update: function(_this) {
		var row = pickRow(_this);
		var isPass = updateTool(_this, row, "修改企业", "enterprise");
	},
	saveOrUpdate: function(_this) {
		
		var token = sessionStorage.getItem("token");
		var url = _this.enterprise.enterpriseId == null ? "/wave-enterprise/enterprise/insertEnterprise" :
			"/wave-enterprise/enterprise/updateEnterprise";
		$.ajax({
			type: "POST",
			url: url,
			contentType: "application/json;charset=UTF-8",
			dataType: "json",
			headers: {
				"Authorization": "Bearer " + token
			},
			data: JSON.stringify(_this.enterprise),
			success: function(result) {
				if (result.code == 20000) {
					_this.$message.success(result.message);
					$('#table').bootstrapTable('refresh');
					_this.dialogFormVisible = false;
				} else {
					_this.$message.warning(result.message);
				}
			},
			error: function(xhr, errorText, errorType) {
				if (xhr.status == 403) {
					//do something
					_this.$message.error("抱歉，您没有权限");
					return;
				}
				// _this.$message.error(xhr.responseJSON.error_description);
				// _this.$message.error(xhr.responseText);
			}
		});
	},
	// remove: function(_this) {
	// 	var token = sessionStorage.getItem("token");
	// 	//判断删除是否选中行
	// 	var row = pickRow(_this);
	// 	if (!row) {
	// 		return;
	// 	}

	// 	_this.$confirm('此操作将永久删除该设备, 是否继续?', '提示', {
	// 		confirmButtonText: '确定',
	// 		cancelButtonText: '取消',
	// 		type: 'warning'
	// 	}).then(() => {
	// 		var ids = idsTool(row, "enterprise");
	// 		$.ajax({
	// 			type: "POST",
	// 			contentType: "application/json;charset=UTF-8",
	// 			url: "/wave-enterprise/enterprise/deleteEnterprise",
	// 			dataType: "json",
	// 			data: JSON.stringify(ids),
	// 			headers: {
	// 				"Authorization": "Bearer " + token
	// 			},
	// 			success: function(result) {
	// 				if (result.code == 20000) {
	// 					_this.$message.success(result.message);
	// 				} else {
	// 					_this.$message.error(result.message);
	// 				}
	// 				$('#table').bootstrapTable('refresh');

	// 			},
	// 			error: function(xhr, errorText, errorType) {
	// 				if (xhr.status == 403) {
	// 					//do something
	// 					_this.$message.error("抱歉，您没有权限");
	// 					return;
	// 				}
	// 			}
	// 		});
	// 	}).catch(() => {
	// 		_this.$message({
	// 			type: 'info',
	// 			message: '已取消删除'
	// 		});
	// 	});
	// },
	clear: function(_this) {
		_this.searchenterpriseVo.searchInfo = "";
	},
	search: function(_this) {
		// var token = localStorage.getItem("token");
		var token = sessionStorage.getItem("token");
		$.ajax({
			type: "POST",
			contentType: "application/json;charset=UTF-8",
			url: "/wave-enterprise/enterprise/selectEnterpriseLike",
			headers: {
				"Authorization": "Bearer " + token
			},
			dataType: "json",
			data: JSON.stringify(_this.searchenterpriseVo),
			success: function(res) {
				if (res.code == 20000) {
					_this.$message.success(res.message);
					$('#table').bootstrapTable('load', res.data);
				} else {
					_this.$message.error(res.message);
				}
			},
			error: function(xhr, errorText, errorType) {
				if (xhr.status == 403) {
					//do something
					_this.$message.error("抱歉，您没有权限");
					return;
				}
			}
		});
	},
	reset: function(_this) {
		_this.searchenterpriseVo.searchInfo = "";
		_this.searchenterpriseVo.startCreateTime = "";
		_this.searchenterpriseVo.endCreateTime = "";
		$('#table').bootstrapTable('refresh');
	}
}

