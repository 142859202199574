<template>
	<div id="app" class="container-fluid">
		<div class="header row">
			<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 top"><span class="badge badge-primary">企业管理</span></div>
			<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 top"></div>
			<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 top"></div>
			<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 top"></div>
		</div>
		<div class="main row">
			<el-dialog :title="title" :visible.sync="dialogFormVisible" @close="closeDialog" :close-on-click-modal='false' :append-to-body='true'>
				<div id="enterpriseForm">
					<el-form :model="enterprise" :rules="enterprises" ref="enterpriseForm" class="demo-ruleForm">
						<el-form-item label="企业名称" prop="enterpriseName">
							<el-input v-model="enterprise.enterpriseName" @keyup.enter.native="saveOrUpdate" clearable></el-input>
						</el-form-item>
						<el-form-item label="统一社会信用代码" prop="enterpriseCreditCode">
							<el-input v-model="enterprise.enterpriseCreditCode" @keyup.enter.native="saveOrUpdate" clearable></el-input>
						</el-form-item>
						<el-form-item label="企业英文名称" prop="enterpriseEnglishName">
							<el-input v-model="enterprise.enterpriseEnglishName" @keyup.enter.native="saveOrUpdate" clearable></el-input>
						</el-form-item>
						<el-form-item label="企业地址" prop="enterpriseSite">
							<el-input v-model="enterprise.enterpriseSite" @keyup.enter.native="saveOrUpdate" clearable></el-input>
						</el-form-item>
						<el-form-item label="企业法人" prop="enterpriseLegalPerson">
							<el-input v-model="enterprise.enterpriseLegalPerson" @keyup.enter.native="saveOrUpdate" clearable></el-input>
						</el-form-item>
						<el-form-item label="企业联系人" prop="enterpriseContacts">
							<el-input v-model="enterprise.enterpriseContacts" @keyup.enter.native="saveOrUpdate" clearable></el-input>
						</el-form-item>
						<el-form-item label="企业联系人电话" prop="enterpriseContactPhone">
							<el-input v-model="enterprise.enterpriseContactPhone" @keyup.enter.native="saveOrUpdate" clearable></el-input>
						</el-form-item>
						<el-form-item label="企业联系人邮箱" prop="enterpriseContactEmail">
							<el-input v-model="enterprise.enterpriseContactEmail" @keyup.enter.native="saveOrUpdate" clearable></el-input>
						</el-form-item>
						<el-form-item label="企业类型" prop="enterpriseType">
							<el-input v-model="enterprise.enterpriseType" @keyup.enter.native="saveOrUpdate" clearable></el-input>
						</el-form-item>
						<el-form-item label="上级企业名称" prop="enterpriseParentId">
							<el-select v-model="enterprise.enterpriseParentId" filterable placeholder="请选择" class="enterpriseVal">
								<el-option v-for="enterprise in enterpriseList" :value="enterprise.enterpriseId" :key="enterprise.enterpriseId" :label="enterprise.enterpriseName">
								</el-option>
							</el-select>
						</el-form-item>
					</el-form>
				</div>
				<div slot="footer" class="dialog-footer">
					<el-button @click="closeDialog">取 消</el-button>
					<el-button type="primary" @click="saveOrUpdate">确 定</el-button>
				</div>
			</el-dialog>



			<!-- 搜索框 start -->
			<div class="col-md-12 collapse search" id="collapseExample">

				<div class="col-md-6 input-group input-group-sm ">
					<input type="text" class="col-md-6 form-control" placeholder="请输入名称或者描述搜索" v-model="searchenterpriseVo.searchInfo">
					<input type="date" class="col-md-3 form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm"
					 v-model="searchenterpriseVo.startCreateTime">
					<div class="input-group-append">
						<span class="input-group-text">至</span>
					</div>
					<input type="date" class="col-md-3 form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm"
					 v-model="searchenterpriseVo.endCreateTime">
				</div>

				<div class="col-md-4">
					<button type="button" class="btn btn-info btn-sm" @click="search">
						<svg class="bi bi-search" focusable="false" aria-hidden="true" width="1em" height="1em" viewBox="0 0 18 20" fill="currentColor"
						 xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
							 clip-rule="evenodd" />
							<path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
							 clip-rule="evenodd" />
						</svg>
						搜&nbsp;索
					</button>

					<button type="button" class="btn btn-warning btn-sm" @click="reset">
						<svg width="1em" height="1em" viewBox="0 0 18 20" class="bi bi-arrow-counterclockwise" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" d="M12.83 6.706a5 5 0 0 0-7.103-3.16.5.5 0 1 1-.454-.892A6 6 0 1 1 2.545 5.5a.5.5 0 1 1 .91.417 5 5 0 1 0 9.375.789z" />
							<path fill-rule="evenodd" d="M7.854.146a.5.5 0 0 0-.708 0l-2.5 2.5a.5.5 0 0 0 0 .708l2.5 2.5a.5.5 0 1 0 .708-.708L5.707 3 7.854.854a.5.5 0 0 0 0-.708z" />
						</svg>
						重&nbsp;置
					</button>
				</div>
			</div>
			<!-- 搜索框 end -->

			<!-- 左侧功能按钮 start -->
			<div class="col-md-3 left-botton">
				<button type="button" class="btn btn-sm btn-outline-success" data-toggle="modal" data-target="" @click="add" id="add"
				 v-show="addIsShow">
					<svg class="bi bi-plus" focusable="false" aria-hidden="true" width="1.2em" height="1.2em" viewBox="0 0 15 20" fill="currentColor"
					 xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z"
						 clip-rule="evenodd" />
						<path fill-rule="evenodd" d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z" clip-rule="evenodd" />
					</svg>&nbsp;新增
				</button>
				<button type="button" class="btn btn-sm btn-outline-warning" data-toggle="modal" data-target="" @click="update" id="update"
				 v-show="updateIsShow">
					<svg class="bi bi-pen" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" d="M5.707 13.707a1 1 0 0 1-.39.242l-3 1a1 1 0 0 1-1.266-1.265l1-3a1 1 0 0 1 .242-.391L10.086 2.5a2 2 0 0 1 2.828 0l.586.586a2 2 0 0 1 0 2.828l-7.793 7.793zM3 11l7.793-7.793a1 1 0 0 1 1.414 0l.586.586a1 1 0 0 1 0 1.414L5 13l-3 1 1-3z"
						 clip-rule="evenodd" />
						<path fill-rule="evenodd" d="M9.854 2.56a.5.5 0 0 0-.708 0L5.854 5.855a.5.5 0 0 1-.708-.708L8.44 1.854a1.5 1.5 0 0 1 2.122 0l.293.292a.5.5 0 0 1-.707.708l-.293-.293z"
						 clip-rule="evenodd" />
						<path d="M13.293 1.207a1 1 0 0 1 1.414 0l.03.03a1 1 0 0 1 .03 1.383L13.5 4 12 2.5l1.293-1.293z" />
					</svg>&nbsp;修改
				</button>
				<!-- <button type="button" class="btn btn-sm btn-outline-danger" @click="remove" id="remove" v-show="removeIsShow">
					<svg class="bi bi-trash" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
						<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
						<path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
						 clip-rule="evenodd" />
					</svg>&nbsp;删除
				</button> -->
			</div>
			<!-- 左侧功能按钮 end -->

			<div class="col-md-8"></div>

			<!-- 右侧功能按钮 start-->
			<div class="col-md-1 btn-group right-botton btn-group-sm" role="group" aria-label="...">
				<button type="button" class="btn btn-outline-secondary" data-toggle="collapse" data-target="#collapseExample"
				 aria-expanded="false" aria-controls="collapseExample" @click="clear">
					<svg class="bi bi-search" focusable="false" aria-hidden="true" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor"
					 xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
						 clip-rule="evenodd" />
						<path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
						 clip-rule="evenodd" />
					</svg>
				</button>
				<button type="button" class="btn btn-outline-secondary" @click="search">
					<svg class="bi bi-arrow-repeat" focusable="false" aria-hidden="true" width="1em" height="1em" viewBox="0 0 16 16"
					 fill="currentColor" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" d="M2.854 7.146a.5.5 0 0 0-.708 0l-2 2a.5.5 0 1 0 .708.708L2.5 8.207l1.646 1.647a.5.5 0 0 0 .708-.708l-2-2zm13-1a.5.5 0 0 0-.708 0L13.5 7.793l-1.646-1.647a.5.5 0 0 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0 0-.708z"
						 clip-rule="evenodd" />
						<path fill-rule="evenodd" d="M8 3a4.995 4.995 0 0 0-4.192 2.273.5.5 0 0 1-.837-.546A6 6 0 0 1 14 8a.5.5 0 0 1-1.001 0 5 5 0 0 0-5-5zM2.5 7.5A.5.5 0 0 1 3 8a5 5 0 0 0 9.192 2.727.5.5 0 1 1 .837.546A6 6 0 0 1 2 8a.5.5 0 0 1 .501-.5z"
						 clip-rule="evenodd" />
					</svg>
				</button>
			</div>
			<!-- 右侧功能按钮 end-->



			<!-- 设备信息表格-->
			<div class="table table-sm table-responsive" id="table_div">
				<table id="table"></table>
			</div>

		</div>

		<div class="footer row">
			<div class="col-md-4"></div>
			<div class="col-md-4"></div>
			<div class="col-md-4"></div>
		</div>
	</div>
</template>

<script>
	import enterprise from '../js/enterprise'
	export default {
		name: "enterprise",
		data() {
			var validate = (rule, value, callback) => {
				if (!/^\S+$/.test(value)) {
					callback(new Error('输入不能有空格'));
				}
			};
			return {
				title: "",
				addIsShow:false,
				removeIsShow:false,
				updateIsShow:false,
				dialogFormVisible: false,
				enterprise: {},
				enterpriseList: [],
				enterprises: {
					enterpriseName: [{
							required: true,
							message: '请输入企业名称',
							trigger: 'blur'
						},
						{
							validator: validate,
							trigger: 'blur'
						}
					],
					enterpriseCreditCode: [{
							required: true,
							message: '请输入统一社会信用代码',
							trigger: 'blur'
						},
						{
							validator: validate,
							trigger: 'blur'
						}
					],
					enterpriseEnglishName: [
						// {
						// 	required: true,
						// 	message: '请输入企业英文名称',
						// 	trigger: 'blur'
						// },
						// {
						// 	validator: validate,
						// 	trigger: 'blur'
						// }
					],
					enterpriseSite: [{
							required: true,
							message: '请输入企业地址',
							trigger: 'blur'
						},
						{
							validator: validate,
							trigger: 'blur'
						}
					],
					enterpriseLegalPerson: [{
							required: true,
							message: '请输入企业法人姓名',
							trigger: 'blur'
						},
						{
							validator: validate,
							trigger: 'blur'
						}
					],
					enterpriseContacts: [{
							required: true,
							message: '请输入企业联系人',
							trigger: 'blur'
						},
						{
							validator: validate,
							trigger: 'blur'
						}
					],
					enterpriseContactPhone: [{
							required: true,
							message: '请输入企业联系人电话',
							trigger: 'blur'
						},
						{
							validator: validate,
							trigger: 'blur'
						}
					],
					enterpriseContactEmail: [{
							required: true,
							message: '请输入企业联系人邮箱',
							trigger: 'blur'
						},
						{
							validator: validate,
							trigger: 'blur'
						}
					],
					enterpriseType: [{
							required: true,
							message: '请输入企业类型',
							trigger: 'blur'
						},
						{
							validator: validate,
							trigger: 'blur'
						}
					],
					enterpriseParentId: [
						// {
						// 	required: true,
						// 	message: '请输入企业联系人邮箱',
						// 	trigger: 'blur'
						// },
						// {
						// 	validator: validate,
						// 	trigger: 'blur'
						// }
					]
				},
				
				searchenterpriseVo: {
					searchInfo: "",
					startCreateTime: "",
					endCreateTime: "",
				},
			}
		},
		created() {
			var token = sessionStorage.getItem("token");
			enterprise.first(this,token);
			enterprise.second(this,token);
		},
		mounted() {
			var token = sessionStorage.getItem("token");
			var _this = this;
			$.ajax({
				type: "get",
				url: "/wave-enterprise/enterprise/selectEnterpriseAll",
				contentType: "application/json;charset=UTF-8",
				headers: {
					"Authorization": "Bearer " + token
				},
				dataType: "json",
				success: function(result) {
					_this.enterpriseList = result.data;
				}
			});
			
		},
		methods: {
			add: function() {
				enterprise.add(this)
			},
			update: function() {
				enterprise.update(this)
			},
			saveOrUpdate: function() {
				enterprise.saveOrUpdate(this)
			},
			remove: function() {
				enterprise.remove(this)
			},
			clear: function() {
				enterprise.clear(this)
			},
			search: function() {
				enterprise.search(this)
			},
			reset: function() {
				enterprise.reset(this)
			},
			closeDialog: function() {
				this.dialogFormVisible = false;
				this.$refs.enterpriseForm.resetFields();
			}
		}
	}
</script>

<style scoped src="../css/enterprise.css">

</style>
