<template>
	<div id="app" class="container-fluid">
		<div class="main">

			<el-row type="flex" justify="start" :gutter="20" class="collapse search" id="collapseExample">
				<!-- 搜索框 start -->
				<el-col :span="6">
					<el-input v-model="searchOtaProduct.searchInfo" placeholder="请输入内容"></el-input>
				</el-col>
				<el-col :span="5">
					<el-date-picker v-model="searchOtaProduct.time" type="datetimerange" :picker-options="pickerOptions"
					 range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right">
					</el-date-picker>
				</el-col>
				<el-col :span="6">
					<el-button type="primary" plain icon="el-icon-search" @click="search">查询</el-button>
					<el-button type="warning" plain icon="el-icon-refresh-left" @click="reset">重置</el-button>
				</el-col>
				<!-- 搜索框 end -->
			</el-row>


			<el-row type="flex" justify="space-between">
				<!-- 左侧功能按钮 start -->
				<el-col :span="5" class="left-botton">
					<el-button type="success" icon="el-icon-warning-outline" plain round @click="otaPush" v-show="otaIsPush">推送</el-button>
				</el-col>
				<!-- 左侧功能按钮 end -->
				<!-- <el-col :span="4" class="left-botton">
				</el-col> -->
				<!-- 右侧功能按钮 start-->
				<el-col :span="1.5" class="btn-group right-botton btn-group-sm" role="group" aria-label="...">
					<el-button-group>
						<el-button round icon="el-icon-search" autofocus data-toggle="collapse" data-target="#collapseExample"
						 aria-expanded="false" aria-controls="collapseExample"></el-button>
						<el-button round icon="el-icon-refresh" autofocus @click="search"></el-button>
					</el-button-group>
				</el-col>
				<!-- 右侧功能按钮 end-->
			</el-row>
			<!-- OTA信息表格-->
			<el-row>
				<el-col :span="24">
					<el-table :data="tableData" ref="tableDom" :span-method="objectSpanMethod" @row-click="toggleSelection" v-loading="isloading"
					 border max-height="500" style="width: 100%; margin-top: 20px">
						<el-table-column type="selection" width="55" />
						<el-table-column sortable prop="devId" label="设备ID" width="180" v-if="false">
						</el-table-column>
						<el-table-column sortable prop="devName" label="设备名称">
						</el-table-column>
						<el-table-column sortable prop="devVendorName" label="厂商名称">
						</el-table-column>
						<el-table-column sortable prop="devVendorCode" label="厂商编码">
						</el-table-column>
						<el-table-column sortable prop="devMac" label="MAC地址">
						</el-table-column>
						<el-table-column sortable prop="devSerialNumber" label="序列号">
						</el-table-column>
						<el-table-column sortable prop="devSn" label="SN号">
						</el-table-column>
						<el-table-column sortable prop="devProtocol" label="设备协议">
						</el-table-column>
						<el-table-column sortable prop="devAddress" label="安装地址">
						</el-table-column>
						<el-table-column sortable prop="devStatus" label="设备状态" :formatter="formatterEnum">
						</el-table-column>
						<el-table-column sortable prop="devUpgradeStatus" label="升级状态(OTA)" :formatter="formatterEnum">
						</el-table-column>
						<el-table-column sortable prop="devFirmwareVersion" label="固件版本">
						</el-table-column>
						<el-table-column sortable prop="devHardwareVersion" label="硬件版本">
						</el-table-column>
						<el-table-column sortable prop="devSdkVersion" label="SDK版本">
						</el-table-column>
						<el-table-column sortable prop="devCreateTime" label="设备创建时间">
						</el-table-column>
						<el-table-column sortable prop="devUpdateTime" label="设备修改时间">
						</el-table-column>


					</el-table>
					<!-- <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4"
					 :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, sizes, prev, pager, next, jumper" :total="400">
					</el-pagination> -->
				</el-col>
			</el-row>
			<el-row type="flex" justify="end">
				<el-col :span="24">
				</el-col>
				<el-tag size="mini">设备信息</el-tag>
			</el-row>
			<!-- OTA推送日志表格-->

			<el-row>
				<el-col :span="24">
					<el-table :data="tablePushLogData" ref="tablePushLogDom" :span-method="objectSpanMethod" @row-click="toggleSelection"
					 v-loading="isPushLogloading" border max-height="400" style="width: 100%; margin-top: 20px">
						<el-table-column sortable prop="opId" label="推送ID" width="180" v-if="false">
						</el-table-column>
						<el-table-column sortable prop="opSerialNumber" label="序列号">
						</el-table-column>
						<el-table-column sortable prop="opDeviceMac" label="推送设备mac地址">
						</el-table-column>
						<el-table-column sortable prop="opContent" label="推送内容">
						</el-table-column>
						<el-table-column sortable prop="opCreateTime" label="推送时间">
						</el-table-column>
					</el-table>
					<!-- <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4"
					 :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, sizes, prev, pager, next, jumper" :total="400">
					</el-pagination> -->
				</el-col>


			</el-row>
			<el-row type="flex" justify="end">
				<el-col :span="24">
				</el-col>
				<el-tag size="mini">推送日志</el-tag>
			</el-row>
		</div>
	</div>
</template>

<script>
	import otaProductInfo from '../js/otaProductInfo'
	export default {
		props: {
			otaRowInfo: {}
		},
		name: "otaProductInfo",
		data() {
			return {
				title: "",
				tableData: [],
				tablePushLogData: [],
				otaIsPush:false,
				dialogFormVisible: false,
				searchOtaProduct: {
					searchInfo: "",
					time: ""
				},
				pickerOptions: {
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
				isloading: false,
				isPushLogloading: false
			}
		},
		created() {
			var token = sessionStorage.getItem("token");
			otaProductInfo.pageLoad(this, token);
		},
		methods: {
			formatterEnum: function(row, index, value){
				if (value && value.alias) {
					return value.alias;
				} else {
					return "";
				}
			},
			toggleSelection: function(row) {
				this.$refs.tableDom.toggleRowSelection(row);
			},
			otaPush: function() {
				otaProductInfo.otaPush(this)
			},
			search: function() {
				otaProductInfo.search(this)
			},
			reset: function() {
				otaProductInfo.reset(this)
			},
			objectSpanMethod({
				row,
				column,
				rowIndex,
				columnIndex
			}) {
				const span = column['property'] + '-span'
				if (row[span]) {
					return row[span]
				}

			},
			handleSizeChange: function(val) {
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange: function(val) {
				console.log(`当前页: ${val}`);
			}

		}
	}
</script>

<style scoped src="../css/otaProductInfo.css">
</style>
