<template>
	<div id="main">
		<el-tag type="info">设备号</el-tag>
		<el-input class="input" placeholder="请输入设备号" v-model="cloudage_sn" @change="sn_change" clearable>
			<i slot="suffix" class="el-input__icon el-icon-full-screen" @click="scan_code('cloudage')"></i>
		</el-input>
		<el-tag type="info">摄像头设备号</el-tag>
		<el-input class="input" placeholder="请输入摄像头设备号" v-model="video_mac" @change="video_change" clearable>
			<i slot="suffix" class="el-input__icon el-icon-full-screen" @click="scan_code('video')"></i>
		</el-input>
		<el-tag type="info">诊所</el-tag>
		<el-select :popper-append-to-body="false" v-model="organizationId" filterable clearable placeholder="请选择"
			class="enterpriseVal">
			<el-option v-for="organization in organizationList" :value="organization.organizationId"
				:key="organization.organizationId" :label="organization.organizationName">
			</el-option>
		</el-select>
		<el-button type="primary" plain class="button" @click="save">确认添加</el-button>
	</div>
</template>

<script>
	export default {
		name: 'scanCode',
		data() {
			return {
				cloudage_sn: '',
				video_mac: '',
				organizationList: [],
				organizationId: null
			}
		},
		// computed: {
		// 	// cloudage_sn: {
		// 	// 	get(){
		// 	// 		return sessionStorage.getItem('cloudage') != null ? sessionStorage.getItem('cloudage') : '';
		// 	// 	},
		// 	// 	set(value){
		// 	// 		return value;
		// 	// 	}
		// 	// },
		// 	// video_mac: {
		// 	// 	get(){
		// 	// 		return sessionStorage.getItem('video') != null ? sessionStorage.getItem('video') : '';
		// 	// 	},
		// 	// 	set(value){
		// 	// 		return value;
		// 	// 	}
		// 	// }
		// },
		mounted() {
			var _this = this;
			
			$.ajax({
				type: "get",
				url: "/dcss/organization/selectOrganizationDentalClinic",
				contentType: "application/json;charset=UTF-8",
				dataType: "json",
				success: function(result) {
					// console.log(result)
					if (result.code === 20000) {
						_this.organizationList = result.data;
					}
				}
			});
			
			if (sessionStorage.getItem('cloudage') != null) {
				this.cloudage_sn = sessionStorage.getItem('cloudage');
			}
			if (sessionStorage.getItem('video') != null) {
				this.video_mac = sessionStorage.getItem('video');
			}

		},
		methods: {
			sn_change(){
				sessionStorage.setItem('cloudage', this.cloudage_sn);
			},
			video_change(){
				sessionStorage.setItem('video', this.video_mac);
			},
			scan_code(option) {
				// alert(option)
				this.$router.push({
					name: 'scan_code',
					params: {
						option: option
					}
				});
			},
			save() {
				if (!this.cloudage_sn || !this.video_mac) {
					this.$message.warning("请输入设备号和摄像头设备号！");
					return;
				}
				if (!this.organizationId) {
					this.$message.warning("请选择设备安装的诊所！");
					return;
				}
				let data = {
					organizationId: this.organizationId,
					devName: "消毒灭菌智能监测DTU",
					// devMac: _this.cloudage_sn,
					devSerialNumber: this.cloudage_sn,
					devCloudageDevice: true,
					videoNumber: this.video_mac
				}
				var _this = this;
				$.ajax({
					type: "post",
					url: "/dcss/device/binding",
					contentType: "application/json;charset=UTF-8",
					dataType: "json",
					data: JSON.stringify(data),
					success(result) {
						if (result.code === 20000) {
							_this.$message.success(result.message);
							return;
						} else {
							_this.$message.error(result.message);
							return;
						}
					}
				});
				
				
			}
		}
	}
</script>

<style scoped>
	#main {
		margin: 1%;
		padding-top: 50%;
	}

	/deep/.el-select-dropdown {
		position: absolute !important;
		left: 0px !important;
		width: 80%;
	}

	.el-select-dropdown__item {
		overflow: scroll;
	}

	.enterpriseVal {
		width: 100%;
		margin-bottom: 1%;
	}

	.input {
		margin-bottom: 1%;
	}

	.button {
		/* display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%); */
	}
</style>
