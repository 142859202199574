import $ from 'jquery';
// import {request} from "../plugins/axios.js";
import axios from 'axios';
import {
	formatterEnum,
	pickRow,
	addTool,
	updateTool,
	idsTool,
	pageLoad
} from './cloudTool'

export default {
	pageLoad: function(_this) {
		var username = sessionStorage.getItem("username");
		// $("#userName").html(username);
		_this.username = username;
		var token = sessionStorage.getItem("token");
		$.ajax({
			type: "get",
			url: "/wave-device/product/getProductInfo_Enterprise",
			contentType: "application/json;charset=UTF-8",
			headers: {
				"Authorization": "Bearer " + token
			},
			dataType: "json",
			success: function(result) {
				_this.productList = result.data;
			}
		});
	},
	login: function(_this) {
		var token = sessionStorage.getItem("token");
		const loading = _this.$loading({
			lock: true,
			text: 'Loading',
			spinner: 'el-icon-loading',
			background: 'rgba(0, 0, 0, 0.7)'
		});
		// _this.fullscreenLoading = true;
		var username = _this.loginForm.enterprise_number +"_" + _this.loginForm.username;
		var password = _this.loginForm.password;
		if (!username) {
			_this.$message.error("请输入用户名");
			loading.close();
			return;
		}
		if (username.length < 4 || username.length > 20) {
			_this.$message.error("用户名长度在4~20个字符间");
			loading.close(); //黑色
			// _this.fullscreenLoading = false;//白色
			return;
		}
		if (!/^\S+$/.test(username)) {
			_this.$message.error("账号不能有空格");
			loading.close(); //黑色
			// _this.fullscreenLoading = false;//白色
			return;
		}
		if (!password) {
			_this.$message.error("请输入密码");
			loading.close(); //黑色
			// _this.fullscreenLoading = false;//白色
			return;
		}
		if (password.length < 6 || password.length > 20) {
			_this.$message.error("密码长度在6~20个字符间");
			loading.close(); //黑色
			// _this.fullscreenLoading = false;//白色
			return;
		}
		var param = {};
		param.username = username;
		param.password = password;
		param.isAdmin = true;
		$.ajax({
			type: "GET",
			url: "/wave-uaa/user/login",
			contentType: "application/json;charset=UTF-8",
			dataType: "json",
			data: param,
			success: function(result) {
				if (result.code == 20000) {
					// sessionStorage.setItem('username', param.username);
					// sessionStorage.setItem("token", result.data.accessToken);
					$.ajax({
						type: "GET",
						url: "/wave-user/permissions/pageLoad/",
						contentType: "application/json;charset=UTF-8",
						headers: {
							"Authorization": "Bearer " + result.data.accessToken
						},
						success: function(result) {
							if (result.code === 20000) {
								var permissions = result.data;
								var passed = false;
								for (let i = 0; i < permissions.length; i++) {
									if (permissions[i] === "device:update") {
										_this.disabled = _this.disabled === true ? false : true;
										passed = true;
									}
								}
								if (passed) {
									if (_this.disabled) {
										_this.$message.warning("已锁定！");
									} else {
										_this.$message.success("已解锁！");
									}
									_this.dialogFormVisible = false;
								} else {
									_this.$message.error("您没有权限！");
									$(".error").html("您没有权限！").css({
										"color": "#FF0000"
									});
								}
							}
						}
					});
					loading.close(); //黑色
					// _this.fullscreenLoading = false;//白色
				} else {
					$(".error").html(result.message).css({
						"color": "#FF0000"
					});
					_this.$message.error(result.message);
					loading.close(); //黑色
					// _this.fullscreenLoading = false;//白色
				}
			}

		});
	},
	logout: function(_this) {
		_this.$confirm('确定注销并退出系统吗?', '提示', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'warning'
		}).then(() => {
			_this.$router.push("/logout");
		}).catch(() => {});
	},
	lock_unlock: function(_this) {
		if(_this.disabled === false){
			_this.disabled = true;
			_this.$message.warning("已锁定！");
			return;
		}
		_this.dialogFormVisible = true;
		_this.loginForm = {};
		$(".error").html("").css({
			"color": "#FF0000"
		});
	},
	check_mac: function(_this) {
		if (!_this.disabled) {
			_this.$message.warning("请锁定后进行扫码！");
			return;
		}
		if (!_this.lineName) {
			_this.$message.warning("请输入产线名称！");
			return;
		}
		if (!_this.timeout) {
			_this.$message.warning("请设置超时时间，单位：秒（S）！");
			return;
		}
		if (!_this.productId) {
			_this.$message.warning("请选择产品！");
			return;
		}
		_this.disabled_mac = true;
		var token = sessionStorage.getItem("token");
		
		// 1.校验是否绑定产品
		var resultJson_check_mac;
		$.ajax({
			url: "/wave-device/device/check_mac?mac="+_this.mac,//  请求地址
			type: "get",//  提交方式
			headers: {
				"Authorization": "Bearer " + token
			},
			// data: param,//   请求的数据
			async: false,
			dataType:"json",//   服务器返回值的类型   xml/text/html/json
			success:function(result){//成功   result:服务器返回的请求数据
				resultJson_check_mac = result;
			},
			error: function (XMLHttpRequest, textStatus, errorThrown) { //失败  error:失败
				console.log(XMLHttpRequest.status); // 状态码
				console.log(XMLHttpRequest.readyState); // 状态
				console.log(textStatus); // 错误信息 
			}
		});
		console.log(resultJson_check_mac); 
		if (!resultJson_check_mac.data){
			_this.assignable_causes = resultJson_check_mac.message;
		}
		if(resultJson_check_mac.code === 20006){
			_this.process = 1;
			_this.disabled_mac = false;
			_this.process_status = "error";
			_this.$message.error(resultJson_check_mac.message);
			return;
		}
		// 2.通过mac获取mqtt消息，是否存在遗留消息，如果设备固件升级成功，直接pass，否则继续下一步
		var topic = _this.mtopic+"/"+_this.mac;
		_this.client.subscribe(topic, { qos: 2 },(err) => {
			if (!err) {
				console.log("订阅成功:" + topic);
			} else {
				console.log("订阅失败:" + topic);
			}
		});
		_this.client.on("message", (topic, message ,packet) => {
			_this.msg = message.toString();
			console.log("message:" + _this.msg);
			//判断消息是否pass,录入日志
			if(_this.msg === "true" && "时间"=="时间"){
				$.ajax({
					url: "/wave-device/distinguish/distinguish_add",//  请求地址
					type: "POST",//  提交方式
					headers: {
						"Authorization": "Bearer " + token
					},
					data: JSON.stringify(_this.ota),//   请求的数据
					// async: false,
					dataType:"json",//   服务器返回值的类型   xml/text/html/json
					success:function(result){//成功   result:服务器返回的请求数据
						if(result.code === 20000){
							alert("提交成功！");
						}
					},
					error: function (XMLHttpRequest, textStatus, errorThrown) { //失败  error:失败
						console.log(XMLHttpRequest.status); // 状态码
						console.log(XMLHttpRequest.readyState); // 状态
						console.log(textStatus); // 错误信息 
					}
				});
				_this.client.unsubscribe(topic);//取消订阅
				_this.passed = "PASS";
				_this.process = 5;
				_this.disabled_mac = false;
				return;
			} else {
				// 3.推送固件
				var push_topic = "push_topic";
				var payload = "datetime=11点50分;content=123456";
				_this.client.publish(push_topic, payload,  [{qos: 2},{retain : true}]  , error => {
					if (error) {
						console.log('Publish error', error);
						return;
					}
					console.log("推送成功主题:" + push_topic);
					console.log("推送成功载荷:" + payload);
				});
			}
		});
		
		// 5.获取当前mac主题消息，至设定时间取消订阅
		setTimeout(() => {
			_this.client.unsubscribe(topic);//取消订阅
			_this.passed = _this.process == 5 ? "PASS" : "NG";
			_this.disabled_mac = false;
			if(_this.passed === "NG"){
				_this.process_status = "error";
				_this.$message.error("标定超时！");
				return;
			}
		}, _this.timeout*1000);
	}
}
