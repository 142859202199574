import $ from 'jquery'
import {
	paramsMatter,
	formatTableUnit,
	formatterEnum,
	pickRow,
	addTool,
	updateTool,
	idsTool,
} from './cloudTool'
var token = sessionStorage.getItem("token");
export default {
	loadTable: function(_this, token) {
		var token = sessionStorage.getItem("token");
		$(function() {
			$("#table").bootstrapTable({
				height: $(window).height()*0.7,//自定义高度
				url: "/wave-device/deviceLog/getDeviceLog", //请求地址
				striped: true, //是否显示行间隔色
				pageNumber: 1, //初始化加载第一页
				ajaxOptions: {
					headers: {
						"Authorization": "Bearer " + token
					}
				},
				pagination: true, //是否分页
				sidePagination: 'client', //server:服务器端分页|client：前端分页
				pageSize: 5, //单页记录数
				pageList: [5, 10, 20, 50], //可选择单页记录数
				showRefresh: false, //刷新按钮
				clickToSelect: true, //是否启用点击选中行
				singleSelect: false, //开启单选,想要获取被选中的行数据必须要有该参数
				columns: [{
					checkbox: true //第一列显示复选框
				}, {
					title: '设备日志id',
					field: 'logId',
					sortable: true,
					visible: false
				}, {
					title: '所属产品',
					field: 'pdName',
					sortable: true
				}, {
					title: '设备名称',
					field: 'devName',
					sortable: true
				}, {
					title: '日志时间',
					field: 'logCreateTime',
					sortable: true
				}, {
					title: '日志内容',
					field: 'logContent',
					sortable: true,
					cellStyle: formatTableUnit,
					formatter: paramsMatter,
				}, {
					title: '日志详细内容',
					field: 'logContentPublic',
					sortable: true,
					formatter: function(value , row , index){
						return "<pre style='white-space: pre-wrap;'>"+JSON.stringify(value, null, 2)+"</pre>";
					}
				}],
				onLoadSuccess: function(res) {
					$("#table").bootstrapTable("load", res.data)
				}
			});
			
		})
	},
	clear: function(_this) {
		_this.searchDeviceVo.searchInfo = "";
	},
	search: function(_this) {
		// var token = localStorage.getItem("token");
		// $.ajax({
		// 	type: "POST",
		// 	contentType: "application/json;charset=UTF-8",
		// 	url: "/wave-device/device/selectDeviceLike",
		// 	headers: {
		// 		"Authorization": "Bearer " + token
		// 	},
		// 	dataType: "json",
		// 	data: JSON.stringify(_this.searchDeviceVo),
		// 	success: function(res) {
		// 		if (res.code == 20000) {
		// 			_this.$message.success(res.message);
		// 			$('#table').bootstrapTable('load', res.data);
		// 		} else {
		// 			_this.$message.error(res.message);
		// 		}
		// 	},
		// 	error: function(xhr, errorText, errorType) {
		// 		if (xhr.status == 403) {
		// 			//do something
		// 			_this.$message.error("抱歉，您没有权限");
		// 			return;
		// 		}
		// 	}
		// });
		$('#table').bootstrapTable('refresh');
	},
	reset: function(_this) {
		_this.searchDeviceVo.searchInfo = "";
		_this.searchDeviceVo.startCreateTime = "";
		_this.searchDeviceVo.endCreateTime = "";
		$('#table').bootstrapTable('refresh');
	}
}
