import $ from 'jquery'
import {
	formatterEnum,
	pickRow,
	addTool,
	updateTool,
	idsTool,
	pageLoad
} from './cloudTool'
var token = sessionStorage.getItem("token");
var userInfo = {};
export default {
	second: function(_this,token) {
		var token = sessionStorage.getItem("token");
		var userType = sessionStorage.getItem("userType");
		$(function() {
			$("#table").bootstrapTable({
				height: $(window).height()*0.7,//自定义高度
				url: "/wave-user/user/selectUserAll", //请求地址
				striped: true, //是否显示行间隔色
				pageNumber: 1, //初始化加载第一页
				pagination: true, //是否分页
				sidePagination: 'client', //server:服务器端分页|client：前端分页
				pageSize: 5, //单页记录数
				pageList: [5, 10, 20, 50], //可选择单页记录数
				showRefresh: false, //刷新按钮
				clickToSelect: true, //是否启用点击选中行
				singleSelect: false, //开启单选,想要获取被选中的行数据必须要有该参数
				ajaxOptions: {
					headers: {
						"Authorization": "Bearer " + token
					}
				},
				columns: [{
					checkbox: true //第一列显示复选框
				}, {
					title: '用户id',
					field: 'userId',
					sortable: true,
					visible: false
				}, {
					title: '用户名',
					field: 'userName',
					sortable: true
				}, {
					title: '用户类型',
					field: 'userType',
					sortable: true,
					formatter: formatterEnum,
					visible: userType === "ADMIN" ? true : false
				}, {
					title: '电话',
					field: 'userPhone',
					sortable: true
				}, {
					title: '性别',
					field: 'userGender',
					sortable: true,
					formatter: formatterEnum,
				}, {
					title: '邮箱',
					field: 'userEmail',
					sortable: true
				}, {
					title: '所属企业',
					field: 'userEnterpriseName',
					sortable: true,
					visible: userType === "ADMIN" ? true : false
				}, {
					title: '所属企业Id',
					field: 'userEnterpriseId',
					sortable: true,
					visible: false
				}, {
					title: '角色',
					field: 'userRoleName',
					sortable: true
				}, {
					title: '角色Id',
					field: 'userRoleId',
					sortable: true,
					visible: false
				}, {
					title: '状态',
					field: 'userStatus',
					sortable: true,
					// visible: false
					formatter: formatterEnum,
				}, {
					title: '创建日期',
					field: 'userCreateTime',
					sortable: true
				}, {
					title: '更新日期',
					field: 'userUpdateTime',
					sortable: true
				}],
				onLoadSuccess: function(res) {
					// for (var i = 0; i < res.data.length; i++) {
					// 	if (res.data[i].userGender == "MAN") {
					// 		res.data[i].gender = "男";
					// 	} else if (res.data[i].userGender == "WOMAN") {
					// 		res.data[i].gender = "女";
					// 	}
					// 	if (res.data[i].userStatus == "ENABLE") {
					// 		res.data[i].status = "启用";
					// 	} else if (res.data[i].userStatus == "DISABLE") {
					// 		res.data[i].status = "禁用";
					// 	}
					// }
					$("#table").bootstrapTable("load", res.data);
					userInfo = res.data;
					//合并单元格
					var data = $('#table').bootstrapTable('getData', true);
					mergeCells(data, "userName", 1, $("#table")[0], [])
					mergeCells(data, "userPhone", 1, $("#table")[0], ["userName"])
					mergeCells(data, "gender", 1, $("#table")[0], ["userName", "userPhone"])
					mergeCells(data, "userEmail", 1, $("#table")[0], ["userName", "userPhone", "gender"])
					mergeCells(data, "userEnterprise", 1, $("#table")[0], ["userName", "userPhone", "gender", "userEmail"])
					mergeCells(data, "status", 1, $("#table")[0], ["userName", "userPhone", "gender", "userEmail","userEnterprise"])
					mergeCells(data, "userCreateTime", 1, $("#table")[0], ["userName", "userPhone",
						"gender", "userEmail", "userEnterprise", "status"
					])
					mergeCells(data, "userUpdateTime", 1, $("#table")[0], ["userName", "userPhone",
						"gender", "userEmail", "userEnterprise", "status", "userCreateTime"
					]);
				}
				// ,
				// onClickRow: function(row, element, field) {
				//     _this.user = row;
				// }
			});

		});
		//  *
		//  * 合并单元格
		//  * @param data  原始数据（在服务端完成排序）
		//  * @param fieldName 合并属性名称
		//  * @param colspan   合并列
		//  * @param target    目标表格对象
		// 
		function mergeCells(data, fieldName, colspan, target, upperLevelFieldnameList) {
			if (data.length == 0) {
				return;
			}
			//初始化upperFieldValueList
			var upperFieldValueList = [];
			upperLevelFieldnameList.forEach(function(item) {
				upperFieldValueList.push(data[0][item]);
			})
			data.push({
				"userId": "",
				"userName": "",
				"userPhone": "",
				"userGender": "",
				"userEmail": "",
				"userRoleName": "",
				"userStatus": "",
				"userCreateTime": "",
				"userUpdateTime": ""
			}); //主要是给数据增加一行解决底部合并问题
			var numArr = [];
			var value = data[0][fieldName];
			var num = 0;
			for (var i = 0; i < data.length; i++) {
				// debugger
				if ((value != data[i][fieldName]) | doCheck(data[i], upperLevelFieldnameList)) {
					numArr.push(num);
					value = data[i][fieldName];
					num = 1;
					continue;
				}
				num++;
			}
			var merIndex = 0;
			for (var i = 0; i < numArr.length; i++) {
				$(target).bootstrapTable('mergeCells', {
					index: merIndex,
					field: fieldName,
					colspan: colspan,
					rowspan: numArr[i]
				})
				merIndex += numArr[i];
			}

			//用于检查是否相同，相同返回false不相同更新父类并返回true
			function doCheck(item, upperLevelFieldnameList) {
				var log = false;
				upperLevelFieldnameList.forEach(function(value, index) {
					if (upperFieldValueList[index] != item[value]) {
						log = true;
						upperFieldValueList[index] = item[value]
					};
				})
				return log;
			}
		}

	},
	first: function(_this,token) {
		pageLoad("user", _this);
		$.ajax({
			type: "get",
			url: "/wave-user/role/selectRoleAll",
			headers: {
				"Authorization": "Bearer " + token
			},
			dataType: "json",
			success: function(result) {
				_this.roles = result.data;
			},
			error: function(xhr, errorText, errorType) {
				if (xhr.status == 403) {
					//do something
					// _this.$message.error("抱歉，您没有权限");
					return;
				}
				// _this.$message.error(xhr.responseJSON.error_description);
				// _this.$message.error(xhr.responseText);
			}
		});
		
		$.ajax({
			type: "get",
			url: "/wave-enterprise/enterprise/selectEnterpriseAll",
			headers: {
				"Authorization": "Bearer " + token
			},
			dataType: "json",
			success: function(result) {
				_this.enterpriseList = result.data;
			},
			error: function(xhr, errorText, errorType) {
				if (xhr.status == 403) {
					//do something
					// _this.$message.error("抱歉，您没有权限");
					return;
				}
				// _this.$message.error(xhr.responseJSON.error_description);
				// _this.$message.error(xhr.responseText);
			}
		});
	},
	add: function(_this) {
		addTool(_this, "新增用户", "user");
		_this.mustEnter = true;
		this.isAdmin(_this);
	},
	update: function(_this) {
		var row = pickRow(_this);
		var passed = updateTool(_this, row, "修改用户", "user");
		if (passed) {
			_this.mustEnter = false;
			_this.user.userRoleIds = [];
			userInfo.forEach(function(job, index) {
				if (job.userId == _this.user.userId) { 
					if(job.userRoleId){
						_this.user.userRoleIds.push(job.userRoleId);
					}
				}
			})
		}
		if (_this.user.userType) {
			_this.user.userType = _this.user.userType.value;
		}
		if (_this.user.userStatus) {
			_this.user.userStatus = _this.user.userStatus.value;
		}
		if (_this.user.userGender) {
			_this.user.userGender = _this.user.userGender.value;
		}
		this.isAdmin(_this);
		if (_this.user.userType === "ADMIN"){
			_this.disabled_userType_admin = true;
		} else {
			_this.disabled_userType_admin = false;
		}
	},
	isAdmin: function(_this){
		var userType = sessionStorage.getItem("userType");
		if(userType !== "ADMIN"){
			_this.disabled_userType = false;
			// _this.disabled_userEnterprise = false;
		} else {
			_this.disabled_userType = true;
			_this.disabled_userEnterprise = true;
		}
	},
	saveOrUpdate: function(_this) {
		var token = sessionStorage.getItem("token");
		if (!_this.user.userName) {
			_this.$message.warning("请输入用户名");
			return;
		}
		if (!/^[a-zA-Z0-9_-]{4,20}$/.test(_this.user.userName)) { //4到20位（字母，数字，下划线，减号）
			_this.$message.warning("用户名应为4到20位（字母，数字，下划线，减号）构成");
			return;
		}
		if (!_this.user.userPassword && !_this.user.userId) {
			_this.$message.warning("请输入密码");
			return;
		}
		if (!_this.user.checkPass && !_this.user.userId) {
			_this.$message.warning("请确认密码");
			return;
		}

		if (_this.user.userPassword || _this.user.checkPass) {
			if (!/^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9._~!?@#$^&*+-]{6,20}$/.test(_this.user.userPassword) || !
				/^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9._~!?@#$^&*+-]{6,20}$/.test(
					_this.user.checkPass)) {
				_this.$message.warning("密码只能输入6-20个字母、数字、下划线等特殊符号");
				return;
			} else if (!/^[^\u4e00-\u9fa5]+$/.test(_this.user.userPassword)) {
				_this.$message.warning("密码不能为中文");
				return;
			} else if (!/^\S+$/.test(_this.user.userPassword)) {
				_this.$message.warning('密码不能有空格');
				return;
			}
			if (_this.user.userPassword != _this.user.checkPass) {
				_this.$message.warning("两次输入密码不一致，请重新输入");
				return;
			}
		}




		if (!_this.user.userPhone) {
			_this.$message.warning("请输入手机号码");
			return;
		}

		if (/^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[8-9])[0-9]{8}$/.test(_this.user.userPhone) == false) {
			_this.$message.warning("手机号格式错误");
			return;
		}

		if (!_this.user.userEmail) {
			_this.$message.warning("请输入邮箱");
			return;
		}

		if (/^\w{1,64}@[a-z0-9\-]{1,256}(\.[a-z]{2,6}){1,2}$/i.test(_this.user.userEmail) == false) {
			_this.$message.warning("邮箱格式错误");
			return;
		}


		var url = _this.user.userId == null ? "/wave-user/user/insertUser" : "/wave-user/user/updateUser";
		$.ajax({
			type: "POST",
			url: url,
			contentType: "application/json;charset=UTF-8",
			dataType: "json",
			headers: {
				"Authorization": "Bearer " + token
			},
			data: JSON.stringify(_this.user),
			success: function(result) {
				if (result.code == 20000) {
					_this.$message.success(result.message);
					$('#table').bootstrapTable('refresh');
					_this.dialogFormVisible = false;
				} else {
					_this.$message.warning(result.message);
				}
			},
			error: function(xhr, errorText, errorType) {
				if (xhr.status == 403) {
					//do something
					_this.$message.error("抱歉，您没有权限");
					return;
				}
				// _this.$message.error(xhr.responseJSON.error_description);
				// _this.$message.error(xhr.responseText);
			}
		});

	},
	remove: function(_this) {
		var token = sessionStorage.getItem("token");
		//判断删除是否选中行
		var row = pickRow(_this);
		if (!row) {
			return;
		}
		_this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'warning'
		}).then(() => {
			var ids = idsTool(row, "user");
			$.ajax({
				type: "POST",
				contentType: "application/json;charset=UTF-8",
				url: "/wave-user/user/deleteUser",
				dataType: "json",
				data: JSON.stringify(ids),
				headers: {
					"Authorization": "Bearer " + token
				},
				success: function(result) {
					if (result.code == 20000) {
						_this.$message.success(result.message);
					} else {
						_this.$message.error(result.message);
					}
					// window.location.href = "/wave-admin/to/toUser";
					$('#table').bootstrapTable('refresh');

				},
				error: function(xhr, errorText, errorType) {
					if (xhr.status == 403) {
						//do something
						_this.$message.error("抱歉，您没有权限");
						return;
					}
					// _this.$message.error(xhr.responseJSON.error_description);
					// _this.$message.error(xhr.responseText);
				}
			});
		}).catch(() => {
			_this.$message({
				type: 'info',
				message: '已取消删除'
			});
		});
	},
	//判断是否选中行
	pickRow: function(_this) {
		var row = $("#table").bootstrapTable('getSelections');
		if (!row || row.length == 0) {
			_this.$message.warning('请选择一条记录！');
			return false;
		} else {
			return row;
		}
	},
	search: function(_this) {
		var token = sessionStorage.getItem("token");
		// if(_this.searchUserVo.userStatus){

		// }
		$.ajax({
			type: "POST",
			contentType: "application/json;charset=UTF-8",
			url: "/wave-user/user/searchUserInfo",
			dataType: "json",
			data: JSON.stringify(_this.searchUserVo),
			headers: {
				"Authorization": "Bearer " + token
			},
			success: function(res) {
				if (res.code == 20000) {
					_this.$message.success(res.message);
					for (var i = 0; i < res.data.length; i++) {
						if (res.data[i].userGender == "MAN") {
							res.data[i].gender = "男";
						} else if (res.data[i].userGender == "WOMAN") {
							res.data[i].gender = "女";
						}
						if (res.data[i].userStatus == "ENABLE") {
							res.data[i].status = "启用";
						} else if (res.data[i].userStatus == "DISABLE") {
							res.data[i].status = "禁用";
						}
					}
					$('#table').bootstrapTable('load', res.data);
					//合并单元格
					var data = $('#table').bootstrapTable('getData', true);
					mergeCells(data, "userName", 1, $("#table")[0], []);
					mergeCells(data, "userPhone", 1, $("#table")[0], ["userName"])
					mergeCells(data, "gender", 1, $("#table")[0], ["userName", "userPhone"])
					mergeCells(data, "userEmail", 1, $("#table")[0], ["userName", "userPhone", "gender"])
					mergeCells(data, "userStatus", 1, $("#table")[0], ["userName", "userPhone", "gender", "userEmail"])
					mergeCells(data, "userCreateTime", 1, $("#table")[0], ["userName", "userPhone",
						"gender", "userEmail", "userStatus"
					])
					mergeCells(data, "userUpdateTime", 1, $("#table")[0], ["userName", "userPhone",
						"gender", "userEmail", "userStatus", "userCreateTime"
					]);
				} else {
					_this.$message.error(res.message);
				}
			},
			error: function(xhr, errorText, errorType) {
				if (xhr.status == 403) {
					//do something
					_this.$message.error("抱歉，您没有权限");
					return;
				}
				// _this.$message.error(xhr.responseJSON.error_description);
				// _this.$message.error(xhr.responseText);
			}
		});
		//  *
		//  * 合并单元格
		//  * @param data  原始数据（在服务端完成排序）
		//  * @param fieldName 合并属性名称
		//  * @param colspan   合并列
		//  * @param target    目标表格对象
		// 
		function mergeCells(data, fieldName, colspan, target, upperLevelFieldnameList) {
			if (data.length == 0) {
				return;
			}
			//初始化upperFieldValueList
			var upperFieldValueList = [];
			upperLevelFieldnameList.forEach(function(item) {
				upperFieldValueList.push(data[0][item]);
			})
			data.push({
				"userId": "",
				"userName": "",
				"userPhone": "",
				"userGender": "",
				"userEmail": "",
				"userRoleName": "",
				"userStatus": "",
				"userCreateTime": "",
				"userUpdateTime": ""
			}); //主要是给数据增加一行解决底部合并问题
			var numArr = [];
			var value = data[0][fieldName];
			var num = 0;
			for (var i = 0; i < data.length; i++) {
				// debugger
				if ((value != data[i][fieldName]) | doCheck(data[i], upperLevelFieldnameList)) {
					numArr.push(num);
					value = data[i][fieldName];
					num = 1;
					continue;
				}
				num++;
			}
			var merIndex = 0;
			for (var i = 0; i < numArr.length; i++) {
				$(target).bootstrapTable('mergeCells', {
					index: merIndex,
					field: fieldName,
					colspan: colspan,
					rowspan: numArr[i]
				})
				merIndex += numArr[i];
			}

			//用于检查是否相同，相同返回false不相同更新父类并返回true
			function doCheck(item, upperLevelFieldnameList) {
				var log = false;
				upperLevelFieldnameList.forEach(function(value, index) {
					if (upperFieldValueList[index] != item[value]) {
						log = true;
						upperFieldValueList[index] = item[value]
					};
				})
				return log;
			}
		}
	},
	clear: function(_this) {
		_this.searchUserVo.searchInfo = "";
	},
	reset: function(_this) {
		_this.searchUserVo.searchInfo = "";
		_this.searchUserVo.start = "";
		_this.searchUserVo.end = "";
		_this.searchUserVo.status = "";
		$('#table').bootstrapTable('refresh');
	}

}
