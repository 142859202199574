<template>
	<div class="container-fluid">
		<div class="header row">
			<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 top"><span class="badge badge-primary">用户管理</span></div>
			<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 top"></div>
			<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 top"></div>
			<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 top"></div>
		</div>
		<div class="main row">
			<el-dialog :title="title" :visible.sync="dialogFormVisible" @close="closeDialog" :close-on-click-modal='false'
			 :append-to-body='true'>
				<div id="userForm">
					<el-form :model="user" :rules="users" ref="userForm" class="demo-ruleForm">
						<el-form-item label="用户名" prop="userName">
							<el-input v-model="user.userName" @keyup.enter.native="saveOrUpdate" clearable></el-input>
						</el-form-item>
						<el-form-item label="用户类型" prop="userType" v-show="disabled_userType">
							<el-select :disabled="disabled_userType_admin" v-model="user.userType" placeholder="请选择" class="userTypeVal" clearable>
								<el-option v-for="item in typeList" :key="item.id" :value="item.value" :label="item.label" :disabled="item.disabled">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="密码" prop="userPassword" :required="mustEnter">
							<el-input type="password" v-model="user.userPassword" show-password @keyup.enter.native="saveOrUpdate"
							 autocomplete="off" clearable></el-input>
						</el-form-item>
						<el-form-item label="确认密码" prop="checkPass" :required="mustEnter">
							<el-input type="password" v-model="user.checkPass" show-password @keyup.enter.native="saveOrUpdate" autocomplete="off"
							 clearable></el-input>
						</el-form-item>
						<el-form-item label="手机号码" prop="userPhone">
							<el-input oninput="value=value.replace(/[^0-9.]/g,'')" v-model="user.userPhone" @keyup.enter.native="saveOrUpdate"
							 clearable></el-input>
						</el-form-item>
						<el-form-item label="邮箱" prop="userEmail">
							<el-input v-model="user.userEmail" @keyup.enter.native="saveOrUpdate" clearable></el-input>
						</el-form-item>
						<el-form-item label="所属企业" prop="userEnterprise" v-show="disabled_userEnterprise">
							<el-select v-model="user.userEnterpriseId" placeholder="请选择" class="userEnterpriseVal" clearable>
								<el-option v-for="enterprise in enterpriseList" :key="enterprise.enterpriseId" :value="enterprise.enterpriseId"
								 :label="enterprise.enterpriseName">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="性别">
							<el-select v-model="user.userGender" placeholder="请选择" class="userGenderVal" clearable>
								<el-option v-for="item in genderList" :key="item.id" :value="item.value" :label="item.label">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="角色">
							<el-select v-model="user.userRoleIds" multiple filterable @change="$forceUpdate()" placeholder="请选择" class="userRoleVal"
							 clearable>
								<el-option v-for="role in roles" :key="role.roleId" :value="role.roleId" :label="role.roleName">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="状态">
							<el-select v-model="user.userStatus" placeholder="请选择" class="userStatusVal" clearable>
								<el-option v-for="item in statusList" :key="item.id" :value="item.value" :label="item.label">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="用户授权次数">
							<el-input v-model="user.userAuthorizationNumber" @keyup.enter.native="saveOrUpdate" clearable></el-input>
						</el-form-item>
					</el-form>
				</div>
				<div slot="footer" class="dialog-footer">
					<el-button @click="closeDialog">取 消</el-button>
					<el-button type="primary" @click="saveOrUpdate">确 定</el-button>
				</div>
			</el-dialog>


			<!-- 搜索框 start -->
			<div class="col-md-12 collapse search" id="collapseExample">

				<div class="col-md-6 input-group input-group-sm ">
					<input type="text" class="col-md-6 form-control" placeholder="请输入名称或者描述搜索" v-model="searchUserVo.searchInfo">
					<input type="date" class="col-md-3 form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm"
					 v-model="searchUserVo.startCreateTime">
					<div class="input-group-append">
						<span class="input-=group-text">至</span>
					</div>
					<input type="date" class="col-md-3 form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm"
					 v-model="searchUserVo.endCreateTime">
				</div>

				<div class="btn-group">
					<div>
						<select class="btn-sm dropdown-toggle btn-outline-secondary" v-model="searchUserVo.status">
							<option selected="selected" disabled="disabled" value="">状态</option>
							<option v-for="item in statusList" :key="item.value" :value="item.value">{{item.label}}
							</option>
						</select>
					</div>
				</div>


				<div class="col-md-4">
					<button type="button" class="btn btn-info btn-sm" @click="search">
						<svg class="bi bi-search" focusable="false" aria-hidden="true" width="1em" height="1em" viewBox="0 0 18 20" fill="currentColor"
						 xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
							 clip-rule="evenodd" />
							<path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
							 clip-rule="evenodd" />
						</svg>
						搜&nbsp;索
					</button>

					<button type="button" class="btn btn-warning btn-sm" @click="reset">
						<svg width="1em" height="1em" viewBox="0 0 18 20" class="bi bi-arrow-counterclockwise" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" d="M12.83 6.706a5 5 0 0 0-7.103-3.16.5.5 0 1 1-.454-.892A6 6 0 1 1 2.545 5.5a.5.5 0 1 1 .91.417 5 5 0 1 0 9.375.789z" />
							<path fill-rule="evenodd" d="M7.854.146a.5.5 0 0 0-.708 0l-2.5 2.5a.5.5 0 0 0 0 .708l2.5 2.5a.5.5 0 1 0 .708-.708L5.707 3 7.854.854a.5.5 0 0 0 0-.708z" />
						</svg>
						重&nbsp;置
					</button>
				</div>
			</div>
			<!-- 搜索框 end -->

			<!-- 左侧功能按钮 start -->
			<div class="col-md-3 left-botton">
				<button type="button" class="btn btn-sm btn-outline-success" @click="add" id="add" v-show="addIsShow">
					<svg class="bi bi-plus" focusable="false" aria-hidden="true" width="1.2em" height="1.2em" viewBox="0 0 15 20" fill="currentColor"
					 xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z"
						 clip-rule="evenodd" />
						<path fill-rule="evenodd" d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z" clip-rule="evenodd" />
					</svg>&nbsp;新增
				</button>
				<button type="button" class="btn btn-sm btn-outline-warning" data-toggle="modal" data-target="" @click="update" id="update"
				 v-show="updateIsShow">
					<svg class="bi bi-pen" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" d="M5.707 13.707a1 1 0 0 1-.39.242l-3 1a1 1 0 0 1-1.266-1.265l1-3a1 1 0 0 1 .242-.391L10.086 2.5a2 2 0 0 1 2.828 0l.586.586a2 2 0 0 1 0 2.828l-7.793 7.793zM3 11l7.793-7.793a1 1 0 0 1 1.414 0l.586.586a1 1 0 0 1 0 1.414L5 13l-3 1 1-3z"
						 clip-rule="evenodd" />
						<path fill-rule="evenodd" d="M9.854 2.56a.5.5 0 0 0-.708 0L5.854 5.855a.5.5 0 0 1-.708-.708L8.44 1.854a1.5 1.5 0 0 1 2.122 0l.293.292a.5.5 0 0 1-.707.708l-.293-.293z"
						 clip-rule="evenodd" />
						<path d="M13.293 1.207a1 1 0 0 1 1.414 0l.03.03a1 1 0 0 1 .03 1.383L13.5 4 12 2.5l1.293-1.293z" />
					</svg>&nbsp;修改
				</button>
				<button type="button" class="btn btn-sm btn-outline-danger" @click="remove" id="remove" v-show="removeIsShow">
					<svg class="bi bi-trash" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
						<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
						<path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
						 clip-rule="evenodd" />
					</svg>&nbsp;删除
				</button>
			</div>
			<!-- 左侧功能按钮 end -->

			<div class="col-md-8"></div>

			<!-- 右侧功能按钮 start-->
			<div class="col-md-1 btn-group right-botton btn-group-sm" role="group" aria-label="...">
				<button type="button" class="btn btn-outline-secondary" data-toggle="collapse" data-target="#collapseExample"
				 aria-expanded="false" aria-controls="collapseExample" @click="clear">
					<svg class="bi bi-search" focusable="false" aria-hidden="true" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor"
					 xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
						 clip-rule="evenodd" />
						<path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
						 clip-rule="evenodd" />
					</svg>
				</button>
				<button type="button" class="btn btn-outline-secondary" @click="search">
					<svg class="bi bi-arrow-repeat" focusable="false" aria-hidden="true" width="1em" height="1em" viewBox="0 0 16 16"
					 fill="currentColor" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" d="M2.854 7.146a.5.5 0 0 0-.708 0l-2 2a.5.5 0 1 0 .708.708L2.5 8.207l1.646 1.647a.5.5 0 0 0 .708-.708l-2-2zm13-1a.5.5 0 0 0-.708 0L13.5 7.793l-1.646-1.647a.5.5 0 0 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0 0-.708z"
						 clip-rule="evenodd" />
						<path fill-rule="evenodd" d="M8 3a4.995 4.995 0 0 0-4.192 2.273.5.5 0 0 1-.837-.546A6 6 0 0 1 14 8a.5.5 0 0 1-1.001 0 5 5 0 0 0-5-5zM2.5 7.5A.5.5 0 0 1 3 8a5 5 0 0 0 9.192 2.727.5.5 0 1 1 .837.546A6 6 0 0 1 2 8a.5.5 0 0 1 .501-.5z"
						 clip-rule="evenodd" />
					</svg>
				</button>
			</div>
			<!-- 右侧功能按钮 end-->


			<!-- 用户信息表格-->
			<div class="table table-sm table-responsive"id="table_div">
				<table id="table"></table>
			</div>
		</div>

		<div class="footer row">
			<div class="col-md-4"></div>
			<div class="col-md-4"></div>
			<div class="col-md-4"></div>
		</div>
	</div>
</template>

<script>
	import user from '../js/user'
	export default {
		name: "User",
		data() {
			var validateUserName = (rule, value, callback) => {
				if (!/^[a-zA-Z0-9_-]{4,20}$/.test(value)) { //4到20位（字母，数字，下划线，减号）
					callback(new Error('用户名应为4-20位（字母，数字，下划线，减号）构成'));
				} else {
					callback();
				}
			};
			var validatePass = (rule, value, callback) => {
				if (!value) {
					callback(new Error('请输入用户密码'));
				} else if (!/^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9._~!?@#$^&*+-]{6,20}$/.test(value)) {
					callback(new Error('密码只能输入6-20个字母、数字、下划线等特殊字符'));
				} else if (!/^\S+$/.test(value)) {
					callback(new Error('密码不能有空格'));
				} else if (!/^[^\u4e00-\u9fa5]+$/.test(value)) {
					callback(new Error('密码不能为中文'));
				} else {
					// if (this.user.checkPass !== '') {
					// 	this.$refs.user.validateField('checkPass');
					// }
					callback();
				}
			};
			var validatecheckPass = (rule, value, callback) => {
				if (!value) {
					callback(new Error('请确认用户密码'));
				} else if (value !== this.user.userPassword) {
					callback(new Error('两次输入密码不一致!'));
				} else if (!/^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9._~!?@#$^&*+-]{6,20}$/.test(value)) {
					callback(new Error('密码只能输入6-20个字母、数字、下划线等特殊字符'));
				} else if (!/^\S+$/.test(value)) {
					callback(new Error('密码不能有空格'));
				} else if (!/^[^\u4e00-\u9fa5]+$/.test(value)) {
					callback(new Error('密码不能为中文'));
				} else {
					callback();
				}
			};
			var validatorEmail = (rule, value, callback) => {
				if (!/^[^\u4e00-\u9fa5]+$/.test(value)) {
					callback(new Error('邮箱不能为中文'));
				} else if (/^\w{1,64}@[a-z0-9\-]{1,256}(\.[a-z]{2,6}){1,2}$/i.test(value) == false) {
					callback(new Error("邮箱格式错误"));
				} else {
					callback();
				}
			};

			var validatorPhone = (rule, value, callback) => {
				if (/^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[8-9])[0-9]{8}$/.test(value) == false) {
					callback(new Error("手机号格式错误"));
				} else {
					callback();
				}
			};
			return {
				title: "",
				addIsShow: false,
				removeIsShow: false,
				updateIsShow: false,
				dialogFormVisible: false,
				disabled_userType: false,
				disabled_userType_admin: false,
				disabled_userEnterprise: false,
				mustEnter: true,
				user: {},
				users: {
					userName: [{
							required: true,
							message: '请输入用户名称',
							trigger: 'blur'
						},
						{
							validator: validateUserName,
							trigger: 'blur'
						},
						{
							min: 4,
							max: 20,
							message: '用户名称长度为 4 到 20 个字符',
							trigger: 'blur'
						}

					],
					userType: [{
						required: false,
						message: '请选择用户类型',
						trigger: 'change'
					}],
					userPassword: [{
							required: this.mustEnter,
							// message: '请输入用户密码',
							trigger: 'blur'
						},
						{
							validator: validatePass,
							trigger: 'blur'
						}
					],
					checkPass: [{
							required: this.mustEnter,
							// message: '请确认用户密码',
							trigger: 'blur'
						},
						{
							validator: validatecheckPass,
							trigger: 'blur'
						}
					],
					userPhone: [{
							required: true,
							message: '请输入手机号码',
							trigger: 'blur'
						},
						{
							min: 11,
							max: 11,
							message: '+86手机号码应为11位',
							trigger: "blur"
						}, {
							validator: validatorPhone,
							trigger: "blur"
						}
					],
					userEmail: [{
						required: true,
						message: '请输入用户邮箱',
						trigger: 'blur'
					}, {
						validator: validatorEmail,
						trigger: 'blur'
					}]
				},
				// userRoleVal: "",
				roles: [],
				// userGenderVal: "",
				enterpriseList: [],
				typeList: [{
					id: 0,
					label: '超级管理员',
					value: 'ADMIN',
					disabled: true
				}, {
					id: 1,
					label: '企业',
					value: 'ENTERPRISE'
				}, {
					id: 2,
					label: '个人',
					value: 'PERSON'
				}],
				genderList: [{
						id: 0,
						label: '女',
						value: 'WOMAN'
					},
					{
						id: 1,
						label: '男',
						value: 'MAN'
					}
				],
				// userStatusVal: "",
				statusList: [{
						id: 0,
						label: '启用',
						value: 'ENABLE'
					},
					{
						id: 1,
						label: '禁用',
						value: 'DISABLE'
					},
				],
				searchUserVo: {
					searchInfo: "",
					startCreateTime: "",
					endCreateTime: "",
					startUpdateTime: "",
					endUpdateTime: "",
					status: ""
				}
			}
		},
		created() {
			var token = sessionStorage.getItem("token");
			user.first(this, token);
			user.second(this, token);
		},
		methods: {
			add: function() {
				user.add(this);
			},
			update: function() {
				user.update(this);
			},
			saveOrUpdate: function() {
				user.saveOrUpdate(this);
			},
			remove: function() {
				user.remove(this);
			},
			formatterEnumValue: function() {
				user.formatterEnumValue(this);
			},
			pickRow: function() {
				user.pickRow(this);
			},
			search: function() {
				user.search(this);
			},
			clear: function() {
				user.clear(this);
			},
			reset: function() {
				user.reset(this);
			},
			closeDialog: function() {
				this.$refs.userForm.resetFields();
				this.dialogFormVisible = false;
			}
		}
	}
</script>
<style scoped src="../css/user.css">

</style>
