import $ from 'jquery'

export default {
	login: function(_this) {
		const loading = _this.$loading({
			lock: true,
			text: 'Loading',
			spinner: 'el-icon-loading',
			background: 'rgba(0, 0, 0, 0.7)'
		});
		var username = _this.ruleForm.enterprise_number + "_" + _this.ruleForm.username;
		var password = _this.ruleForm.password;
		if (!_this.ruleForm.enterprise_number) {
			_this.$message.error("请输入企业编码");
			loading.close();
			return;
		}
		if (!username) {
			_this.$message.error("请输入用户名");
			loading.close();
			return;
		}
		if (username.length < 4 || username.length > 20) {
			_this.$message.error("用户名长度在4~20个字符间");
			loading.close();
			return;
		}
		if (!/^\S+$/.test(username)) {
			_this.$message.error("账号不能有空格");
			loading.close();
			return;
		}
		if (!password) {
			_this.$message.error("请输入密码");
			loading.close();
			return;
		}
		if (password.length < 6 || password.length > 20) {
			_this.$message.error("密码长度在6~20个字符间");
			loading.close();
			return;
		}
		var param = {};
		param.username = username;
		param.password = password;
		param.isAdmin = true;
		$.ajax({
			type: "GET",
			url: "/wave-uaa/user/login",
			contentType: "application/json;charset=UTF-8",
			dataType: "json",
			data: param,
			success: function(result) {
				// alert(result.msg);
				if (result.code == 20000) {
					// var localstorage = window.localStorage;
					// sessionStorage.setItem('isLogin','true');
					sessionStorage.setItem('username', param.username.split("_")[1]);
					// console.log(result.data.accessToken);
					sessionStorage.setItem("token", result.data.accessToken);

					$.ajax({
						type: "get",
						url: "/wave-user/user/getUserInfoByToken",
						contentType: "application/json;charset=UTF-8",
						headers: {
							"Authorization": "Bearer " + result.data.accessToken
						},
						dataType: "json",
						success: function(result) {
							sessionStorage.setItem("userType", result.data.userType);
							sessionStorage.setItem("enterpriseId", result.data.enterprise_id);
						}
					});
					
					$.ajax({
						type: "GET",
						url: "/wave-user/permissions/pageLoad/",
						contentType: "application/json;charset=UTF-8",
						headers: {
							"Authorization": "Bearer " + result.data.accessToken
						},
						success: function(result) {
							if (result.code === 20000) {
								var permissions = result.data;
								for (let i = 0; i < permissions.length; i++) {
									if (permissions[i] === "device:distinguish") {
										_this.distinguish = true;
									}
								}
							}
							if (_this.distinguish) {
								_this.$router.push("/distinguish");
							} else {
								_this.$router.push("/index");
							}
						}
					});
					_this.$message.success(result.message);
					loading.close();
				} else {
					$(".error").html(result.message).css({
						"color": "#FF0000"
					});
					_this.$message.error(result.message);
					loading.close();
				}
			},
			error: function(xhr, errorText, errorType) {
				if (xhr.status == 403) {
					//do something
					_this.$message.error("抱歉，您没有权限");
					return;
				} else {
					_this.$message.error("系统错误，请联系管理员");
					loading.close();
					return;
				}
			}
		});
	},
	submit: function() {
		if ($("#insertUserPhone").val().length != 11) {
			alert("请输入正确的手机号码");
		} else {
			var insertUser = {};
			insertUser.userName = $("#insertUserName").val();
			insertUser.userPassword = $("#insertUserPassword").val();
			insertUser.userPhone = $("#insertUserPhone").val();
			insertUser.userGender = $("#insertUserGender").val();
			insertUser.userEmail = $("#insertUserEmail").val();
			$.ajax({
				type: "POST",
				url: "/wave-user/user/register",
				contentType: "application/json;charset=UTF-8",
				dataType: "json",
				data: JSON.stringify(insertUser),
				success: function(result) {
					alert(result.message);
					if (result.code == 200) {
						if (window.sessionStorage) {
							// var localstorage = window.localStorage;
							sessionStorage.setItem('userName', insertUser.userName);
							sessionStorage.setItem("token", result.data.accessToken);
						}
						this.$router.push("/index");
					}
				}
			});
		}
	}

}
