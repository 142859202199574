// import $ from 'jquery'
var token = sessionStorage.getItem("token");
export default {
	first: function() {
		var token = sessionStorage.getItem("token");
		$(function() {
			$(".menuParent").eq(0).click(function() {
				$(".system").slideToggle();
				rotate("system");
			});
			$(".menuParent").eq(1).click(function() {
				$(".equipment").slideToggle();
				rotate("device");
			});
			$(".menuParent").eq(2).click(function() {
				$(".enterprise").slideToggle();
				rotate("enterprise");
			});
			$(".menuParent").eq(3).click(function() {
				$(".api").slideToggle();
				rotate("api");
			});
			// 图标旋转180°start
			function rotate(info) {
				if ($("#" + info).hasClass("rotate")) {
					$("#" + info).removeClass("rotate").addClass("rotate1");
				} else {
					$("#" + info).removeClass("rotate1").addClass("rotate");
				}
			}
			//图标旋转180°end

			//侧边菜单栏收缩展开start
			var Sidebar = function(eId, closeBarId) {
				this.state = 'opened';
				if ((document.body.offsetWidth != screen.width && document.body.offsetWidth < 1400) || screen.width < 1400) {
					this.state = 'closed';
				}
				this.el = document.getElementById(eId || 'menuName');
				// this.closeBarEl = document.getElementById(closeBarId || 'menuName');
				var self = this;
				this.el.addEventListener('click', function(event) {
					if (event.target !== self.el) {
						self.triggerSwich();
					}
				});
			};
			Sidebar.prototype.close = function() {
				// console.log("关闭");
				document.getElementById('menu').style.left = '0';
				document.getElementById('menu').className = 'sidebar-move-left menu';
				document.getElementById('content').className = 'sidebar-stretch-left content';
				if ((document.body.offsetWidth != screen.width && document.body.offsetWidth < 1400) || screen.width < 1400) {
					$(".interlayer").attr("style", "display:none;");
				}
				// console.log(document.getElementById('content'));
				this.state = "closed";
			};
			Sidebar.prototype.open = function() {
				// console.log("打开");
				document.getElementById('menu').style.left = '-10%';
				document.getElementById('menu').className = 'sidebar-move-right menu';
				document.getElementById('content').className = 'sidebar-stretch-right content';
				if ((document.body.offsetWidth != screen.width && document.body.offsetWidth < 1400) || screen.width < 1400) {
					$(".interlayer").attr("style", "display:block;");
				}
				// console.log(document.getElementById('content'));
				this.state = 'opened';
			};
			Sidebar.prototype.triggerSwich = function() {
				if (this.state === 'opened') {
					this.close();
				} else {
					this.open();
				}
			};
			var sidebar = new Sidebar();
			//侧边菜单栏收缩展开end
			//点击空白start
			$(".interlayer").click(function(event) {
				sidebar.close();
			});
			//点击空白end

			var username = sessionStorage.getItem("username");
			$("#userName").html(username);
		});
	},
	pageLoad: function(_this) {
		// var username = sessionStorage.getItem("username");
		var token = sessionStorage.getItem("token"); //异步加载写在这
		var userType = sessionStorage.getItem("userType"); //异步加载写在这
		// _this.disabled = userType === "ADMIN" ? true : false;
		// if (!username) {
		// 	return;
		// }
		$.ajax({
			type: "get",
			url: "/wave-user/permissions/pageLoad/",
			headers: {
				"Authorization": "Bearer " + token
			},
			success: function(res) {
				if (res.code === 20000){
					var permissions = res.data;
					for (let i = 0; i < permissions.length; i++) {
						if (permissions[i] === "user:query") {
							// $("#user").attr("style", "display:block;");
							_this.userIsShow = true;
						}
						if (permissions[i] === "role:query") {
							// $("#role").attr("style", "display:block;");
							_this.roleIsShow = true;
						}
						if (permissions[i] === "product:query") {
							// $("#product").attr("style", "display:block;");
							_this.productIsShow = true;
						}
						if (permissions[i] === "product_function:query") {
							// $("#productConfig").attr("style", "display:block;");
							_this.productFunctionIsShow = true;
						}
						if (permissions[i] === "device:query") {
							// $("#deviceManagement").attr("style", "display:block;");
							_this.deviceManagementIsShow = true;
						}
						if (permissions[i] === "enterprise:query") {
							// $("#deviceManagement").attr("style", "display:block;");
							_this.enterpriseManagementIsShow = true;
						}
						if (permissions[i] === "api:query") {
							// $("#deviceManagement").attr("style", "display:block;");
							_this.apiIsShow = true;
						}
						if (permissions[i] === "ota:query") {
							// $("#deviceManagement").attr("style", "display:block;");
							_this.otaIsShow = true;
						}
						if (permissions[i] === "device_log:query") {
							// $("#deviceManagement").attr("style", "display:block;");
							_this.deviceLogIsShow = true;
						}
					}
				}
					
			}
		});
	},
	// searchInfo: function(_this) {
	// 	var menu = _this.menu;
	// 	var userName = localStorage.getItem("userName");
	// 	if (menu == null || menu == "") {
	// 		alert("请先输入要查询的菜单");
	// 	} else {
	// 		$.ajax({
	// 			type: "get",
	// 			// contentType: "application/json;charset=UTF-8",
	// 			url: "/wave-api/menu/selectMenu",
	// 			data: {
	// 				"menu": _this.menu,
	// 				"userName": userName
	// 			},
	// 			success: function(res) {
	// 				if (res.code == 200) {
	// 					const fresh_link = new Date().getTime();
	// 					var res2 = res.data.menuUrl.split("/");
	// 					_this.url = "/" + res2[2] + "?time=" + fresh_link;
	// 				} else {
	// 					alert(res.message);
	// 				}
	// 			}
	// 		});
	// 	}
	// },
	logout: function(_this) {
		_this.$confirm('确定注销并退出系统吗?', '提示', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'warning'
		}).then(() => {
			_this.$router.push("/logout");
		}).catch(() => {});
	}

}
