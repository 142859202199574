import $ from 'jquery'
import {
	formatterEnum,
	pickRow,
	addTool,
	updateTool,
	idsTool,
	pageLoad
} from './cloudTool'
var token = sessionStorage.getItem("token");
var productInfo = {};
export default {
	second: function(_this, token) {
		var token = sessionStorage.getItem("token");
		$(function() {
			//根据窗口调整表格高度
			$(window).resize(function() {
				$('#table').bootstrapTable('resetView', {
					height: tableHeight()
				})
			});
			$("#table").bootstrapTable({
				height: $(window).height()*0.7,//自定义高度
				url: "/wave-device/product/selectProductAndEnterprise_All", //请求地址
				striped: true, //是否显示行间隔色
				pageNumber: 1, //初始化加载第一页
				pagination: true, //是否分页
				ajaxOptions: {
					headers: {
						"Authorization": "Bearer " + token
					}
				},
				sidePagination: 'client', //server:服务器端分页|client：前端分页
				pageSize: 5, //单页记录数
				pageList: [5, 10, 20, 50], //可选择单页记录数
				showRefresh: false, //刷新按钮
				clickToSelect: true, //是否启用点击选中行
				singleSelect: false, //开启单选,想要获取被选中的行数据必须要有该参数
				columns: [{
					checkbox: true //第一列显示复选框
				}, {
					title: '产品id',
					field: 'pdId',
					sortable: true,
					visible: false
				}, {
					title: '产品名称',
					field: 'pdName',
					sortable: true
				}, {
					title: '厂商名称',
					field: 'enterpriseName',
					sortable: true
				}, {
					title: '厂商名称Id',
					field: 'enterpriseId',
					sortable: true,
					visible: false
				}, {
					title: '产品类型',
					field: 'pdType',
					sortable: true
				}, {
					title: '产品公开类型',
					field: 'pdOpenType',
					sortable: true,
					formatter: formatterEnum
				}, {
					title: '产品图标',
					field: 'pdPictureUrl',
					sortable: true,
					align: 'center',
					formatter: function(value, row, index) {
						return '<img  src=' + value + ' class="img-rounded" style="width: 100%;" >';
					},
				}, {
					title: "产品功能",
					field: 'pdFunctionName',
					sortable: true,
					align: 'center',
					formatter: function(value, row, index) {
						return '<a href="#" class="functionClick">' + "查看详情" + '</a>';
					},
					events: {
						"click .functionClick": functionClick
					}
				}, {
					title: "产品升级状态（OTA）",
					field: 'pdUpgradeStatus',
					sortable: true,
					formatter: formatterEnum
				}, {
					title: '产品创建时间',
					field: 'pdCreateTime',
					sortable: true
				}, {
					title: '产品修改时间',
					field: 'pdUpdateTime',
					sortable: true
				}],
				onLoadSuccess: function(res) {
					productInfo = res.data;
					$("#table").bootstrapTable("load", res.data);
					//合并单元格
					var data = $('#table').bootstrapTable('getData', true);
					mergeCells(data, "pdName", 1, $("#table")[0], [])
					mergeCells(data, "pdType", 1, $("#table")[0], ["pdName"])
					mergeCells(data, "pdPictureUrl", 1, $("#table")[0], ["pdName", "pdType"])
					mergeCells(data, "pdCreateTime", 1, $("#table")[0], ["pdName", "pdType", "pdPictureUrl"])
					mergeCells(data, "pdUpdateTime", 1, $("#table")[0], ["pdName", "pdType",
						"pdPictureUrl", "pdCreateTime"
					])
				}
			});
		});

		function functionClick(event, value, row, index) {
			// _this.$emit("fatherEvent", row)
			_this.tableFunction = true;
			_this.productName = row.pdName;
			_this.product = row;
			_this.getFunctionInfo(_this);
		}
		//  *
		//  * 合并单元格
		//  * @param data  原始数据（在服务端完成排序）
		//  * @param fieldName 合并属性名称
		//  * @param colspan   合并列
		//  * @param target    目标表格对象
		// 
		function mergeCells(data, fieldName, colspan, target, upperLevelFieldnameList) {
			if (data.length == 0) {
				return;
			}
			//初始化upperFieldValueList
			var upperFieldValueList = [];
			upperLevelFieldnameList.forEach(function(item) {
				upperFieldValueList.push(data[0][item]);
			})
			data.push({
				"pdName": "",
				"pdType": "",
				"pdFunctionName": "",
				"pdCreateTime": "",
				"pdUpdateTime": ""
			}); //主要是给数据增加一行解决底部合并问题
			var numArr = [];
			var value = data[0][fieldName];
			var num = 0;
			for (var i = 0; i < data.length; i++) {
				// debugger
				if ((value != data[i][fieldName]) | doCheck(data[i], upperLevelFieldnameList)) {
					numArr.push(num);
					value = data[i][fieldName];
					num = 1;
					continue;
				}
				num++;
			}
			var merIndex = 0;
			for (var i = 0; i < numArr.length; i++) {
				$(target).bootstrapTable('mergeCells', {
					index: merIndex,
					field: fieldName,
					colspan: colspan,
					rowspan: numArr[i]
				})
				merIndex += numArr[i];
			}

			//用于检查是否相同，相同返回false不相同更新父类并返回true
			function doCheck(item, upperLevelFieldnameList) {
				var log = false;
				upperLevelFieldnameList.forEach(function(value, index) {
					if (upperFieldValueList[index] != item[value]) {
						log = true;
						upperFieldValueList[index] = item[value]
					};
				})
				return log;
			}
		}
	},
	first: function(_this, token) {
		pageLoad("product", _this);
		var token = sessionStorage.getItem("token");
		$.ajax({
			type: "get",
			url: "/wave-device/function/selectFunctionAll",
			contentType: "application/json;charset=UTF-8",
			headers: {
				"Authorization": "Bearer " + token
			},
			dataType: "json",
			success: function(result) {
				_this.productFunctionList = result.data;
			}
		});
		$.ajax({
			type: "get",
			url: "/wave-enterprise/enterprise/selectEnterpriseAll",
			headers: {
				"Authorization": "Bearer " + token
			},
			dataType: "json",
			success: function(result) {
				_this.productEnterpriseList = result.data;
			},
			error: function(xhr, errorText, errorType) {
				if (xhr.status == 403) {
					//do something
					// _this.$message.error("抱歉，您没有权限");
					return;
				}
				// _this.$message.error(xhr.responseJSON.error_description);
				// _this.$message.error(xhr.responseText);
			}
		});
	},
	add: function(_this) {
		addTool(_this, "新增产品", "product");
		_this.dynamicValidateForm.productFunctions = [{}];
		_this.functionInfoButton = false;
		_this.badge = false;
		this.isAdmin(_this);
		_this.upload_img = false;
	},
	update: function(_this) {
		var row = pickRow(_this);
		if (!row) {
			return;
		}
		var isPass = updateTool(_this, row, "修改产品", "product");
		if (isPass) {
			// _this.product.functionIds = [];
			// // var ids = [];
			// productInfo.forEach(function(job, index) {
			// 	if (job.pdId == _this.product.pdId) { //比较当前行产品id
			// 		// ids[index] = job.pdFunctionId;
			// 		if (job.pdFunctionId) {
			// 			_this.product.functionIds.push(job.pdFunctionId);
			// 		}
			// 	}
			// })
			_this.dynamicValidateForm.productFunctions = _this.product.productFunction.length == 0 ? [{}] : _this.product.productFunction;

			if (_this.product.pdUpgradeStatus) {
				_this.product.pdUpgradeStatus = _this.product.pdUpgradeStatus.value;
			}
			if (_this.product.pdOpenType) {
				_this.product.pdOpenType = _this.product.pdOpenType.value;
			}
			
			// _this.product.functionIds = ids;
			this.isAdmin(_this);
			_this.upload_img = true;
		}

		_this.functionInfoButton = true;
		_this.badge = true;

	},
	isAdmin: function(_this) {
		var userType = sessionStorage.getItem("userType");
		if (userType === "ADMIN") {
			_this.isAdmin_show = true;
		} else {
			_this.isAdmin_show = false;
		}
	},
	saveOrUpdate: function(_this) {
		var token = sessionStorage.getItem("token");
		if (!_this.product.pdName) {
			_this.$message.warning('请输入产品名称');
			return;
		}
		if (!_this.product.pdType) {
			_this.$message.warning('请输入产品类型');
			return;
		}
		if (!/^\S+$/.test(_this.product.pdName) || !/^\S+$/.test(_this.product.pdType)) {
			_this.$message.warning('输入不能有空格');
			return;
		}

		let isPass = true;
		_this.dynamicValidateForm.productFunctions.forEach(function(info, index, allInfo) {
			if (info.functionId && !info.portValue) {
				isPass = false;
			} else if (!info.functionId || !info.portValue) {
				_this.dynamicValidateForm.productFunctions = [];
			}
		});
		if (!isPass) {
			_this.$message.warning('所选产品功能存在port_id为空，请输入port_id');
			return;
		}

		_this.product.functionInfo = _this.dynamicValidateForm.productFunctions;
		var url = _this.product.pdId == null ? "/wave-device/product/insertProduct" :
			"/wave-device/product/updateProduct";
		$.ajax({
			type: "POST",
			url: url,
			contentType: "application/json;charset=UTF-8",
			dataType: "json",
			headers: {
				"Authorization": "Bearer " + token
			},
			data: JSON.stringify(_this.product),
			success: function(result) {
				if (result.code == 20000) {
					_this.$message.success(result.message);
					$('#table').bootstrapTable('refresh');
					_this.dialogFormVisible = false;
				} else {
					_this.$message.warning(result.message);
				}
			},
			error: function(xhr, errorText, errorType) {
				if (xhr.status == 403) {
					//do something
					_this.$message.error("抱歉，您没有权限");
					return;
				}
			}
		});

	},
	remove: function(_this) {
		var token = sessionStorage.getItem("token");
		//判断删除是否选中行
		var row = pickRow(_this);
		if (!row) {
			return;
		}
		_this.$confirm('此操作将永久删除该产品, 是否继续?', '提示', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'warning'
		}).then(() => {
			var ids = idsTool(row, "product");
			$.ajax({
				type: "POST",
				contentType: "application/json;charset=UTF-8",
				url: "/wave-device/product/deleteProduct",
				dataType: "json",
				headers: {
					"Authorization": "Bearer " + token
				},
				data: JSON.stringify(ids),
				success: function(result) {
					if (result.code == 20000) {
						_this.$message.success(result.message);
					} else {
						_this.$message.error(result.message);
					}
					// window.location.href = "/wave-user/to/toUser";
					$('#table').bootstrapTable('refresh');

				},
				error: function(xhr, errorText, errorType) {
					if (xhr.status == 403) {
						//do something
						_this.$message.error("抱歉，您没有权限");
						return;
					}
				}
			});
		}).catch(() => {
			_this.$message({
				type: 'info',
				message: '已取消删除'
			});
		});
	},
	uploadSuccess: function(_this, response, file, fileList) {
		var token = sessionStorage.getItem("token");
		if (response.code == 20001) {
			_this.fileList = [];
			_this.$message.error(response.message);
			return;
		}
		let data = {
			url:response.data,
			pdId:_this.product.pdId
		};
		$.ajax({
			type: "POST",
			url: "/wave-device/product/update_pictureUrl",
			contentType: "application/x-www-form-urlencoded",
			dataType: "json",
			headers: {
				"Authorization": "Bearer " + token
			},
			data: data,
			success: function(result) {
				if (result.code == 20000) {
					$('#table').bootstrapTable('refresh');
					_this.product.pdPictureUrl = response.data;
					_this.$message.success("上传成功！");
					return;
				} else {
					_this.fileList = [];
					_this.$message.error("上传失败");
					result;
				}
			},
			error: function(xhr, errorText, errorType) {
				if (xhr.status == 403) {
					//do something
					_this.$message.error("抱歉，您没有权限");
					return;
				}
				// _this.$message.error(xhr.responseJSON.error_description);
				// _this.$message.error(xhr.responseText);
			},
		});
	},
	clear: function(_this) {
		_this.searchProductVo.searchInfo = "";
	},

	search: function(_this) {
		var token = sessionStorage.getItem("token");
		// var token = localStorage.getItem("token");
		$.ajax({
			type: "POST",
			contentType: "application/json;charset=UTF-8",
			url: "/wave-device/product/selectProductLike",
			dataType: "json",
			headers: {
				"Authorization": "Bearer " + token
			},
			data: JSON.stringify(_this.searchProductVo),
			success: function(res) {
				if (res.code == 20000) {
					$('#table').bootstrapTable('load', res.data);
					//合并单元格
					var data = $('#table').bootstrapTable('getData', true);
					mergeCells(data, "pdName", 1, $("#table")[0], [])
					mergeCells(data, "pdType", 1, $("#table")[0], ["pdName"])
					mergeCells(data, "pdPictureUrl", 1, $("#table")[0], ["pdName", "pdType"])
					mergeCells(data, "pdCreateTime", 1, $("#table")[0], ["pdName", "pdType", "pdPictureUrl"])
					mergeCells(data, "pdUpdateTime", 1, $("#table")[0], ["pdName", "pdType",
						"pdPictureUrl", "pdCreateTime"
					])
				} else {
					_this.$message.error(res.message);
				}
			},
			error: function(xhr, errorText, errorType) {
				if (xhr.status == 403) {
					//do something
					_this.$message.error("抱歉，您没有权限");
					return;
				}
				// _this.$message.error(xhr.responseJSON.error_description);
				// _this.$message.error(xhr.responseText);
			}
		});
		//  *
		//  * 合并单元格
		//  * @param data  原始数据（在服务端完成排序）
		//  * @param fieldName 合并属性名称
		//  * @param colspan   合并列
		//  * @param target    目标表格对象
		// 
		function mergeCells(data, fieldName, colspan, target, upperLevelFieldnameList) {
			if (data.length == 0) {
				return;
			}
			//初始化upperFieldValueList
			var upperFieldValueList = [];
			upperLevelFieldnameList.forEach(function(item) {
				upperFieldValueList.push(data[0][item]);
			})
			data.push({
				"userId": "",
				"userName": "",
				"userPhone": "",
				"userGender": "",
				"userEmail": "",
				"userRoleName": "",
				"userStatus": "",
				"userCreateTime": "",
				"userUpdateTime": ""
			}); //主要是给数据增加一行解决底部合并问题
			var numArr = [];
			var value = data[0][fieldName];
			var num = 0;
			for (var i = 0; i < data.length; i++) {
				// debugger
				if ((value != data[i][fieldName]) | doCheck(data[i], upperLevelFieldnameList)) {
					numArr.push(num);
					value = data[i][fieldName];
					num = 1;
					continue;
				}
				num++;
			}
			var merIndex = 0;
			for (var i = 0; i < numArr.length; i++) {
				$(target).bootstrapTable('mergeCells', {
					index: merIndex,
					field: fieldName,
					colspan: colspan,
					rowspan: numArr[i]
				})
				merIndex += numArr[i];
			}

			//用于检查是否相同，相同返回false不相同更新父类并返回true
			function doCheck(item, upperLevelFieldnameList) {
				var log = false;
				upperLevelFieldnameList.forEach(function(value, index) {
					if (upperFieldValueList[index] != item[value]) {
						log = true;
						upperFieldValueList[index] = item[value]
					};
				})
				return log;
			}
		}
	},

	reset: function(_this) {
		_this.searchProductVo.searchInfo = "";
		_this.searchProductVo.startCreateTime = "";
		_this.searchProductVo.endCreateTime = "";
		$('#table').bootstrapTable('refresh');
	},

	getFunctionInfo: function(_this) {
		var token = sessionStorage.getItem("token");
		_this.tableFunction = true;
		_this.productName = _this.product.pdName;

		$.ajax({
			type: "GET",
			url: "/wave-device/function/selectFunctionByProductId?productId=" + _this.product.pdId,
			contentType: "application/json;charset=UTF-8",
			dataType: "json",
			headers: {
				"Authorization": "Bearer " + token
			},
			success: function(result) {
				if (result.code == 20000) {
					// _this.$message.success(result.message);
					_this.functionData = result.data.function;
				}
			},
			error: function(xhr, errorText, errorType) {
				if (xhr.status == 403) {
					//do something
					_this.$message.error("抱歉，您没有权限");
					return;
				}
			}
		});
	}
}
