<template>
	<div id="app" class="container-fluid">
		<div class="header row">
			<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 top"><span class="badge badge-primary">产品配置管理</span></div>
			<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 top"></div>
			<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 top"></div>
			<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 top"></div>
		</div>
		<div class="main row">
			<el-dialog :title="title" :visible.sync="dialogFormVisible" @close="closeDialog" :close-on-click-modal='false'
			 :append-to-body='true'>
				<div id="productFunctionForm">
					<el-form :model="productFunction" :rules="productFunctions" ref="productFunctionForm" class="demo-ruleForm"
					 label-width="120px" label-position="right">
						<el-row :gutter="20">
							<el-col :span="24">
								<el-form-item label="功能配置名称" prop="functionName">
									<el-input v-model="productFunction.functionName" @keyup.enter.native="saveOrUpdate" clearable></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="24">
								<el-form-item label="功能描述" prop="functionDescribe">
									<el-input type="textarea" v-model="productFunction.functionDescribe" @keyup.enter.native="saveOrUpdate"
									 clearable></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="功能类型" prop="functionType">
									<el-select clearable v-model="productFunction.functionType" filterable placeholder="请选择" class="functionTypeVal">
										<el-option v-for="item in functionTypeList" :key="item.id" :value="item.value" :label="item.label">
										</el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="功能属性" prop="functionProperty">
									<el-select clearable v-model="productFunction.functionProperty" filterable placeholder="请选择" class="functionTypeVal">
										<el-option v-for="item in functionPropertyList" :key="item.id" :value="item.value" :label="item.label">
										</el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<!-- <el-col :span="8">
								<el-form-item label="命令" prop="functionCmd">
									<el-input oninput="value=value.replace(/[^0-9a-fA-F]$/g,'')" v-model="productFunction.functionCmd"
									 @keyup.enter.native="saveOrUpdate" clearable></el-input>
								</el-form-item>
							</el-col> -->
							<el-col :span="7">
								<el-form-item label="数据传输类型" prop="functionDataTransferType">
									<el-select clearable v-model="productFunction.functionDataTransferType" filterable placeholder="请选择" class="functionTypeVal">
										<el-option v-for="item in functionDataTransferTypeList" :key="item.id" :value="item.value" :label="item.label">
										</el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<!-- <el-col :span="6">
								<el-form-item label="长度" prop="functionLength">
									<el-input-number class="functionLength" v-model="productFunction.functionLength" controls-position="right"
									 :min="0" :max="99999" label="数据值长度"></el-input-number>
								</el-form-item>
							</el-col> -->
							
							<el-col :span="9">
								<el-form-item label="数据类型" prop="functionDataType">
									<el-select clearable v-model="productFunction.functionDataType" filterable placeholder="请选择" @change="functionDataTypeChange"
									 class="functionTypeVal">
										<el-option v-for="item in functionDataTypeList" :key="item.id" :value="item.value" :label="item.label">
										</el-option>
									</el-select>
								</el-form-item>
							</el-col>
							
							<el-col :span="15" v-show="disabled_enterprise">
								<el-form-item label="指定企业" prop="enterprise">
									<el-select clearable v-model="productFunction.enterpriseId" filterable placeholder="请选择" @change="functionDataTypeChange"
									 class="enterpriseVal">
										<el-option v-for="enterprise in enterpriseList" :key="enterprise.enterpriseId" :value="enterprise.enterpriseId"
										 :label="enterprise.enterpriseName">
										</el-option>
									</el-select>
								</el-form-item>
							</el-col>


							<el-col :span="24">
								<el-form-item label="数据" prop="functionPortValue">
									<el-input type="textarea" oninput="value=value.replace(/[^0-9a-fA-F]$/g,'')" v-model="productFunction.functionPortValue"
									 @keyup.enter.native="saveOrUpdate" clearable></el-input>
								</el-form-item>
							</el-col>

							<el-col :span="24">
								<el-form-item label="范围值类型" prop="functionRangeType">
									<el-select clearable v-model="productFunction.functionRangeType" filterable placeholder="请选择" class="functionTypeVal">
										<el-option v-for="item in functionRangeTypeList" :key="item.id" :value="item.value" :label="item.label">
										</el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<!-- <el-form-item label="范围" prop="range" v-show="rangeIsShow">
									<el-input-number v-model="productFunction.functionRangeMin" :step="0.000001" controls-position="right" size="small" class="rangeMin"></el-input-number>
									~
									<el-input-number v-model="productFunction.functionRangeMax" :step="0.000001" controls-position="right" size="small" class="rangeMax"></el-input-number>
								</el-form-item> -->
							<el-col :span="24">
								<el-form-item :label="'范围值'+index" v-for="(functionRange, index) in dynamicValidateForm.functionRanges" :key="index" prop="functionRange">
									<el-col :span="12">
										<el-form-item label="名称" prop="functionRangeName">
											<el-input v-model="functionRange.frName"></el-input>
											<!-- <el-input v-model="productFunction.functionRangeName"></el-input> -->
										</el-form-item>
									</el-col>
									<el-col :span="11">
										<el-form-item label="值" prop="functionRangeValue">
											<el-input v-model="functionRange.frValue"></el-input>
											<!-- <el-input v-model="productFunction.functionRangeValue"></el-input> -->
										</el-form-item>
									</el-col>
									<el-col :span="1">
										<el-button v-if="index === 0" type="primary" size="mini" icon="el-icon-plus" circle plain @click="addFunctionRange"></el-button>
										<el-button v-if="index !== 0" type="danger" size="mini" icon="el-icon-minus" circle plain @click.prevent="removeFunctionRange(functionRange)"></el-button>
									</el-col>
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
				</div>
				<div slot="footer" class="dialog-footer">
					<el-button @click="closeDialog">取 消</el-button>
					<el-button type="primary" @click="saveOrUpdate">确 定</el-button>
				</div>
			</el-dialog>



			<!-- 搜索框 start -->
			<div class="col-md-12 collapse search" id="collapseExample">

				<div class="col-md-6 input-group input-group-sm ">
					<input type="text" class="col-md-6 form-control" placeholder="请输入名称或者描述搜索" v-model="searchProductFunctionVo.searchInfo">
					<input type="date" class="col-md-3 form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm"
					 v-model="searchProductFunctionVo.startCreateTime">
					<div class="input-group-append">
						<span class="input-group-text">至</span>
					</div>
					<input type="date" class="col-md-3 form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm"
					 v-model="searchProductFunctionVo.endCreateTime">
				</div>

				<div class="col-md-4">
					<button type="button" class="btn btn-info btn-sm" @click="search">
						<svg class="bi bi-search" focusable="false" aria-hidden="true" width="1em" height="1em" viewBox="0 0 18 20" fill="currentColor"
						 xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
							 clip-rule="evenodd" />
							<path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
							 clip-rule="evenodd" />
						</svg>
						搜&nbsp;索
					</button>

					<button type="button" class="btn btn-warning btn-sm" @click="reset">
						<svg width="1em" height="1em" viewBox="0 0 18 20" class="bi bi-arrow-counterclockwise" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" d="M12.83 6.706a5 5 0 0 0-7.103-3.16.5.5 0 1 1-.454-.892A6 6 0 1 1 2.545 5.5a.5.5 0 1 1 .91.417 5 5 0 1 0 9.375.789z" />
							<path fill-rule="evenodd" d="M7.854.146a.5.5 0 0 0-.708 0l-2.5 2.5a.5.5 0 0 0 0 .708l2.5 2.5a.5.5 0 1 0 .708-.708L5.707 3 7.854.854a.5.5 0 0 0 0-.708z" />
						</svg>
						重&nbsp;置
					</button>
				</div>
			</div>
			<!-- 搜索框 end -->

			<!-- 左侧功能按钮 start -->
			<div class="col-md-3 left-botton">
				<button type="button" class="btn btn-sm btn-outline-success" data-toggle="modal" data-target="" @click="add" id="add" v-show="addIsShow">
					<svg class="bi bi-plus" focusable="false" aria-hidden="true" width="1.2em" height="1.2em" viewBox="0 0 15 20" fill="currentColor"
					 xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z"
						 clip-rule="evenodd" />
						<path fill-rule="evenodd" d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z" clip-rule="evenodd" />
					</svg>&nbsp;新增
				</button>
				<button type="button" class="btn btn-sm btn-outline-warning" data-toggle="modal" data-target="" @click="update" id="update" v-show="updateIsShow">
					<svg class="bi bi-pen" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" d="M5.707 13.707a1 1 0 0 1-.39.242l-3 1a1 1 0 0 1-1.266-1.265l1-3a1 1 0 0 1 .242-.391L10.086 2.5a2 2 0 0 1 2.828 0l.586.586a2 2 0 0 1 0 2.828l-7.793 7.793zM3 11l7.793-7.793a1 1 0 0 1 1.414 0l.586.586a1 1 0 0 1 0 1.414L5 13l-3 1 1-3z"
						 clip-rule="evenodd" />
						<path fill-rule="evenodd" d="M9.854 2.56a.5.5 0 0 0-.708 0L5.854 5.855a.5.5 0 0 1-.708-.708L8.44 1.854a1.5 1.5 0 0 1 2.122 0l.293.292a.5.5 0 0 1-.707.708l-.293-.293z"
						 clip-rule="evenodd" />
						<path d="M13.293 1.207a1 1 0 0 1 1.414 0l.03.03a1 1 0 0 1 .03 1.383L13.5 4 12 2.5l1.293-1.293z" />
					</svg>&nbsp;修改
				</button>
				<button type="button" class="btn btn-sm btn-outline-danger" @click="remove" id="remove" v-show="removeIsShow">
					<svg class="bi bi-trash" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
						<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
						<path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
						 clip-rule="evenodd" />
					</svg>&nbsp;删除
				</button>
			</div>
			<!-- 左侧功能按钮 end -->

			<div class="col-md-8"></div>

			<!-- 右侧功能按钮 start-->
			<div class="col-md-1 btn-group right-botton btn-group-sm" role="group" aria-label="...">
				<button type="button" class="btn btn-outline-secondary" data-toggle="collapse" data-target="#collapseExample"
				 aria-expanded="false" aria-controls="collapseExample" @click="clear">
					<svg class="bi bi-search" focusable="false" aria-hidden="true" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor"
					 xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
						 clip-rule="evenodd" />
						<path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
						 clip-rule="evenodd" />
					</svg>
				</button>
				<button type="button" class="btn btn-outline-secondary" @click="search">
					<svg class="bi bi-arrow-repeat" focusable="false" aria-hidden="true" width="1em" height="1em" viewBox="0 0 16 16"
					 fill="currentColor" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" d="M2.854 7.146a.5.5 0 0 0-.708 0l-2 2a.5.5 0 1 0 .708.708L2.5 8.207l1.646 1.647a.5.5 0 0 0 .708-.708l-2-2zm13-1a.5.5 0 0 0-.708 0L13.5 7.793l-1.646-1.647a.5.5 0 0 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0 0-.708z"
						 clip-rule="evenodd" />
						<path fill-rule="evenodd" d="M8 3a4.995 4.995 0 0 0-4.192 2.273.5.5 0 0 1-.837-.546A6 6 0 0 1 14 8a.5.5 0 0 1-1.001 0 5 5 0 0 0-5-5zM2.5 7.5A.5.5 0 0 1 3 8a5 5 0 0 0 9.192 2.727.5.5 0 1 1 .837.546A6 6 0 0 1 2 8a.5.5 0 0 1 .501-.5z"
						 clip-rule="evenodd" />
					</svg>
				</button>
			</div>
			<!-- 右侧功能按钮 end-->


			<!-- 产品配置信息表格-->
			<div class="table table-sm col-md-12">
				<table id="table"></table>
			</div>

		</div>

		<div class="footer row">
			<div class="col-md-4"></div>
			<div class="col-md-4"></div>
			<div class="col-md-4"></div>
		</div>
	</div>
</template>

<script>
	import productFunction from '../js/productFunction'
	export default {
		name: "ProductFunction",
		data() {
			var validate = (rule, value, callback) => {
				if (!/^\S+$/.test(value)) {
					callback(new Error('输入不能有空格'));
				}
			};
			return {
				dynamicValidateForm: {
					functionRanges: [{
						frName: "",
						frValue: ""
					}],

				},
				title: "",
				addIsShow: false,
				removeIsShow: false,
				updateIsShow: false,
				dialogFormVisible: false,
				disabled_enterprise: false,
				productList: [],
				enterpriseList:[],
				searchProductFunctionVo: {
					searchInfo: "",
					startCreateTime: "",
					endCreateTime: "",
				},
				functionTypeList: [{
						id: 0,
						label: '控制',
						value: 'CONTROL'
					},
					{
						id: 1,
						label: '非控制',
						value: 'NOT_CONTROL'

					}
				],
				functionPropertyList: [{
						id: 0,
						label: '云世',
						value: 'CLOUD_AGE'
					},
					{
						id: 1,
						label: '智能家居',
						value: 'SMART_HOME'

					}
				],
				functionRangeTypeList: [{
						id: 0,
						label: '连续',
						value: 'CONTINUOUS'
					},
					{
						id: 1,
						label: '离散',
						value: 'DISPERSE'
					}
				],
				functionDataTransferTypeList: [{
						id: 0,
						label: '可下发可上报',
						value: 'RAW'
					},
					{
						id: 1,
						label: '只上报',
						value: 'WOM'
					}, {
						id: 2,
						label: '只下发',
						value: 'ROM'
					}
				],
				functionDataTypeList: [{
						id: 0,
						label: '布尔类型0x00、0x01(字节：8位)',
						value: 'BOOLEAN'
					},
					{
						id: 1,
						label: '无符号单字节整形(字节：8位)',
						value: 'BYTE'
					}, {
						id: 2,
						label: '无符号双字节整形(字节：16位)',
						value: 'WORD'
					}, {
						id: 3,
						label: '无符号四字节整形(双字：32位)',
						value: 'DWORD'
					}, {
						id: 4,
						label: 'n字节',
						value: 'BYTE_N'
					}, {
						id: 5,
						label: '8421码，n字节',
						value: 'BCD_N'
					}, {
						id: 6,
						label: '字符串，n字节(UTF-8编码)',
						value: 'STRING'
					}, {
						id: 7,
						label: '单精度浮点数(32位)',
						value: 'FLOAT'
					}, {
						id: 8,
						label: '双精度浮点数(64位)',
						value: 'DOUBLE'
					}, {
						id: 9,
						label: '有符号单字节整型(8位)',
						value: 'INT8_T'
					}, {
						id: 10,
						label: '有符号双字节整型(16位)',
						value: 'INT16_T'
					}, {
						id: 11,
						label: '有符号四字节整型(32位)',
						value: 'INT32_T'
					}
				],
				productFunction: {},
				productFunctions: {
					functionName: [{
							required: true,
							message: '请输入功能配置名称',
							trigger: 'blur'
						},
						{
							validator: validate,
							trigger: 'blur'
						}
					],
					functionDescribe: [{
							required: true,
							message: '请输入功能描述',
							trigger: 'blur'
						}
					],
					functionCmd: [
						// {
						// 	required: true,
						// 	message: '请输入命令（十六进制）',
						// 	trigger: 'blur'
						// },
						// {
						// 	validator: validate,
						// 	trigger: 'blur'
						// }
					],
					// functionLength: [
					// 	// {
					// 	// 	required: true,
					// 	// 	message: '请输入长度',
					// 	// 	trigger: 'blur'
					// 	// },
					// 	// {
					// 	// 	validator: validate,
					// 	// 	trigger: 'blur'
					// 	// }
					// ],
					functionType: [{
						required: true,
						message: '请选择功能类型',
						trigger: 'change'
					}],
					functionDataType: [{
						required: true,
						message: '请选择数据类型',
						trigger: 'change'
					}],
					functionPortValue: [{
							required: true,
							message: '请输入数据（十六进制）',
							trigger: 'blur'
						},
						{
							validator: validate,
							trigger: 'blur'
						}
					],
					functionRangeType: [{
						required: true,
						message: '请选择范围值类型',
						trigger: 'change'
					}],
					functionRange: [{
						required: true,
					}],
					functionRangeName: [{
							required: true,
							message: '请输入范围值名称',
							trigger: 'blur'
						},
						{
							validator: validate,
							trigger: 'blur'
						}
					],
					functionRangeValue: [{
							required: true,
							message: '请输入值',
							trigger: 'blur'
						},
						{
							validator: validate,
							trigger: 'blur'
						}
					]
				},

			}
		},
		mounted() {
			var token = sessionStorage.getItem("token");
			productFunction.first(this, token);
			productFunction.second(this, token);
		},
		methods: {
			removeFunctionRange(item) {
				var index = this.dynamicValidateForm.functionRanges.indexOf(item)
				if (index !== -1) {
					this.dynamicValidateForm.functionRanges.splice(index, 1)
				}
			},
			addFunctionRange() {
				this.dynamicValidateForm.functionRanges.push({
					value: '',
					key: Date.now()
				});
			},
			functionDataTypeChange: function() {
				if (this.productFunction.functionType === "BYTE" || this.productFunction.functionType === "WORD" || this.productFunction
					.functionType === "DWORD" || this.productFunction.functionType === "FLOAT" || this.productFunction.functionType ===
					"DOUBLE") {
					this.rangeIsShow = true;
				} else {
					this.rangeIsShow = false;
				}
			},
			add: function() {
				productFunction.add(this)
			},
			update: function() {
				productFunction.update(this)
			},
			saveOrUpdate: function() {
				productFunction.saveOrUpdate(this)
			},
			remove: function() {
				productFunction.remove(this)
			},
			clear: function() {
				productFunction.clear(this)
			},
			search: function() {
				productFunction.search(this)
			},
			reset: function() {
				productFunction.reset(this)
			},
			closeDialog: function() {
				this.dialogFormVisible = false;
				this.$refs.productFunctionForm.resetFields();
			}
		}
	}
</script>

<style scoped src="../css/productFunction.css">

</style>
