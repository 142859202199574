<template>
	<div id="app" class="container-fluid">
		<div class="main">

			<el-row :gutter="20">
				<el-dialog :title="uploadTitle" :visible.sync="dialogFormVisibleUpload" @close="closeDialog" :close-on-click-modal='false'
				 :append-to-body='true'>
					<div id="otaForm">
						<!-- action="http://cloud.szxinken.com/confidence-device/ota/upload" -->
						<!-- action="http://localhost:8888/confidence-device/ota/upload" -->
						<el-upload class="upload-demo" drag action="http://cloud.cloudagetech.com/wave-device/ota/upload" :on-preview="handlePreview"
						 :before-upload="beforeAvatarUpload" :on-success="uploadSuccess" :on-remove="handleRemove" :before-remove="beforeRemove"
						 :headers="headers" :data="uploadData" :file-list="fileList">
							<i class="el-icon-upload"></i>
							<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
							<!-- <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div> -->
						</el-upload>
					</div>
				</el-dialog>


				<el-dialog :title="title" :visible.sync="dialogFormVisible" @close="closeDialog" :close-on-click-modal='false'
				 :append-to-body='true'>
					<div id="otaForm">
						<el-form :model="ota" :rules="otas" ref="otaForm" class="demo-ruleForm">
							<el-form-item label="CRC值" prop="otaCrc">
								<el-input v-model="ota.otaCrc" oninput="value=value.replace(/[^0-9a-fA-F]$/g,'')" @keyup.enter.native="saveOrUpdate"
								 clearable></el-input>
							</el-form-item>

							<el-form-item label="所属产品" prop="product">
								<el-select v-model="ota.otaProductId" filterable placeholder="请选择" class="productVal">
									<el-option v-for="product in productList" :value="product.pdId" :key="product.pdId" :label="product.pdName">
									</el-option>
								</el-select>
							</el-form-item>
							
							<el-form-item label="固件类型" prop="otaFirmwareType">
								<el-select v-model="ota.otaFirmwareType" filterable placeholder="请选择" class="productVal">
									<el-option v-for="item in otaFirmwareTypeList" :key="item.id" :value="item.value" :label="item.label">
									</el-option>
								</el-select>
							</el-form-item>

							<el-form-item label="OTA类型" prop="otaType">
								<el-select v-model="ota.otaType" filterable placeholder="请选择" class="productVal">
									<el-option v-for="item in otaTypeList" :key="item.id" :value="item.value" :label="item.label">
									</el-option>
								</el-select>
							</el-form-item>

							<el-form-item label="版本变更记录" prop="otaVersionChange">
								<el-input v-model="ota.otaVersionChange" @keyup.enter.native="saveOrUpdate" clearable></el-input>
							</el-form-item>
							<el-form-item label="版本名称" prop="otaVersionName">
								<el-input v-model="ota.otaVersionName" @keyup.enter.native="saveOrUpdate" clearable></el-input>
							</el-form-item>
							<!-- <el-form-item label="链接" prop="otaUrl">
							<el-input v-model="ota.otaUrl" @keyup.enter.native="saveOrUpdate" clearable></el-input>
						</el-form-item> -->
							<el-form-item label="硬件版本" prop="otaHardwareVersion">
								<el-input v-model="ota.otaHardwareVersion" @keyup.enter.native="saveOrUpdate" clearable></el-input>
							</el-form-item>
							<el-form-item label="当前使用固件版本" prop="otaCurrentFirmwareVersion">
								<el-input v-model="ota.otaCurrentFirmwareVersion" @keyup.enter.native="saveOrUpdate" clearable></el-input>
							</el-form-item>

							<el-form-item label="状态" prop="otaStatus">
								<el-select v-model="ota.otaStatus" placeholder="请选择" class="otaStatusVal">
									<el-option v-for="item in statusList" :key="item.id" :value="item.value" :label="item.label">
									</el-option>
								</el-select>
							</el-form-item>
						</el-form>
					</div>
					<div slot="footer" class="dialog-footer">
						<el-button @click="closeDialog">取 消</el-button>
						<el-button type="primary" @click="saveOrUpdate">确 定</el-button>
					</div>
				</el-dialog>


				<!-- 搜索框 start -->
				<el-col :span="24" class="collapse search" id="collapseExample">

					<div class="col-md-6 input-group input-group-sm ">
						<input type="text" class="col-md-6 form-control" placeholder="请输入名称或者描述搜索" v-model="searchOtaVo.searchInfo">
						<input type="date" class="col-md-3 form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm"
						 v-model="searchOtaVo.startCreateTime">
						<div class="input-group-append">
							<span class="input-group-text">至</span>
						</div>
						<input type="date" class="col-md-3 form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm"
						 v-model="searchOtaVo.endCreateTime">
					</div>

					<div class="col-md-4">
						<button type="button" class="btn btn-info btn-sm" @click="search">
							<svg class="bi bi-search" focusable="false" aria-hidden="true" width="1em" height="1em" viewBox="0 0 18 20" fill="currentColor"
							 xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
								 clip-rule="evenodd" />
								<path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
								 clip-rule="evenodd" />
							</svg>
							搜&nbsp;索
						</button>

						<button type="button" class="btn btn-warning btn-sm" @click="reset">
							<svg width="1em" height="1em" viewBox="0 0 18 20" class="bi bi-arrow-counterclockwise" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" d="M12.83 6.706a5 5 0 0 0-7.103-3.16.5.5 0 1 1-.454-.892A6 6 0 1 1 2.545 5.5a.5.5 0 1 1 .91.417 5 5 0 1 0 9.375.789z" />
								<path fill-rule="evenodd" d="M7.854.146a.5.5 0 0 0-.708 0l-2.5 2.5a.5.5 0 0 0 0 .708l2.5 2.5a.5.5 0 1 0 .708-.708L5.707 3 7.854.854a.5.5 0 0 0 0-.708z" />
							</svg>
							重&nbsp;置
						</button>
					</div>
				</el-col>
				<!-- 搜索框 end -->

				<!-- 左侧功能按钮 start -->

				<el-col :span="22" class="left-botton">
					<button type="button" class="btn btn-sm btn-outline-success" data-toggle="modal" data-target="" @click="add" id="add"
					 v-show="addIsShow">
						<svg class="bi bi-plus" focusable="false" aria-hidden="true" width="1.2em" height="1.2em" viewBox="0 0 15 20"
						 fill="currentColor" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z"
							 clip-rule="evenodd" />
							<path fill-rule="evenodd" d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z" clip-rule="evenodd" />
						</svg>&nbsp;新增
					</button>
					<button type="button" class="btn btn-sm btn-outline-warning" data-toggle="modal" data-target="" @click="update" id="update"
					 v-show="updateIsShow">
						<svg class="bi bi-pen" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" d="M5.707 13.707a1 1 0 0 1-.39.242l-3 1a1 1 0 0 1-1.266-1.265l1-3a1 1 0 0 1 .242-.391L10.086 2.5a2 2 0 0 1 2.828 0l.586.586a2 2 0 0 1 0 2.828l-7.793 7.793zM3 11l7.793-7.793a1 1 0 0 1 1.414 0l.586.586a1 1 0 0 1 0 1.414L5 13l-3 1 1-3z"
							 clip-rule="evenodd" />
							<path fill-rule="evenodd" d="M9.854 2.56a.5.5 0 0 0-.708 0L5.854 5.855a.5.5 0 0 1-.708-.708L8.44 1.854a1.5 1.5 0 0 1 2.122 0l.293.292a.5.5 0 0 1-.707.708l-.293-.293z"
							 clip-rule="evenodd" />
							<path d="M13.293 1.207a1 1 0 0 1 1.414 0l.03.03a1 1 0 0 1 .03 1.383L13.5 4 12 2.5l1.293-1.293z" />
						</svg>&nbsp;修改
					</button>
					<button type="button" class="btn btn-sm btn-outline-danger" @click="remove" id="remove" v-show="removeIsShow">
						<svg class="bi bi-trash" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
							<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
							<path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
							 clip-rule="evenodd" />
						</svg>&nbsp;删除
					</button>
				</el-col>
				<!-- 左侧功能按钮 end -->

				<!-- 右侧功能按钮 start-->
				<el-col :span="2" class="btn-group right-botton btn-group-sm" role="group" aria-label="...">
					<button type="button" class="btn btn-outline-secondary" data-toggle="collapse" data-target="#collapseExample"
					 aria-expanded="false" aria-controls="collapseExample" @click="clear">
						<svg class="bi bi-search" focusable="false" aria-hidden="true" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor"
						 xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
							 clip-rule="evenodd" />
							<path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
							 clip-rule="evenodd" />
						</svg>
					</button>
					<button type="button" class="btn btn-outline-secondary" @click="search">
						<svg class="bi bi-arrow-repeat" focusable="false" aria-hidden="true" width="1em" height="1em" viewBox="0 0 16 16"
						 fill="currentColor" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" d="M2.854 7.146a.5.5 0 0 0-.708 0l-2 2a.5.5 0 1 0 .708.708L2.5 8.207l1.646 1.647a.5.5 0 0 0 .708-.708l-2-2zm13-1a.5.5 0 0 0-.708 0L13.5 7.793l-1.646-1.647a.5.5 0 0 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0 0-.708z"
							 clip-rule="evenodd" />
							<path fill-rule="evenodd" d="M8 3a4.995 4.995 0 0 0-4.192 2.273.5.5 0 0 1-.837-.546A6 6 0 0 1 14 8a.5.5 0 0 1-1.001 0 5 5 0 0 0-5-5zM2.5 7.5A.5.5 0 0 1 3 8a5 5 0 0 0 9.192 2.727.5.5 0 1 1 .837.546A6 6 0 0 1 2 8a.5.5 0 0 1 .501-.5z"
							 clip-rule="evenodd" />
						</svg>
					</button>
				</el-col>
				<!-- 右侧功能按钮 end-->

				<!-- OTA信息表格-->
				<!-- <el-col :span="24">
					<div class="table table-sm table-responsive" style="max-height: 500px;">
						<table id="table" data-height="500"></table>
					</div>
				</el-col> -->
				<el-col :span="24" class="table table-sm">
					<table id="table"></table>
				</el-col>

			</el-row>
		</div>
	</div>
</template>

<script>
	import ota from '../js/ota'
	export default {
		name: "ota",
		data() {
			var token = sessionStorage.getItem("token");
			var validate = (rule, value, callback) => {
				if (!/^\S+$/.test(value)) {
					callback(new Error('输入不能有空格'));
				}
			};
			return {
				headers: {
					"Authorization": "Bearer " + token
				},
				uploadData:{},
				fileList: [],
				productList: [],
				title: "",
				uploadTitle: "文件上传",
				addIsShow: false,
				removeIsShow: false,
				updateIsShow: false,
				dialogFormVisible: false,
				dialogFormVisibleUpload: false,
				ota: {},
				otas: {
					otaVersionChange: [
						// {
						// 	required: true,
						// 	message: '请输入设备名称',
						// 	trigger: 'blur'
						// },
						// {
						// 	validator: validate,
						// 	trigger: 'blur'
						// }
					],
					otaVersionName: [
						// {
						// 	required: true,
						// 	message: '请输入厂商名称',
						// 	trigger: 'blur'
						// },
						// {
						// 	validator: validate,
						// 	trigger: 'blur'
						// }
					],
					otaUrl: [
						// {
						// 	required: true,
						// 	message: '请输入厂商编码',
						// 	trigger: 'blur'
						// },
						// {
						// 	validator: validate,
						// 	trigger: 'blur'
						// }
					],
					otaCrc: [{
							required: true,
							message: '请输入CRC值',
							trigger: 'blur'
						},
						{
							min: 0,
							max: 8,
							message: 'CRC值长度为 0 到 8 个字符',
							trigger: 'blur'
						}
					],
					otaHardwareVersion: [
						// {
						// 	required: true,
						// 	message: '请输入设备MAC地址',
						// 	trigger: 'blur'
						// },
						// {
						// 	validator: validate,
						// 	trigger: 'blur'
						// }
					],
					otaCurrentFirmwareVersion: [
						// {
						// 	required: true,
						// 	message: '请输入设备序列号',
						// 	trigger: 'blur'
						// },
						// {
						// 	validator: validate,
						// 	trigger: 'blur'
						// }
					],
					product: [{
						required: true,
						message: '请选择产品',
						trigger: 'change'
					}],
					otaType: [{
						required: true,
						message: '请选择OTA类型',
						trigger: 'change'
					}],
					otaStatus: [{
						required: true,
						message: '请选择状态',
						trigger: 'change'
					}]
				},
				otaFirmwareTypeList: [{
					id: 0,
					label: '配置固件',
					value: 'CONFIG'
				}, {
					id: 1,
					label: '其他固件',
					value: 'OTHER'
				}, {
					id: 2,
					label: 'H5升级固件',
					value: 'PAGE'
				}],
				otaTypeList: [{
					id: 0,
					label: '强制',
					value: 'FORCE'
				}, {
					id: 1,
					label: '选择',
					value: 'SELECT'
				}],
				statusList: [{
						id: 0,
						label: '启用',
						value: 'ENABLE'
					},
					{
						id: 1,
						label: '禁用',
						value: 'DISABLE'
					},
				],
				searchOtaVo: {
					searchInfo: "",
					startCreateTime: "",
					endCreateTime: "",
				},
			}
		},
		created() {
			var token = sessionStorage.getItem("token");
			ota.first(this, token);
			ota.second(this, token);
		},
		methods: {
			add: function() {
				ota.add(this)
			},
			update: function() {
				ota.update(this)
			},
			saveOrUpdate: function() {
				ota.saveOrUpdate(this)
			},
			remove: function() {
				ota.remove(this)
			},
			clear: function() {
				ota.clear(this)
			},
			search: function() {
				ota.search(this)
			},
			reset: function() {
				ota.reset(this)
			},
			closeDialog: function() {
				this.dialogFormVisible = false;
				this.$refs.otaForm.resetFields();
				$('#table').bootstrapTable('refresh');
			},
			beforeAvatarUpload: function(file) {
				this.uploadData.otaId = this.ota.otaId;
			},
			uploadSuccess: function(response, file, fileList) {
				ota.uploadSuccess(this, response, file, fileList)
			},
			handleRemove: function(file, fileList) {
				// console.log(file, fileList);
			},
			handlePreview: function(file) {
				// console.log(file);
			},
			beforeRemove: function(file, fileList) {
				return this.$confirm(`确定移除 ${ file.name }？`);
			}
		}
	}
</script>

<style scoped src="../css/otaMain.css">
</style>
