<template>
    <div class="container-fluid">
        <div class="header row">
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 top"><span class="badge badge-primary">个人中心</span></div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 top"></div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 top"></div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 top"></div>
        </div>
        <div class="main row">
            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">{{title}}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <!-- 提交的表单 -->
                        <form id="personal">
                            <div class="form-group row">
                                <label class="col-sm-2 col-form-label">{{showInfo.old}}</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control" v-model="updateInfo.oldInfo">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2 col-form-label">{{showInfo.new}}</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control" v-model="updateInfo.newInfo">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2 col-form-label">{{showInfo.confirm}}</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control" v-model="updateInfo.confirmInfo">

                                </div>
                            </div>
                        </form>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">取消</button>
                            <button type="button" class="btn btn-primary" @click="toUpdate">提交</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 card shadow p-3 mb-5 bg-white rounded mx-auto info" style="width: 18rem;">
                <div class="card-body">
                    <h5 class="card-title">用户信息</h5>
                </div>
                <!-- <img src="..." class="card-img-top" alt="..."> -->
                <div class="list-group list-group-flush" id="qq">
                    <li class="list-group-item">登录账号：{{userInfo.userName}}</li>
                    <li class="list-group-item">手机号码：{{userInfo.userPhone}}</li>
                    <li class="list-group-item">用户邮箱：{{userInfo.userEmail}}</li>
                </div>
                <div class="card-body">
                    <a href="#" class="card-link" @click="updatePassword" data-toggle="modal" data-target="" id="updatePassword">修改密码</a>
                </div>
            </div>
            <div class="col-md-7 card shadow p-3 mb-5 bg-white rounded mx-auto info" style="width: 18rem;">
                <div class="card-body">
                    <h5 class="card-title">生产信息</h5>
                </div>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">绑定产品数量：{{count.productCount}}</li>
                    <li class="list-group-item">注册设备数量：{{count.deviceCount}}</li>
                </ul>
                <div class="card-body">
                    <a href="#" class="card-link"></a>
                    <a href="#" class="card-link"></a>
                </div>
            </div>
        </div>

        <div class="footer row">
            <div class="col-md-4"></div>
            <div class="col-md-4"></div>
            <div class="col-md-4"></div>
        </div>
    </div>
</template>

<script>
    import personal from '../js/personal'
    export default {
        name: "Personal",
        data(){
            return{
                userInfo:{},
                updateInfo:{
                    userName:"",
                    oldInfo:"",
                    newInfo:"",
                    confirmInfo:""
                },
                password:{
                    old:"原密码",
                    new:"新密码",
                    confirm:"再次确认",
                },
                showInfo:{
                    old:"",
                    new:"",
                    confirm:"",
                },
                title:"",
                passwordTitle:"密码修改",
                emailTitle:"邮箱修改",
                count:{
                }
            }
        },
        created() {
            personal.first(this)
        },
        methods:{
            updatePassword:function () {
                personal.updatePassword(this)
            },
            toUpdate:function () {
                personal.toUpdate(this)
            }
        }
    }
</script>

<style scoped src="../css/personal.css">

</style>