<template>
	<div id="app" class="container-fluid">
		<div class="header row">
			<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 top"><span class="badge badge-primary">产品管理</span></div>
			<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 top"></div>
			<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 top"></div>
			<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 top"></div>
		</div>
		<div class="main row">

			<el-dialog :title="title" :visible.sync="dialogFormVisible" @close="closeDialog"
				:close-on-click-modal='false' :append-to-body='true'>
				<div id="productForm">
					<el-form :model="product" :rules="products" ref="productForm" class="demo-ruleForm">
						<el-row :gutter="20">
							<el-col :span="16">
								<el-form-item label="产品名称" prop="pdName">
									<el-input v-model="product.pdName" @keyup.enter.native="saveOrUpdate" clearable>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="产品类型" prop="pdType">
									<el-input v-model="product.pdType" @keyup.enter.native="saveOrUpdate" clearable>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="厂商名称" prop="productEnterprise">
									<el-select v-model="product.enterpriseId" filterable placeholder="请选择"
										class="productEnterpriseVal">
										<el-option v-for="productEnterprise in productEnterpriseList"
											:value="productEnterprise.enterpriseId"
											:key="productEnterprise.enterpriseId"
											:label="productEnterprise.enterpriseName">
										</el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="产品公开类型" prop="pdOpenType">
									<el-select v-model="product.pdOpenType" placeholder="请选择" style="width: 100%;">
										<el-option v-for="item in openType" :key="item.id" :value="item.value"
											:label="item.label">
										</el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="设备升级状态(OTA)">
									<el-select v-model="product.pdUpgradeStatus" placeholder="请选择" class="otaStatusVal">
										<el-option v-for="item in statusList" :key="item.id" :value="item.value"
											:label="item.label">
										</el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<!-- <el-col :span="24">
								<el-form-item label="产品功能" prop="productFunction ">
									<el-select v-model="product.functionIds" multiple filterable placeholder="请选择" @change="functionChange" class="productFunctionVal">
										<el-option v-for="productFunction in productFunctionList" :value="productFunction.functionId" :key="productFunction.functionId"
										 :label="productFunction.functionName">
										</el-option>
									</el-select>
								</el-form-item>
							</el-col> -->
							<el-col :span="24">
								<el-collapse accordion>
									<el-collapse-item>
										<template slot="title">
											产品功能 Product Function<i class="header-icon el-icon-info"></i>
											<div @click="stopProp" style="margin-left:10px">
												<el-button icon="el-icon-search" v-show="functionInfoButton"
													@click="getFunctionInfo" circle size="mini"></el-button>
												<el-badge type="warning" v-show="badge"
													:value="this.dynamicValidateForm.productFunctions.length"
													class="item">
												</el-badge>
											</div>
										</template>
										<el-scrollbar style="height:100%">
											<el-form-item
												v-for="(productFunction, index) in dynamicValidateForm.productFunctions"
												:key="index" prop="productFunction">
												<el-col :span="8">
													<el-form-item label="功能选择" prop="productFunction ">
														<el-select v-model="productFunction.functionId" filterable
															placeholder="请选择" class="productFunctionVal">
															<el-option v-for="productFunction in productFunctionList"
																:value="productFunction.functionId"
																:key="productFunction.functionId"
																:label="productFunction.functionName">
															</el-option>
														</el-select>
													</el-form-item>
												</el-col>
												<el-col :span="7">
													<el-form-item label="port_id" prop="portValue">
														<el-input v-model="productFunction.portValue"
															oninput="value=value.replace(/[^0-9a-fA-F]$/g,'')">
														</el-input>
													</el-form-item>
												</el-col>
												<el-col :span="7">
													<el-form-item label="描述" prop="portDescribe">
														<el-input v-model="productFunction.portDescribe"></el-input>
													</el-form-item>
												</el-col>
												<el-col :span="1" style="padding-top: 46px;">
													<el-button v-if="index === 0" type="primary" size="mini"
														icon="el-icon-plus" circle plain @click="addProductFunction">
													</el-button>
													<el-button v-if="index !== 0" type="danger" size="mini"
														icon="el-icon-minus" circle plain
														@click.prevent="removeProductFunction(productFunction)">
													</el-button>
												</el-col>
											</el-form-item>
										</el-scrollbar>
									</el-collapse-item>

								</el-collapse>
							</el-col>

							<el-col :span="24">
								<el-form-item label="产品图标" prop="pdPictureUrl">
									<!-- <el-input v-show="false" v-model="product.pdPictureUrl" @keyup.enter.native="saveOrUpdate" clearable></el-input> -->
								</el-form-item>
								<!-- <el-upload style="width: 50%;display: inline-block;" class="upload-demo" drag action="http://cloud.cloudagetech.com/wave-device/product/upload">
									<i class="el-icon-upload"></i>
									<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
									<div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
								</el-upload> -->
								<el-image style="width: 30%; height: 200px; margin: 0 0 1% 35%;"
									:src="product.pdPictureUrl">
								</el-image>
							</el-col>
						</el-row>
					</el-form>
				</div>
				<div slot="footer" class="dialog-footer">
					<el-button @click="closeDialog">取 消</el-button>
					<el-button type="primary" @click="saveOrUpdate">确 定</el-button>
					<el-upload class="upload-demo" :action="upload_url" :on-success="uploadSuccess" :headers="headers"
						:show-file-list="false" style="float: left;">
						<el-button type="success" icon="el-icon-picture-outline" circle v-show="upload_img">上传产品图片
						</el-button>
					</el-upload>
				</div>
			</el-dialog>


			<el-drawer title="当前产品绑定功能" :visible.sync="tableFunction" :append-to-body="true" :before-close="handleClose"
				direction="rtl" size="30%">
				<el-alert title="产品 :" type="success" :closable="false">
					{{productName}}
				</el-alert>

				<el-card class="box-card" shadow="hover">
					<el-table :data="functionData">
						<el-table-column property="functionName" label="功能" width="150"></el-table-column>
						<el-table-column property="functionPortId.port_id" label="port_id" width="200">
						</el-table-column>
						<el-table-column property="functionPortId.describe" label="描述"></el-table-column>
					</el-table>
				</el-card>
			</el-drawer>


			<!-- 搜索框 start -->
			<div class="col-md-12 collapse search" id="collapseExample">

				<div class="col-md-6 input-group input-group-sm ">
					<input type="text" class="col-md-6 form-control" placeholder="请输入名称或者描述搜索"
						v-model="searchProductVo.searchInfo">
					<input type="date" class="col-md-3 form-control" aria-label="Sizing example input"
						aria-describedby="inputGroup-sizing-sm" v-model="searchProductVo.startCreateTime">
					<div class="input-group-append">
						<span class="input-group-text">至</span>
					</div>
					<input type="date" class="col-md-3 form-control" aria-label="Sizing example input"
						aria-describedby="inputGroup-sizing-sm" v-model="searchProductVo.endCreateTime">
				</div>

				<div class="col-md-4">
					<button type="button" class="btn btn-info btn-sm" @click="search">
						<svg class="bi bi-search" focusable="false" aria-hidden="true" width="1em" height="1em"
							viewBox="0 0 18 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd"
								d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
								clip-rule="evenodd" />
							<path fill-rule="evenodd"
								d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
								clip-rule="evenodd" />
						</svg>
						搜&nbsp;索
					</button>

					<button type="button" class="btn btn-warning btn-sm" @click="reset">
						<svg width="1em" height="1em" viewBox="0 0 18 20" class="bi bi-arrow-counterclockwise"
							fill="currentColor" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd"
								d="M12.83 6.706a5 5 0 0 0-7.103-3.16.5.5 0 1 1-.454-.892A6 6 0 1 1 2.545 5.5a.5.5 0 1 1 .91.417 5 5 0 1 0 9.375.789z" />
							<path fill-rule="evenodd"
								d="M7.854.146a.5.5 0 0 0-.708 0l-2.5 2.5a.5.5 0 0 0 0 .708l2.5 2.5a.5.5 0 1 0 .708-.708L5.707 3 7.854.854a.5.5 0 0 0 0-.708z" />
						</svg>
						重&nbsp;置
					</button>
				</div>
			</div>
			<!-- 搜索框 end -->

			<!-- 左侧功能按钮 start -->
			<div class="col-md-3 left-botton">
				<button type="button" class="btn btn-sm btn-outline-success" data-toggle="modal" data-target=""
					@click="add" id="add" v-show="addIsShow">
					<svg class="bi bi-plus" focusable="false" aria-hidden="true" width="1.2em" height="1.2em"
						viewBox="0 0 15 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd"
							d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z"
							clip-rule="evenodd" />
						<path fill-rule="evenodd" d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z"
							clip-rule="evenodd" />
					</svg>&nbsp;新增
				</button>
				<button type="button" class="btn btn-sm btn-outline-warning" data-toggle="modal" data-target=""
					@click="update" id="update" v-show="updateIsShow">
					<svg class="bi bi-pen" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor"
						xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd"
							d="M5.707 13.707a1 1 0 0 1-.39.242l-3 1a1 1 0 0 1-1.266-1.265l1-3a1 1 0 0 1 .242-.391L10.086 2.5a2 2 0 0 1 2.828 0l.586.586a2 2 0 0 1 0 2.828l-7.793 7.793zM3 11l7.793-7.793a1 1 0 0 1 1.414 0l.586.586a1 1 0 0 1 0 1.414L5 13l-3 1 1-3z"
							clip-rule="evenodd" />
						<path fill-rule="evenodd"
							d="M9.854 2.56a.5.5 0 0 0-.708 0L5.854 5.855a.5.5 0 0 1-.708-.708L8.44 1.854a1.5 1.5 0 0 1 2.122 0l.293.292a.5.5 0 0 1-.707.708l-.293-.293z"
							clip-rule="evenodd" />
						<path
							d="M13.293 1.207a1 1 0 0 1 1.414 0l.03.03a1 1 0 0 1 .03 1.383L13.5 4 12 2.5l1.293-1.293z" />
					</svg>&nbsp;修改
				</button>
				<button type="button" class="btn btn-sm btn-outline-danger" @click="remove" id="remove"
					v-show="removeIsShow">
					<svg class="bi bi-trash" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
						<path fill-rule="evenodd"
							d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
							clip-rule="evenodd" />
					</svg>&nbsp;删除
				</button>
			</div>
			<!-- 左侧功能按钮 end -->

			<div class="col-md-8"></div>

			<!-- 右侧功能按钮 start-->
			<div class="col-md-1 btn-group right-botton btn-group-sm" role="group" aria-label="...">
				<button type="button" class="btn btn-outline-secondary" data-toggle="collapse"
					data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" @click="clear">
					<svg class="bi bi-search" focusable="false" aria-hidden="true" width="1em" height="1em"
						viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd"
							d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
							clip-rule="evenodd" />
						<path fill-rule="evenodd"
							d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
							clip-rule="evenodd" />
					</svg>
				</button>
				<button type="button" class="btn btn-outline-secondary" @click="search">
					<svg class="bi bi-arrow-repeat" focusable="false" aria-hidden="true" width="1em" height="1em"
						viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd"
							d="M2.854 7.146a.5.5 0 0 0-.708 0l-2 2a.5.5 0 1 0 .708.708L2.5 8.207l1.646 1.647a.5.5 0 0 0 .708-.708l-2-2zm13-1a.5.5 0 0 0-.708 0L13.5 7.793l-1.646-1.647a.5.5 0 0 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0 0-.708z"
							clip-rule="evenodd" />
						<path fill-rule="evenodd"
							d="M8 3a4.995 4.995 0 0 0-4.192 2.273.5.5 0 0 1-.837-.546A6 6 0 0 1 14 8a.5.5 0 0 1-1.001 0 5 5 0 0 0-5-5zM2.5 7.5A.5.5 0 0 1 3 8a5 5 0 0 0 9.192 2.727.5.5 0 1 1 .837.546A6 6 0 0 1 2 8a.5.5 0 0 1 .501-.5z"
							clip-rule="evenodd" />
					</svg>
				</button>
			</div>
			<!-- 右侧功能按钮 end-->


			<!-- 产品信息表格-->
			<!-- <div class="table table-sm table-responsive" style="max-height: 500px;" id="table_div">
				<table id="table" data-height="500"></table>
			</div> -->
			<div class="table table-sm col-md-12">
				<table id="table" style="table-layout: fixed;"></table>
			</div>

		</div>

		<div class="footer row">
			<div class="col-md-4"></div>
			<div class="col-md-4"></div>
			<div class="col-md-4"></div>
		</div>
	</div>
</template>

<script>
	import product from '../js/product'
	export default {
		name: "Product",
		data() {
			var token = sessionStorage.getItem("token");
			var validate = (rule, value, callback) => {
				if (!/^\S+$/.test(value)) {
					callback(new Error('输入不能有空格'));
				}
			};
			return {
				headers: {
					"Authorization": "Bearer " + token
				},
				upload_url: "",
				badge: false,
				productName: "",
				functionInfoButton: false,
				tableFunction: false,
				upload_img: false,
				functionData: [],
				dynamicValidateForm: {
					productFunctions: [{}],
				},
				product: {
					// functionIds: []
				},
				products: {
					portValue: [{
						required: false,
						message: '请输入port_id',
						trigger: 'blur'
					}],
					pdName: [{
							required: true,
							message: '请输入产品名称',
							trigger: 'blur'
						},
						{
							validator: validate,
							trigger: 'blur'
						},
						{
							min: 4,
							max: 200,
							message: '产品名称长度为 4 到 200 个字符',
							trigger: 'blur'
						}
					],
					productEnterprise: [{
						required: true,
						message: '请选择所属企业',
						trigger: 'change'
					}],
					pdType: [{
							required: true,
							message: '请输入产品类型',
							trigger: 'blur'
						},
						{
							validator: validate,
							trigger: 'blur'
						}
					],
					pdOpenType: [{
							required: true,
							message: '请选择产品公开类型',
							trigger: 'blur'
						},
						{
							validator: validate,
							trigger: 'blur'
						}
					],
					pdPictureUrl: [{
							required: true,
							message: '请上传产品图片',
							trigger: 'blur'
						},
						{
							validator: validate,
							trigger: 'blur'
						}
					]
				},
				title: "",
				addIsShow: false,
				removeIsShow: false,
				updateIsShow: false,
				dialogFormVisible: false,
				searchProductVo: {
					searchInfo: "",
					startCreateTime: "",
					endCreateTime: "",
				},
				productFunctionList: [],
				productEnterpriseList: [],
				statusList: [{
						id: 0,
						label: '启用',
						value: 'ENABLE'
					},
					{
						id: 1,
						label: '禁用',
						value: 'DISABLE'
					},
				],
				openType: [{
						id: 0,
						label: '公开',
						value: 'PUBLIC'
					},
					{
						id: 1,
						label: '私有',
						value: 'PRIVATE',
					},
					{
						id: 2,
						label: '下级可见',
						value: 'SUBORDINATE',
					}
				]
			}
		},
		created() {
			var token = sessionStorage.getItem("token");
			product.first(this, token);
			product.second(this, token);
		},
		mounted() {
			this.upload_url = window.location.protocol + "//cloud.cloudagetech.com/wave-device/product/upload"
		},
		methods: {
			getFunctionInfo: function() {
				product.getFunctionInfo(this);
			},
			uploadSuccess: function(response, file, fileList) {
				product.uploadSuccess(this, response, file, fileList)
			},
			stopProp(e) {
				e.stopPropagation()
			},
			handleClose(done) {
				done();
				this.functionData = [{
					"functionName": "",
					"functionPortId.port_id": "",
					"functionPortId.describe": ""
				}]

			},

			removeProductFunction(item) {
				var index = this.dynamicValidateForm.productFunctions.indexOf(item)
				if (index !== -1) {
					this.dynamicValidateForm.productFunctions.splice(index, 1)
				}
			},
			addProductFunction() {
				this.dynamicValidateForm.productFunctions.push({
					value: '',
					key: Date.now()
				});
			},
			add: function() {
				product.add(this)
			},
			update: function() {
				product.update(this)
			},
			saveOrUpdate: function() {
				product.saveOrUpdate(this)
			},
			remove: function() {
				product.remove(this)
			},
			clear: function() {
				product.clear(this)
			},
			search: function() {
				product.search(this)
			},
			reset: function() {
				product.reset(this)
			},
			closeDialog: function() {
				this.dialogFormVisible = false;
				this.$refs.productForm.resetFields();
			}
		}
	}
</script>

<style scoped src="../css/product.css">

</style>
